<template>
  <h4>Jadwal Pelajaran</h4>
  <div class="card shadow">
    <div class="card-header">
      <div class="col-md-10 col-lg-8 col-xl-6 mx-auto my-3">
        <div class="card shadow">
          <div class="card-header">
            <div class="row row-cols-auto justify-content-between">
              <div class="col-auto d-inline-flex">
                <h5 class="my-auto">Jadwal</h5>
              </div>
              <div class="col-auto ms-auto">
                <select class="form-select form-select-sm shadow-sm" v-model="semester">
                  <option disabled value="">-- Pilih Semester --</option>
                  <option v-for="s in listSemester" :key="s" :value="s">{{ s.nama }}</option>
                </select>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="row mb-1">
              <label class="col-sm-4 col-form-label col-form-label-sm">Rombongan Belajar</label>
              <div class="col-sm-8">
                <v-select v-model="rombel" placeholder="Pilih Rombongan Belajar" label="nama" :options="listRombel">
                  <template #no-options>
                    Rombongan Belajar tidak ditemukan.
                  </template>
                </v-select>
              </div>
            </div>
            <div class="row mb-0">
              <label class="col-sm-4 col-form-label col-form-label-sm">Hari</label>
              <div class="col-sm-8">
                <v-select v-model="hari" placeholder="Pilih Hari" label="nama" :options="listHari">
                  <template #no-options>
                    Hari tidak ditemukan.
                  </template>
                </v-select>
              </div>
            </div>
          </div>
          <div class="card-footer" v-if="rombel || hari">
            <div class="row row-cols-auto justify-content-between">
              <div class="col-auto d-inline-flex">
                <div class="form-check my-auto">
                  <input class="form-check-input" type="checkbox" v-model="tampilkanWaktu" id="waktu">
                  <label class="form-check-label" for="waktu">
                    Tamplkan Waktu
                  </label>
                </div>
              </div>
              <div class="col-auto">
                <button type="button" class="btn btn-sm btn-success shadow-sm" @click="unduhPDF">Unduh Jadwal</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card-body vl-parent" ref="table" v-if="rombel || hari">
      <h5 v-if="rombel && hari">Jadwal Pelajaran {{ rombel.nama }} Hari {{ hari.nama }}</h5>
      <h5 v-else-if="rombel">Jadwal Pelajaran {{ rombel.nama }}</h5>
      <h5 v-else>Jadwal Pelajaran Hari {{ hari.nama }}</h5>
      <div class="table-responsive shadow" v-if="rombel">
        <table class="table table-sm table-hover table-light table-bordered border-secondary mb-0 align-middle"
          id="tabelJadwal">
          <thead class="text-center">
            <tr>
              <th v-if="!hari" class="position-sticky start-0">Hari</th>
              <th>Jam Ke</th>
              <th>Mata Pelajaran</th>
              <th>Guru Pengampu</th>
              <th>Ruang</th>
            </tr>
          </thead>
          <tbody v-if="dataJadwal.length">
            <tr v-for="d in dataJadwal" :key="d">
              <td v-if="!hari && d.hari" class="position-sticky start-0 text-center" :rowspan="d.hari.rowspan">{{
                d.hari.nama }}</td>
              <td v-if="tampilkanWaktu" class="text-center">
                <strong>{{ d.jam_awal + " - " + d.jam_akhir }}</strong><br />
                <em>({{ d.waktu_mulai + " - " + d.waktu_selesai }})</em>
              </td>
              <td v-else class="text-center">{{ d.jam_awal + " - " + d.jam_akhir }}</td>
              <td>{{ d.nama_mapel }}</td>
              <td>{{ d.nama_guru }}</td>
              <td v-if="d.ruang" :rowspan="d.ruang.rowspan" class="text-center">{{ d.ruang.nama }}</td>
            </tr>
          </tbody>
          <tbody v-else>
            <tr>
              <td class="text-center fst-italic py-3" colspan="5">Data tidak ditemukan.</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="table-responsive shadow" v-else>
        <table class="table table-sm table-hover table-light table-bordered border-secondary mb-0 align-middle"
          id="tabelJadwal">
          <thead class="text-center">
            <tr>
              <th class="position-sticky start-0">Rombel</th>
              <th>Jam Ke</th>
              <th>Mata Pelajaran</th>
              <th>Guru Pengampu</th>
              <th>Ruang</th>
            </tr>
          </thead>
          <tbody v-if="dataJadwal.length">
            <tr v-for="d in dataJadwal" :key="d">
              <td class="position-sticky start-0 text-center" v-if="d.rombel" :rowspan="d.rombel.rowspan">{{ d.rombel.nama
              }}</td>
              <td v-if="tampilkanWaktu" class="text-center">
                <strong>{{ d.jam_awal + " - " + d.jam_akhir }}</strong><br />
                <em>({{ d.waktu_mulai + " - " + d.waktu_selesai }})</em>
              </td>
              <td v-else class="text-center">{{ d.jam_awal + " - " + d.jam_akhir }}</td>
              <td>{{ d.nama_mapel }}</td>
              <td>{{ d.nama_guru }}</td>
              <td v-if="d.ruang" :rowspan="d.ruang.rowspan" class="text-center">{{ d.ruang.nama }}</td>
            </tr>
          </tbody>
          <tbody v-else>
            <tr>
              <td class="text-center fst-italic py-3" colspan="5">Data tidak ditemukan.</td>
            </tr>
          </tbody>
        </table>
      </div>
      <span v-if="dataJadwal.length && hari && !rombel" class="small text-muted fst-italic">
        Menampilkan {{ pageOffset + 1 }}-{{ pageOffset + dataJadwal.length }} dari total {{ count }} data
      </span>
      <div v-if="dataJadwal.length && hari && !rombel"
        class="row row-cols-auto justify-content-sm-between justify-content-center mt-2">
        <div class="row row-cols-auto">
          <label class="col-auto col-form-label-sm pe-1" for="numRows">Tampilkan:</label>
          <div class="col-auto ps-1">
            <select class="form-select form-select-sm shadow" v-model="pageLimit" id="numRows">
              <option v-for="l in arrayLimit" :key="l" :value="l">
                {{ l }} baris
              </option>
            </select>
          </div>
        </div>
        <div class="col-auto">
          <ul class="pagination pagination-sm shadow mb-0">
            <li class="page-item">
              <button class="page-link" :class="{ disabled: pageCurrent == 1 }"
                @click="pageCurrent > 1 ? pageCurrent-- : ''">&laquo;</button>
            </li>
            <li class="page-item" v-for="p in pageCount " :key="p">
              <button v-if="p == pageCurrent" class="page-link active fw-bold">{{ p }}</button>
              <button v-else-if="p == 1 || p == pageCount || Math.abs(p - pageCurrent) == 1" class="page-link"
                @click="pageCurrent = p">{{ p }}</button>
              <button v-else-if="(Math.abs(pageCurrent - 1) < 4 && Math.abs(p - 1) < 5) ||
                (Math.abs(pageCurrent - pageCount) < 4 && Math.abs(p - pageCount) < 5)" class="page-link"
                @click="pageCurrent = p">{{ p }}</button>
              <button
                v-else-if="(Math.abs(p - 1) == 5 || Math.abs(p - pageCount) == 5) && (Math.abs(p - pageCount) == 1 || Math.abs(p - 1) == 1)"
                class="page-link" @click="pageCurrent = p">{{ p }}</button>
              <button v-else-if="Math.abs(p - pageCurrent) == 2 ||
                (Math.abs(pageCurrent - 1) < 4 && Math.abs(p - 1) == 5) ||
                (Math.abs(pageCurrent - pageCount) < 4 && Math.abs(p - pageCount) == 5)" class="page-link px-1"
                @click="pageCurrent = p">...</button>
            </li>
            <li class="page-item">
              <button class="page-link" :class="{ disabled: pageCurrent == pageCount }"
                @click="pageCurrent < pageCount ? pageCurrent++ : ''">&raquo;</button>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import WebSocketService from './WebSocketService';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
export default {
  name: 'JadwalRombel',
  data() {
    return {
      dataListener: (d) => {
        if (d == 'hari' || d == 'statusHari') {
          this.getListHari();
        } else if (d == 'jam' || d == 'statusJam' || d == 'ruang' || d == 'lokasi') {
          if (this.hari || this.rombel) {
            this.getDataJadwal();
          }
        }
      },
      penjadwalanListener: (d) => {
        const data = JSON.parse(d);
        if (data.semester_id == this.semester.semester_id) {
          if (this.rombel) {
            if (data.rombel_id == this.rombel.rombel_id ||
              data.old_rombel_id == this.rombel.rombel_id ||
              data.new_rombel_id == this.rombel.rombel_id) {
              if (this.pageCurrent == 1) {
                this.getDataJadwal();
              } else {
                this.pageCurrent = 1;
              }
            } else if (data.listHariId) {
              const cekGuru = this.dataJadwal.findIndex((d) => d.guru_id == data.guru_id);
              if (cekGuru) {
                this.getDataJadwal();
              }
            }
          } else if (this.hari) {
            if (data.hari_id == this.hari.hari_id) {
              if (this.pageCurrent == 1) {
                this.getDataJadwal();
              } else {
                this.pageCurrent = 1;
              }
            } else if (data.listHariId) {
              const cekHari = data.listHariId.findIndex((h) => h.hari_id == this.hari.hari_id);
              if (cekHari) {
                const cekJadwal = this.dataJadwal.findIndex((d) => d.guru_id == data.guru_id || d.rombel_id == data.rombel_id);
                if (cekJadwal) {
                  if (this.pageCurrent == 1) {
                    this.getDataJadwal();
                  } else {
                    this.pageCurrent = 1;
                  }
                }
              }
            }
          }
        }
      },
      dataJadwal: [],
      listSemester: [],
      listRombel: [],
      listHari: [],
      semester: "",
      rombel: "",
      hari: "",
      tampilkanWaktu: false,
      arrayLimit: [10, 25, 50, 100, 250],
      count: 0,
      pageCurrent: 1,
      pageOffset: 0,
      pageLimit: 10,
      pageCount: 0,
    }
  },
  mounted() {
    WebSocketService.on("inserted-or-deleted-data", this.dataListener);
    WebSocketService.on("update-data", this.dataListener);
    WebSocketService.on("inserted-or-deleted-jadwal", this.penjadwalanListener);
    WebSocketService.on("update-jadwal", this.penjadwalanListener);
    this.pageLimit = this.batasBaris;
    this.semester = {
      tahun_ajaran_id: this.tpAktif.tahun_ajaran_id,
      ...this.semesterAktif
    };
    this.getListSemester();
  },
  beforeUnmount() {
    WebSocketService.off("inserted-or-deleted-data", this.dataListener);
    WebSocketService.off("update-data", this.dataListener);
    WebSocketService.off("inserted-or-deleted-jadwal", this.penjadwalanListener);
    WebSocketService.off("update-jadwal", this.penjadwalanListener);
  },
  computed: {
    tpAktif() {
      return this.$store.getters.getTp
    },
    semesterAktif() {
      return this.$store.getters.getSemester
    },
    batasBaris() {
      return this.$store.getters.getDefaultLimit
    },
  },
  watch: {
    semesterAktif() {
      this.semester = {
        tahun_ajaran_id: this.tpAktif.tahun_ajaran_id,
        ...this.semesterAktif
      };
    },
    semester() {
      this.getListRombel();
      this.getListHari();
      if (this.hari || this.rombel) {
        if (this.pageCurrent == 1) {
          this.getDataJadwal();
        } else {
          this.pageCurrent = 1;
        }
      }
    },
    rombel() {
      if (this.pageCurrent == 1) {
        this.getDataJadwal();
      } else {
        this.pageCurrent = 1;
      }
    },
    listHari() {
      const cekHari = this.listHari.findIndex((d) => d.hari_id == this.hari.hari_id);
      if (cekHari < 0) {
        this.hari = "";
      }
    },
    hari() {
      if (this.pageCurrent == 1) {
        this.getDataJadwal();
      } else {
        this.pageCurrent = 1;
      }
    },
    pageCurrent() {
      this.pageOffset = (this.pageCurrent - 1) * this.pageLimit;
      if (this.hari) {
        this.getDataJadwal();
      }
    },
    pageLimit() {
      if (this.pageCurrent == 1) {
        this.getDataJadwal();
      } else {
        this.pageCurrent = 1;
      }
    },
  },
  methods: {
    async getListSemester() {
      const headers = {
        Authorization: localStorage.getItem('token')
      };
      try {
        const response = await this.axios.post('/penjadwalan/semester/list', {}, {
          headers: headers
        });
        if (response.data) {
          this.listSemester = response.data.semester;
        }
      }
      catch (err) {
        if (err.response) {
          if (err.response.status == 401) {
            this.$swal({
              title: 'Gagal',
              text: 'Sesi berakhir. Silahkan login ulang.',
              icon: 'error',
              confirmButtonText: 'Baik'
            }).then(() => {
              this.$router.go();
            });
          } else {
            console.log(err);
          }
        } else {
          console.log(err)
        }
      }
    },
    async getListRombel() {
      const headers = {
        Authorization: localStorage.getItem('token')
      };
      try {
        const response = await this.axios.post('/rombel/aktif', {
          semester_id: this.semester.semester_id,
        }, {
          headers: headers
        });
        if (response.data) {
          this.listRombel = response.data.rombel;
        }
      }
      catch (err) {
        if (err.response) {
          if (err.response.status == 401) {
            this.$swal({
              title: 'Gagal',
              text: 'Sesi berakhir. Silahkan login ulang.',
              icon: 'error',
              confirmButtonText: 'Baik'
            }).then(() => {
              this.$router.go();
            });
          } else {
            console.log(err);
          }
        } else {
          console.log(err)
        }
      }
    },
    async getListHari() {
      const headers = {
        Authorization: localStorage.getItem('token')
      };
      try {
        const response = await this.axios.post('/hari/aktif', {}, {
          headers: headers
        });
        if (response.data) {
          this.listHari = response.data.hari;
        }
      }
      catch (err) {
        if (err.response) {
          if (err.response.status == 401) {
            this.$swal({
              title: 'Gagal',
              text: 'Sesi berakhir. Silahkan login ulang.',
              icon: 'error',
              confirmButtonText: 'Baik'
            }).then(() => {
              this.$router.go();
            });
          } else {
            console.log(err);
          }
        } else {
          console.log(err)
        }
      }
    },
    async getDataJadwal() {
      if (this.rombel || this.hari) {
        let memuat = this.$loading.show({
          container: this.$refs.table,
          loader: 'dots'
        });
        const headers = {
          Authorization: localStorage.getItem('token')
        };
        try {
          const response = await this.axios.post('/jadwal/rombel', {
            semester_id: this.semester.semester_id,
            rombel_id: this.rombel ? this.rombel.rombel_id : null,
            hari_id: this.hari ? this.hari.hari_id : null,
            limit: this.pageLimit,
            offset: this.pageOffset,
          }, {
            headers: headers
          });
          if (response.data) {
            const jadwalTemp = response.data.jadwal;
            for (const j of jadwalTemp) {
              if (j.waktu_mulai_khusus && j.waktu_selesai_khusus) {
                j.waktu_mulai = j.waktu_mulai_khusus.split(':').slice(0, 2).join(':');
                j.waktu_selesai = j.waktu_selesai_khusus.split(':').slice(0, 2).join(':');
              } else {
                j.waktu_mulai = j.waktu_mulai.split(':').slice(0, 2).join(':');
                j.waktu_selesai = j.waktu_selesai.split(':').slice(0, 2).join(':');
              }
            }
            if (this.rombel && this.hari) {
              for (let i = 0; i < jadwalTemp.length; i++) {
                let rowRuang = 1;
                for (let j = i; j < jadwalTemp.length; j++) {
                  if (jadwalTemp[i].lokasi_id == Object(jadwalTemp[j + 1]).lokasi_id &&
                    jadwalTemp[i].ruang_id == Object(jadwalTemp[j + 1]).ruang_id) {
                    rowRuang++;
                  } else {
                    jadwalTemp[i].ruang = {
                      nama: jadwalTemp[i].kode_lokasi + ' - ' + jadwalTemp[i].nama_ruang,
                      rowspan: rowRuang
                    }
                    i = j;
                    break;
                  }
                }
              }
            } else if (this.rombel) {
              let i = 0;
              while (i < jadwalTemp.length) {
                let rowHari = 1;
                let j = i + 1;
                while (jadwalTemp[i].hari_id == Object(jadwalTemp[j]).hari_id) {
                  j++;
                  rowHari++;
                }
                jadwalTemp[i].hari = {
                  nama: jadwalTemp[i].nama_hari,
                  rowspan: rowHari
                };
                i = j;
              }
              i = 0;
              while (i < jadwalTemp.length) {
                let rowRuang = 1;
                let j = i + 1;
                while (jadwalTemp[i].hari_id == Object(jadwalTemp[j]).hari_id &&
                  jadwalTemp[i].lokasi_id == Object(jadwalTemp[j]).lokasi_id &&
                  jadwalTemp[i].ruang_id == Object(jadwalTemp[j]).ruang_id) {
                  j++;
                  rowRuang++;
                }
                jadwalTemp[i].ruang = {
                  nama: jadwalTemp[i].kode_lokasi + ' - ' + jadwalTemp[i].nama_ruang,
                  rowspan: rowRuang
                }
                i = j;
              }
            } else {
              let i = 0;
              while (i < jadwalTemp.length) {
                let rowRombel = 1;
                let j = i + 1;
                while (jadwalTemp[i].rombel_id == Object(jadwalTemp[j]).rombel_id) {
                  j++;
                  rowRombel++;
                }
                jadwalTemp[i].rombel = {
                  nama: jadwalTemp[i].nama_rombel,
                  rowspan: rowRombel
                };
                i = j;
              }
              i = 0;
              while (i < jadwalTemp.length) {
                let rowRuang = 1;
                let j = i + 1;
                while (jadwalTemp[i].rombel_id == Object(jadwalTemp[j]).rombel_id &&
                  jadwalTemp[i].lokasi_id == Object(jadwalTemp[j]).lokasi_id &&
                  jadwalTemp[i].ruang_id == Object(jadwalTemp[j]).ruang_id) {
                  j++;
                  rowRuang++;
                }
                jadwalTemp[i].ruang = {
                  nama: jadwalTemp[i].kode_lokasi + ' - ' + jadwalTemp[i].nama_ruang,
                  rowspan: rowRuang
                }
                i = j;
              }
            }
            this.dataJadwal = jadwalTemp;
            this.count = response.data.count;
            this.pageCount = Math.ceil(this.count / this.pageLimit) || 0;
          }
        }
        catch (err) {
          if (err.response) {
            if (err.response.status == 401) {
              this.$swal({
                title: 'Gagal',
                text: 'Sesi berakhir. Silahkan login ulang.',
                icon: 'error',
                confirmButtonText: 'Baik'
              }).then(() => {
                this.$router.go();
              });
            } else {
              console.log(err);
            }
          } else {
            console.log(err)
          }
        }
        setTimeout(() => {
          memuat.hide();
        }, 250);
      }
    },
    unduhPDF() {
      if (this.dataJadwal.length) {
        const doc = new jsPDF({
          orientation: 'p',
          unit: 'mm',
          format: 'a4',
        });

        const judul = this.rombel && this.hari ? `Jadwal Pelajaran ${this.rombel.nama} Hari ${this.hari.nama}` :
          this.rombel ? `Jadwal Pelajaran ${this.rombel.nama}` : `Jadwal Pelajaran Hari ${this.hari.nama}`;

        doc.setFontSize(14);
        doc.text(judul, 20, 15);

        const columnStyles = this.rombel && this.hari ? {
          0: { halign: 'center', cellWidth: 'wrap', fontSize: this.tampilkanWaktu ? 8 : 10 },
          3: { halign: 'center' },
        } : {
          0: { halign: 'center', cellWidth: 'wrap' },
          1: { halign: 'center', cellWidth: 'wrap', fontSize: this.tampilkanWaktu ? 8 : 10 },
          4: { halign: 'center' },
        }

        doc.autoTable({
          html: '#tabelJadwal',
          theme: 'grid',
          margin: {
            top: 20,
            right: 15,
            bottom: 15,
            left: 20
          },
          headStyles: {
            halign: 'center',
            cellPadding: 2,
            fillColor: '#184b89'
          },
          styles: {
            cellPadding: {
              top: 1,
              right: 2,
              bottom: 1,
              left: 2
            },
            valign: 'middle',
            lineWidth: 0.3,
            lineColor: '#666666'
          },
          columnStyles: columnStyles,
        });

        const namaFile = this.rombel && this.hari ? `${this.rombel.nama}_${this.hari.nama}.pdf` :
          this.rombel ? this.rombel.nama + '.pdf' : this.hari.nama + '.pdf';

        doc.save(namaFile);
      } else {
        this.$swal({
          title: 'Perhatian',
          text: 'Jadwal kosong!',
          icon: 'warning',
          confirmButtonText: 'Baik'
        });
      }
    }
  },
}
</script>

<style></style>