import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import VueAxios from "vue-axios";
import axios from "axios";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap";
import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
import { LoadingPlugin } from "vue-loading-overlay";
import "vue-loading-overlay/dist/css/index.css";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import JsonCSV from "vue-json-csv";

axios.defaults.withCredentials = true;

const app = createApp(App);

// axios.defaults.baseURL = "http://backend-jadwal.com:3007";
// app.config.globalProperties.$auth = "http://auth.com:3000";
// app.config.globalProperties.$sso = "http://sso.com:200";
// app.config.globalProperties.$appUrl = "http://jadwal.com:207";
// app.config.globalProperties.$appId = "5e7f2a59-10cd-11ef-801e-ac120340b234";

// axios.defaults.baseURL = "http://backend-jadwal.local";
// app.config.globalProperties.$auth = "http://auth.local";
// app.config.globalProperties.$sso = "http://sso.local";
// app.config.globalProperties.$appUrl = "http://jadwal.local";
// app.config.globalProperties.$appId = "537959d4-314c-11ef-9288-d89ef328bfa0";

axios.defaults.baseURL = "https://localbackend-jadwal.sitepgri.com";
app.config.globalProperties.$auth = "https://localauth.sitepgri.com";
app.config.globalProperties.$sso = "https://sso.sitepgri.com";
app.config.globalProperties.$appUrl = "https://jadwal.sitepgri.com";
app.config.globalProperties.$appId = "9a8740a1-57b8-11ee-a065-ee52fd8f2043";

app.use(router);
app.use(store);
app.use(VueAxios, axios);
app.use(VueSweetalert2);
app.use(LoadingPlugin, {
    canCancel: false,
    color: "#002b80",
    backgroundColor: "#ccddff",
    opacity: 0.3,
    zIndex: 9999,
});
app.component("v-select", vSelect);
app.component("downloadCsv", JsonCSV);

app.mount("#app");
