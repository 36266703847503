<template>
  <h4>Data Pendukung Jadwal</h4>
  <div class="card shadow">
    <div class="card-header">
      <div class="row row-cols-auto mx-auto">
        <ul class="nav nav-pills card-header-pills">
          <li class="nav-item">
            <router-link to="/data/hari" class="nav-link" active-class="active">Hari</router-link>
          </li>
          <li class="nav-item">
            <router-link to="/data/non-kbm" class="nav-link" active-class="active">Non KBM</router-link>
          </li>
          <li class="nav-item">
            <router-link to="/data/hari-libur" class="nav-link" active-class="active">Hari Libur</router-link>
          </li>
          <li class="nav-item">
            <router-link to="/data/jam" class="nav-link" active-class="active">Jam</router-link>
          </li>
          <li class="nav-item">
            <router-link to="/data/jam-khusus" class="nav-link" active-class="active">Jam Khusus</router-link>
          </li>
          <li class="nav-item">
            <router-link to="/data/ruang" class="nav-link" active-class="active">Ruang</router-link>
          </li>
          <li class="nav-item">
            <router-link to="/data/lokasi" class="nav-link" active-class="active">Lokasi</router-link>
          </li>
          <li class="nav-item">
            <router-link to="/data/kalender" class="nav-link" active-class="active">Kalender</router-link>
          </li>
          <li class="nav-item">
            <router-link to="/data/mapel-diampu" class="nav-link" active-class="active">Mapel Diampu</router-link>
          </li>
          <li class="nav-item">
            <router-link to="/data/piket" class="nav-link" active-class="active">Jenis Piket</router-link>
          </li>
        </ul>
        <div v-if="!$route.params.data" class="col-auto ms-auto my-auto pe-0">
          <select class="form-select form-select-sm shadow-sm" v-model="semester">
            <option disabled value="">-- Pilih Semester --</option>
            <option v-for="s in listSemester" :key="s" :value="s">{{ s.nama }}</option>
          </select>
        </div>
      </div>
    </div>
    <div class="card-body">
      <div v-if="!$route.params.data" class="card-text">
        <div class="hstack gap-2 mb-2">
          <h5 class="my-0">{{ semesterAktif.nama }}</h5>
          <div class="vr"></div>
          <button class="btn btn-sm btn-outline-dark" data-bs-toggle="modal"
            data-bs-target="#gantiSemester">Ganti</button>
        </div>
        <div class="row row-cols-2 row-cols-sm-3 row-cols-lg-4 g-3">
          <div class="col">
            <div class="card shadow h-100">
              <div class="card-header d-flex">
                <span class="h6 m-0">Hari</span>
                <router-link to="/data/hari" class="ms-auto d-inline-flex align-items-center">
                  <svg xmlns="http://www.w3.org/2000/svg" height="18" fill="currentColor"
                    class="bi bi-box-arrow-in-up-right" viewBox="0 0 16 16">
                    <path fill-rule="evenodd"
                      d="M6.364 13.5a.5.5 0 0 0 .5.5H13.5a1.5 1.5 0 0 0 1.5-1.5v-10A1.5 1.5 0 0 0 13.5 1h-10A1.5 1.5 0 0 0 2 2.5v6.636a.5.5 0 1 0 1 0V2.5a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 .5.5v10a.5.5 0 0 1-.5.5H6.864a.5.5 0 0 0-.5.5z" />
                    <path fill-rule="evenodd"
                      d="M11 5.5a.5.5 0 0 0-.5-.5h-5a.5.5 0 0 0 0 1h3.793l-8.147 8.146a.5.5 0 0 0 .708.708L10 6.707V10.5a.5.5 0 0 0 1 0v-5z" />
                  </svg>
                </router-link>
              </div>
              <div class="card-body">
                <span class="h1">{{ dataJadwal.hari }}</span><span class="ms-2 text-body-secondary  fs-6">data
                  aktif</span>
              </div>
            </div>
          </div>
          <div class="col">
            <div class="card shadow h-100">
              <div class="card-header d-flex">
                <span class="h6 m-0">Non KBM</span>
                <router-link to="/data/non-kbm" class="ms-auto d-inline-flex align-items-center">
                  <svg xmlns="http://www.w3.org/2000/svg" height="18" fill="currentColor"
                    class="bi bi-box-arrow-in-up-right" viewBox="0 0 16 16">
                    <path fill-rule="evenodd"
                      d="M6.364 13.5a.5.5 0 0 0 .5.5H13.5a1.5 1.5 0 0 0 1.5-1.5v-10A1.5 1.5 0 0 0 13.5 1h-10A1.5 1.5 0 0 0 2 2.5v6.636a.5.5 0 1 0 1 0V2.5a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 .5.5v10a.5.5 0 0 1-.5.5H6.864a.5.5 0 0 0-.5.5z" />
                    <path fill-rule="evenodd"
                      d="M11 5.5a.5.5 0 0 0-.5-.5h-5a.5.5 0 0 0 0 1h3.793l-8.147 8.146a.5.5 0 0 0 .708.708L10 6.707V10.5a.5.5 0 0 0 1 0v-5z" />
                  </svg>
                </router-link>
              </div>
              <div class="card-body">
                <span class="h1">{{ dataJadwal.libur }}</span><span class="ms-2 text-body-secondary  fs-6">data
                  aktif</span>
              </div>
            </div>
          </div>
          <div class="col">
            <div class="card shadow h-100">
              <div class="card-header d-flex">
                <span class="h6 m-0">Hari Libur</span>
                <router-link to="/data/hari-libur" class="ms-auto d-inline-flex align-items-center">
                  <svg xmlns="http://www.w3.org/2000/svg" height="18" fill="currentColor"
                    class="bi bi-box-arrow-in-up-right" viewBox="0 0 16 16">
                    <path fill-rule="evenodd"
                      d="M6.364 13.5a.5.5 0 0 0 .5.5H13.5a1.5 1.5 0 0 0 1.5-1.5v-10A1.5 1.5 0 0 0 13.5 1h-10A1.5 1.5 0 0 0 2 2.5v6.636a.5.5 0 1 0 1 0V2.5a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 .5.5v10a.5.5 0 0 1-.5.5H6.864a.5.5 0 0 0-.5.5z" />
                    <path fill-rule="evenodd"
                      d="M11 5.5a.5.5 0 0 0-.5-.5h-5a.5.5 0 0 0 0 1h3.793l-8.147 8.146a.5.5 0 0 0 .708.708L10 6.707V10.5a.5.5 0 0 0 1 0v-5z" />
                  </svg>
                </router-link>
              </div>
              <div class="card-body">
                <span class="h1">{{ dataJadwal.hariLibur }}</span><span class="ms-2 text-body-secondary  fs-6">data
                  aktif</span>
              </div>
            </div>
          </div>
          <div class="col">
            <div class="card shadow h-100">
              <div class="card-header d-flex">
                <span class="h6 m-0">Jam</span>
                <router-link to="/data/jam" class="ms-auto d-inline-flex align-items-center">
                  <svg xmlns="http://www.w3.org/2000/svg" height="18" fill="currentColor"
                    class="bi bi-box-arrow-in-up-right" viewBox="0 0 16 16">
                    <path fill-rule="evenodd"
                      d="M6.364 13.5a.5.5 0 0 0 .5.5H13.5a1.5 1.5 0 0 0 1.5-1.5v-10A1.5 1.5 0 0 0 13.5 1h-10A1.5 1.5 0 0 0 2 2.5v6.636a.5.5 0 1 0 1 0V2.5a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 .5.5v10a.5.5 0 0 1-.5.5H6.864a.5.5 0 0 0-.5.5z" />
                    <path fill-rule="evenodd"
                      d="M11 5.5a.5.5 0 0 0-.5-.5h-5a.5.5 0 0 0 0 1h3.793l-8.147 8.146a.5.5 0 0 0 .708.708L10 6.707V10.5a.5.5 0 0 0 1 0v-5z" />
                  </svg>
                </router-link>
              </div>
              <div class="card-body">
                <span class="h1">{{ dataJadwal.jam }}</span><span class="ms-2 text-body-secondary  fs-6">data
                  aktif</span>
              </div>
            </div>
          </div>
          <div class="col">
            <div class="card shadow h-100">
              <div class="card-header d-flex">
                <span class="h6 m-0">Ruang</span>
                <router-link to="/data/ruang" class="ms-auto d-inline-flex align-items-center">
                  <svg xmlns="http://www.w3.org/2000/svg" height="18" fill="currentColor"
                    class="bi bi-box-arrow-in-up-right" viewBox="0 0 16 16">
                    <path fill-rule="evenodd"
                      d="M6.364 13.5a.5.5 0 0 0 .5.5H13.5a1.5 1.5 0 0 0 1.5-1.5v-10A1.5 1.5 0 0 0 13.5 1h-10A1.5 1.5 0 0 0 2 2.5v6.636a.5.5 0 1 0 1 0V2.5a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 .5.5v10a.5.5 0 0 1-.5.5H6.864a.5.5 0 0 0-.5.5z" />
                    <path fill-rule="evenodd"
                      d="M11 5.5a.5.5 0 0 0-.5-.5h-5a.5.5 0 0 0 0 1h3.793l-8.147 8.146a.5.5 0 0 0 .708.708L10 6.707V10.5a.5.5 0 0 0 1 0v-5z" />
                  </svg>
                </router-link>
              </div>
              <div class="card-body">
                <span class="h1">{{ dataJadwal.ruang }}</span><span class="ms-2 text-body-secondary  fs-6">data
                  aktif</span>
              </div>
            </div>
          </div>
          <div class="col">
            <div class="card shadow h-100">
              <div class="card-header d-flex">
                <span class="h6 m-0">Lokasi</span>
                <router-link to="/data/lokasi" class="ms-auto d-inline-flex align-items-center">
                  <svg xmlns="http://www.w3.org/2000/svg" height="18" fill="currentColor"
                    class="bi bi-box-arrow-in-up-right" viewBox="0 0 16 16">
                    <path fill-rule="evenodd"
                      d="M6.364 13.5a.5.5 0 0 0 .5.5H13.5a1.5 1.5 0 0 0 1.5-1.5v-10A1.5 1.5 0 0 0 13.5 1h-10A1.5 1.5 0 0 0 2 2.5v6.636a.5.5 0 1 0 1 0V2.5a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 .5.5v10a.5.5 0 0 1-.5.5H6.864a.5.5 0 0 0-.5.5z" />
                    <path fill-rule="evenodd"
                      d="M11 5.5a.5.5 0 0 0-.5-.5h-5a.5.5 0 0 0 0 1h3.793l-8.147 8.146a.5.5 0 0 0 .708.708L10 6.707V10.5a.5.5 0 0 0 1 0v-5z" />
                  </svg>
                </router-link>
              </div>
              <div class="card-body">
                <span class="h1">{{ dataJadwal.lokasi }}</span><span class="ms-2 text-body-secondary  fs-6">data</span>
              </div>
            </div>
          </div>
          <div class="col">
            <div class="card shadow h-100">
              <div class="card-header d-flex">
                <span class="h6 m-0">Kalender</span>
                <router-link to="/data/kalender" class="ms-auto d-inline-flex align-items-center">
                  <svg xmlns="http://www.w3.org/2000/svg" height="18" fill="currentColor"
                    class="bi bi-box-arrow-in-up-right" viewBox="0 0 16 16">
                    <path fill-rule="evenodd"
                      d="M6.364 13.5a.5.5 0 0 0 .5.5H13.5a1.5 1.5 0 0 0 1.5-1.5v-10A1.5 1.5 0 0 0 13.5 1h-10A1.5 1.5 0 0 0 2 2.5v6.636a.5.5 0 1 0 1 0V2.5a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 .5.5v10a.5.5 0 0 1-.5.5H6.864a.5.5 0 0 0-.5.5z" />
                    <path fill-rule="evenodd"
                      d="M11 5.5a.5.5 0 0 0-.5-.5h-5a.5.5 0 0 0 0 1h3.793l-8.147 8.146a.5.5 0 0 0 .708.708L10 6.707V10.5a.5.5 0 0 0 1 0v-5z" />
                  </svg>
                </router-link>
              </div>
              <div class="card-body">
                <span class="h1">{{ dataJadwal.kalender }}</span><span class="ms-2 text-body-secondary  fs-6">data
                  aktif</span>
              </div>
            </div>
          </div>
          <div class="col">
            <div class="card shadow h-100">
              <div class="card-header d-flex">
                <span class="h6 m-0">Jenis Piket</span>
                <router-link to="/data/piket" class="ms-auto d-inline-flex align-items-center">
                  <svg xmlns="http://www.w3.org/2000/svg" height="18" fill="currentColor"
                    class="bi bi-box-arrow-in-up-right" viewBox="0 0 16 16">
                    <path fill-rule="evenodd"
                      d="M6.364 13.5a.5.5 0 0 0 .5.5H13.5a1.5 1.5 0 0 0 1.5-1.5v-10A1.5 1.5 0 0 0 13.5 1h-10A1.5 1.5 0 0 0 2 2.5v6.636a.5.5 0 1 0 1 0V2.5a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 .5.5v10a.5.5 0 0 1-.5.5H6.864a.5.5 0 0 0-.5.5z" />
                    <path fill-rule="evenodd"
                      d="M11 5.5a.5.5 0 0 0-.5-.5h-5a.5.5 0 0 0 0 1h3.793l-8.147 8.146a.5.5 0 0 0 .708.708L10 6.707V10.5a.5.5 0 0 0 1 0v-5z" />
                  </svg>
                </router-link>
              </div>
              <div class="card-body">
                <span class="h1">{{ dataJadwal.piket }}</span><span class="ms-2 text-body-secondary  fs-6">data
                  aktif</span>
              </div>
            </div>
          </div>
        </div>
        <div class="hstack gap-1 mt-2">
          <div class="col-auto">
            Jumlah baris default:
          </div>
          <div class="col-auto">
            <select class="form-select form-select-sm shadow" v-model="limit" @change="changeDefaultLimit">
              <option v-for="l in arrayLimit" :key="l" :value="l">
                {{ l }} baris
              </option>
            </select>
          </div>
        </div>
      </div>
      <data-hari v-else-if="$route.params.data === 'hari'" />
      <non-kbm v-else-if="$route.params.data === 'non-kbm'" />
      <hari-libur v-else-if="$route.params.data === 'hari-libur'" />
      <data-jam v-else-if="$route.params.data === 'jam'" />
      <data-jam-khusus v-else-if="$route.params.data === 'jam-khusus'" />
      <data-ruang v-else-if="$route.params.data === 'ruang'" />
      <data-lokasi v-else-if="$route.params.data === 'lokasi'" />
      <kalender-akademik v-else-if="$route.params.data === 'kalender'" />
      <mapel-diampu v-else-if="$route.params.data === 'mapel-diampu'" />
      <jenis-piket v-else-if="$route.params.data === 'piket'" />
      <not-found v-else />
    </div>
    <div class="modal fade" id="gantiSemester" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
      aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content vl-parent" ref="formGanti">
          <form @submit.prevent="gantiSemesterPenjadwalan">
            <div class="modal-header">
              <legend class="modal-title">Ganti Semester Penjadwalan</legend>
              <button type="button" id="tutupFormGanti" class="btn-close" data-bs-dismiss="modal"></button>
            </div>
            <div class="modal-body">
              <div class="row mb-3">
                <label class="col-sm-3 col-form-label col-form-label-sm">Semester</label>
                <div class="col-sm-9">
                  <select class="form-select form-select-sm" v-model="semesterTerpilih" required>
                    <option disabled value="">-- Pilih Semester --</option>
                    <option v-for="s in listSemesterTersedia" :key="s" :value="s">{{ s.nama_semester }}</option>
                  </select>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button type="submit" class="btn btn-primary">Ganti Semester</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DataHari from '@/components/DataHari.vue';
import NonKbm from '@/components/NonKbm.vue';
import HariLibur from '@/components/HariLibur.vue';
import DataJam from '@/components/DataJam.vue';
import DataJamKhusus from '@/components/DataJamKhusus.vue';
import DataRuang from '@/components/DataRuang.vue';
import DataLokasi from '@/components/DataLokasi.vue';
import KalenderAkademik from '@/components/KalenderAkademik.vue';
import MapelDiampu from '@/components/MapelDiampu.vue';
import JenisPiket from '@/components/JenisPiket.vue';
import NotFound from '@/components/NotFound.vue';
import WebSocketService from '@/components/WebSocketService';
export default {
  components: { DataHari, NonKbm, HariLibur, DataJam, DataJamKhusus, DataRuang, DataLokasi, KalenderAkademik, MapelDiampu, JenisPiket, NotFound },
  name: 'DataView',
  data() {
    return {
      insertOrDeleteListener: (d) => {
        const jenisData = ['hari', 'libur', 'hariLibur', 'jam', 'ruang', 'lokasi', 'kalender', 'piket'];
        if (jenisData.includes(d)) {
          this.getDataJadwal();
        }
      },
      updateListener: (d) => {
        const jenisData = ['statusHari', 'statusLibur', 'statusJam', 'statusRuang', 'statusPiket'];
        if (jenisData.includes(d)) {
          this.getDataJadwal();
        }
      },
      dataJadwal: {
        hari: 0,
        libur: 0,
        hariLibur: 0,
        jam: 0,
        ruang: 0,
        lokasi: 0,
        kalender: 0,
        piket: 0,
      },
      arrayLimit: [10, 25, 50, 100, 250],
      limit: 10,
      listSemesterTersedia: [],
      semesterTerpilih: "",
      listSemester: [],
      semester: "",
    }
  },
  mounted() {
    if (this.user.ket === 'it' || this.user.ket === 'kurikulum') {
      WebSocketService.on("inserted-or-deleted-data", this.insertOrDeleteListener);
      WebSocketService.on("update-data", this.updateListener);
      let memuat = this.$loading.show({
        loader: 'dots'
      });
      this.semester = {
        tahun_ajaran_id: this.tpAktif.tahun_ajaran_id,
        ...this.semesterAktif
      };
      this.getListSemesterTersedia();
      this.getListSemester();
      this.limit = this.batasBaris;
      setTimeout(() => {
        memuat.hide();
      }, 250);
    } else {
      this.$router.push('/forbidden/data');
    }
  },
  beforeUnmount() {
    WebSocketService.off('inserted-or-deleted-data', this.insertOrDeleteListener);
    WebSocketService.off("update-data", this.updateListener);
  },
  computed: {
    user() {
      return this.$store.state.userData;
    },
    tpAktif() {
      return this.$store.getters.getTp
    },
    semesterAktif() {
      return this.$store.getters.getSemester
    },
    batasBaris() {
      return this.$store.getters.getDefaultLimit
    },
  },
  watch: {
    semesterAktif() {
      this.semester = {
        tahun_ajaran_id: this.tpAktif.tahun_ajaran_id,
        ...this.semesterAktif
      };
      this.getListSemesterTersedia();
    },
    semester() {
      this.getDataJadwal();
    },
  },
  methods: {
    changeDefaultLimit() {
      this.$store.dispatch('setDefaultLimit', this.limit);
    },
    async getListSemesterTersedia() {
      const headers = {
        Authorization: localStorage.getItem('token')
      };
      try {
        const response = await this.axios.post('/penjadwalan/semester-tersedia', {}, {
          headers: headers
        });
        if (response.data.semester) {
          this.listSemesterTersedia = response.data.semester;
        }
      }
      catch (err) {
        if (err.response) {
          if (err.response.status == 401) {
            this.$swal({
              title: 'Gagal',
              text: 'Sesi berakhir. Silahkan login ulang.',
              icon: 'error',
              confirmButtonText: 'Baik'
            }).then(() => {
              this.$router.go();
            });
          } else {
            console.log(err);
          }
        } else {
          console.log(err)
        }
      }
    },
    async getListSemester() {
      const headers = {
        Authorization: localStorage.getItem('token')
      };
      try {
        const response = await this.axios.post('/penjadwalan/semester/list', {}, {
          headers: headers
        });
        if (response.data) {
          this.listSemester = response.data.semester;
        }
      }
      catch (err) {
        if (err.response) {
          if (err.response.status == 401) {
            this.$swal({
              title: 'Gagal',
              text: 'Sesi berakhir. Silahkan login ulang.',
              icon: 'error',
              confirmButtonText: 'Baik'
            }).then(() => {
              this.$router.go();
            });
          } else {
            console.log(err);
          }
        } else {
          console.log(err)
        }
      }
    },
    async gantiSemesterPenjadwalan() {
      let memuat = this.$loading.show({
        container: this.$refs.formGanti,
        loader: 'spinner'
      });
      const headers = {
        Authorization: localStorage.getItem('token')
      };
      try {
        const response = await this.axios.post('/penjadwalan/aktifkan', {
          penjadwalan_id: this.semesterTerpilih.penjadwalan_id,
          semester_id: this.semesterTerpilih.semester_id
        }, { headers: headers });
        if (response.data) {
          document.getElementById('tutupFormGanti').click();
          this.$swal({
            title: 'Sukses',
            text: 'Berhasil mengganti data Semester Penjadwalan',
            icon: 'success',
            showConfirmButton: false,
            timer: 1500
          }).then(() => {
            this.semesterTerpilih = "";
          })
        } else {
          this.$swal({
            title: 'Gagal',
            text: 'Data tidak ditemukan',
            icon: 'error',
            confirmButtonText: 'Baik'
          });
        }
      }
      catch (err) {
        if (err.response) {
          if (err.response.status == 401) {
            this.$swal({
              title: 'Gagal',
              text: 'Sesi berakhir. Silahkan login ulang.',
              icon: 'error',
              confirmButtonText: 'Baik'
            }).then(() => {
              this.$router.go();
            });
          } else if (err.response.status == 403) {
            this.$swal({
              title: 'Gagal',
              text: 'Anda tidak memiliki akses untuk memperbarui data.',
              icon: 'error',
              confirmButtonText: 'Baik'
            });
          } else {
            this.$swal({
              title: 'Gagal',
              html: `<p class="text-danger">${err.response.data.error}</p>
                  <code class="text-secondary text-bg-secondary bg-opacity-10">code: ${err.response.data.code}<br/>
                    message: ${err.response.data.message}</code>`,
              icon: 'error',
              confirmButtonText: 'Baik'
            });
          }
        } else {
          console.log(err)
        }
      }
      setTimeout(() => {
        memuat.hide();
      }, 250);
    },
    async getDataJadwal() {
      const headers = {
        Authorization: localStorage.getItem('token')
      }
      try {
        const response = await this.axios.post('/penjadwalan/jumlah-data', {
          tahun_ajaran_id: this.semester.tahun_ajaran_id
        }, {
          headers: headers
        });
        if (response.data) {
          this.dataJadwal = response.data;
        }
      } catch (err) {
        if (err.response) {
          if (err.response.status == 401) {
            this.$swal({
              title: 'Gagal',
              text: 'Sesi berakhir. Silahkan login ulang.',
              icon: 'error',
              confirmButtonText: 'Baik'
            }).then(() => {
              this.$router.go();
            });
          } else {
            console.log(err);
          }
        } else {
          console.log(err);
        }
      }
    }
  }
}
</script>

<style></style>