<template>
  <h4>Pembuatan Jadwal Mengajar Guru</h4>
  <div class="card shadow">
    <div class="card-header">
      <div class="col-md-10 col-lg-8 col-xl-6 mx-auto my-3">
        <div class="card shadow vl-parent" ref="formCreate">
          <div class="card-header">
            <div class="row row-cols-auto justify-content-between">
              <div class="col-auto d-inline-flex">
                <h5 class="my-auto">Penjadwalan</h5>
              </div>
              <div class="col-auto ms-auto">
                <select class="form-select form-select-sm shadow-sm" v-model="semester">
                  <option disabled value="">-- Pilih Semester --</option>
                  <option v-for="s in listSemester" :key="s" :value="s">{{ s.nama }}</option>
                </select>
              </div>
            </div>
          </div>
          <form @submit.prevent="simpanJadwal">
            <div class="card-body">
              <div class="row mb-1">
                <label class="col-sm-4 col-form-label col-form-label-sm">Guru</label>
                <div class="col-sm-8">
                  <v-select v-model="guru" placeholder="Pilih Guru Pengampu" label="nama" :options="listGuru">
                    <template #search="{ attributes, events }">
                      <input class="vs__search" :required="!guru" v-bind="attributes" v-on="events" />
                    </template>
                    <template #no-options>
                      Guru tidak ditemukan.
                    </template>
                  </v-select>
                </div>
              </div>
              <div class="row mb-1">
                <label class="col-sm-4 col-form-label col-form-label-sm">Hari</label>
                <div class="col-sm-8">
                  <v-select v-model="hari" placeholder="Pilih Hari" label="nama_sisa" :options="listHari">
                    <template #search="{ attributes, events }">
                      <input class="vs__search" :required="!hari" v-bind="attributes" v-on="events" />
                    </template>
                    <template #no-options>
                      Hari tidak ditemukan.
                    </template>
                  </v-select>
                </div>
              </div>
              <div class="row mb-1">
                <label class="col-sm-4 col-form-label col-form-label-sm">Mata Pelajaran</label>
                <div class="col-sm-8">
                  <v-select v-model="mapel" placeholder="Pilih Mata Pelajaran" label="nama_jam" :options="listMapel">
                    <template #search="{ attributes, events }">
                      <input class="vs__search" :required="!mapel" v-bind="attributes" v-on="events" />
                    </template>
                    <template #no-options>
                      Mata Pelajaran tidak ditemukan.
                    </template>
                  </v-select>
                </div>
              </div>
              <div class="row mb-1">
                <label class="col-sm-4 col-form-label col-form-label-sm">Rombongan Belajar</label>
                <div class="col-sm-8">
                  <v-select v-model="rombel" placeholder="Pilih Rombongan Belajar" label="nama_sisa_jam"
                    :options="listRombel">
                    <template #search="{ attributes, events }">
                      <input class="vs__search" :required="!rombel" v-bind="attributes" v-on="events" />
                    </template>
                    <template #no-options>
                      Rombongan Belajar tidak ditemukan.
                    </template>
                  </v-select>
                </div>
              </div>
              <div class="row mb-1">
                <label class="col-sm-4 col-form-label col-form-label-sm">Rentang Jam</label>
                <div class="col-sm-8 select-wrapper">
                  <div class="input-group input-group-sm">
                    <select class="form-control form-control-sm" :class="{ 'border-end-0': jamAwal }" v-model="jamAwal"
                      required>
                      <option selected value="" disabled>Jam Mulai</option>
                      <option v-for="j in listJamAwal" :key="j" :value="j.jam_id">
                        {{ j.jam_id }} ({{ j.mulai }})
                      </option>
                    </select>
                    <button type="button" v-if="jamAwal" @click="jamAwal = ''" class="reset-button border-start-0">
                      <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10">
                        <path
                          d="M6.895455 5l2.842897-2.842898c.348864-.348863.348864-.914488 0-1.263636L9.106534.261648c-.348864-.348864-.914489-.348864-1.263636 0L5 3.104545 2.157102.261648c-.348863-.348864-.914488-.348864-1.263636 0L.261648.893466c-.348864.348864-.348864.914489 0 1.263636L3.104545 5 .261648 7.842898c-.348864.348863-.348864.914488 0 1.263636l.631818.631818c.348864.348864.914773.348864 1.263636 0L5 6.895455l2.842898 2.842897c.348863.348864.914772.348864 1.263636 0l.631818-.631818c.348864-.348864.348864-.914489 0-1.263636L6.895455 5z">
                        </path>
                      </svg>
                    </button>
                    <span class="input-group-text">s.d.</span>
                    <select class="form-control form-control-sm" :class="{ 'border-end-0': jamAkhir }" v-model="jamAkhir"
                      required>
                      <option selected value="" disabled>Jam Selesai</option>
                      <option v-for="j in listJamAkhir" :key="j" :value="j.jam_id">
                        {{ j.jam_id }} ({{ j.selesai }})
                      </option>
                    </select>
                    <button type="button" v-if="jamAkhir" @click="jamAkhir = ''"
                      class="reset-button border-start-0 rounded-end-1">
                      <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10">
                        <path
                          d="M6.895455 5l2.842897-2.842898c.348864-.348863.348864-.914488 0-1.263636L9.106534.261648c-.348864-.348864-.914489-.348864-1.263636 0L5 3.104545 2.157102.261648c-.348863-.348864-.914488-.348864-1.263636 0L.261648.893466c-.348864.348864-.348864.914489 0 1.263636L3.104545 5 .261648 7.842898c-.348864.348863-.348864.914488 0 1.263636l.631818.631818c.348864.348864.914773.348864 1.263636 0L5 6.895455l2.842898 2.842897c.348863.348864.914772.348864 1.263636 0l.631818-.631818c.348864-.348864.348864-.914489 0-1.263636L6.895455 5z">
                        </path>
                      </svg>
                    </button>
                  </div>
                </div>
              </div>
              <div class="row">
                <label class="col-sm-4 col-form-label col-form-label-sm">Ruang</label>
                <div class="col-sm-8">
                  <v-select v-model="ruang" placeholder="Pilih Ruang" label="lokasi_ruang" :options="listRuang">
                    <template #search="{ attributes, events }">
                      <input class="vs__search" :required="!ruang" v-bind="attributes" v-on="events" />
                    </template>
                    <template #no-options>
                      Ruang tidak ditemukan.
                    </template>
                  </v-select>
                </div>
              </div>
            </div>
            <div class="card-footer">
              <button type="submit" class="btn btn-sm btn-secondary d-flex ms-auto shadow-sm">Simpan</button>
            </div>
          </form>
        </div>
      </div>
    </div>
    <div class="card-body" v-if="guru">
      <div class="row row-cols-auto justify-content-between mb-2 mx-auto gap-2">
        <div class="col-auto p-0 d-inline-flex">
          <h5 class="my-auto">Jadwal Mengajar/Piket {{ guru.nama }} ({{ jumlah.jumlah_jam_terjadwal + "/" +
            jumlah.total_jam
          }})</h5>
        </div>
        <div class="col-auto p-0" v-if="jadwalGuru.length">
          <button type="button" class="btn btn-sm btn-outline-danger" @click="confirmDeleteAll">
            Hapus jadwal guru
          </button>
        </div>
      </div>
      <div class="table-responsive shadow vl-parent" ref="table">
        <table class="table table-sm table-striped table-hover mb-0 align-middle">
          <thead class="table-secondary">
            <tr>
              <th class="position-sticky start-0">Hari</th>
              <th>Jam Ke</th>
              <th>Rombongan Belajar</th>
              <th>Mata Pelajaran</th>
              <th>Ruang</th>
              <th>Keterangan</th>
              <th>Tindakan</th>
            </tr>
          </thead>
          <tbody v-if="jadwalGuru.length">
            <tr v-for="d in jadwalGuru" :key="d">
              <td class="position-sticky start-0">{{ d.nama_hari }}</td>
              <td>
                <strong>{{ d.jam_awal + " - " + d.jam_akhir }}</strong><br />
                <em>({{ d.waktu_mulai + " - " + d.waktu_selesai }})</em>
              </td>
              <td>{{ d.nama_rombel || "-" }}</td>
              <td>{{ d.nama_mapel }}</td>
              <td>{{ d.kode_lokasi }} {{ d.nama_ruang == null ? "" : " - " + d.nama_ruang }}</td>
              <td>{{ d.keterangan || "-" }}</td>
              <td v-if="d.rombel_id">
                <div class="hstack gap-1 me-1">
                  <button class="btn btn-sm btn-outline-primary d-flex" @click="editData = d" data-bs-toggle="modal"
                    data-bs-target="#editData">
                    <svg xmlns="http://www.w3.org/2000/svg" width="13" fill="currentColor" class="bi bi-pencil-square"
                      viewBox="0 0 16 16">
                      <path
                        d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                      <path fill-rule="evenodd"
                        d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z" />
                    </svg>
                  </button>
                  <div class="vr"></div>
                  <button class="btn btn-sm btn-outline-danger d-flex" @click="confirmDelete(d)">
                    <svg xmlns="http://www.w3.org/2000/svg" width="13" fill="currentColor" class="bi bi-trash-fill"
                      viewBox="0 0 16 16">
                      <path
                        d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1H2.5zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5zM8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5zm3 .5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 1 0z" />
                    </svg>
                  </button>
                </div>
              </td>
              <td v-else>-</td>
            </tr>
          </tbody>
          <tbody v-else>
            <tr>
              <td class="text-center fst-italic py-3" colspan="7">Data tidak ditemukan.</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <div class="modal fade" id="editData" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
    aria-hidden="true">
    <div class="modal-lg modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <form @submit.prevent="perbaruiJadwal">
          <div class="modal-header">
            <legend class="modal-title">Edit Jadwal Mengajar {{ Object(guru).nama }}</legend>
            <button type="button" id="tutupFormEdit" class="btn-close" data-bs-dismiss="modal"></button>
          </div>
          <div class="modal-body">
            <div class="row mb-1">
              <label class="col-sm-4 col-form-label col-form-label-sm">Guru</label>
              <div class="col-sm-8">
                <span class="form-control form-control-sm fw-bold">{{ Object(guru).nama }}</span>
              </div>
            </div>
            <div class="row mb-1">
              <label class="col-sm-4 col-form-label col-form-label-sm">Hari</label>
              <div class="col-sm-8">
                <span class="form-control form-control-sm fw-bold">{{ editData.nama_hari }}</span>
              </div>
            </div>
            <div class="row mb-1">
              <label class="col-sm-4 col-form-label col-form-label-sm">Mata Pelajaran</label>
              <div class="col-sm-8">
                <v-select v-model="mapelEdit" :placeholder="editData.nama_mapel" label="nama_jam"
                  :options="listMapelEdit">
                  <template #no-options>
                    Mata Pelajaran tidak ditemukan.
                  </template>
                </v-select>
              </div>
            </div>
            <div class="row mb-1">
              <label class="col-sm-4 col-form-label col-form-label-sm">Rentang Jam</label>
              <div class="col-sm-8">
                <div class="input-group input-group-sm">
                  <span class="form-control fw-bold">{{ editData.jam_awal }} ({{ editData.waktu_mulai }})</span>
                  <span class="input-group-text">s.d.</span>
                  <span class="form-control fw-bold">{{ editData.jam_akhir }} ({{ editData.waktu_selesai }})</span>
                </div>
              </div>
            </div>
            <div class="row mb-1">
              <label class="col-sm-4 col-form-label col-form-label-sm">Rombongan Belajar</label>
              <div class="col-sm-8">
                <v-select v-model="rombelEdit"
                  :placeholder="rombelEditRequired ? 'Pilih Rombongan Belajar' : editData.nama_rombel" label="nama"
                  :options="listRombelEdit">
                  <template #search="{ attributes, events }">
                    <input class="vs__search" :required="rombelEditRequired" v-bind="attributes" v-on="events" />
                  </template>
                  <template #no-options>
                    Rombongan Belajar tidak ditemukan.
                  </template>
                </v-select>
              </div>
            </div>
            <div class="row">
              <label class="col-sm-4 col-form-label col-form-label-sm">Ruang</label>
              <div class="col-sm-8">
                <v-select v-model="ruangEdit" :placeholder="editData.kode_lokasi + ' - ' + editData.nama_ruang"
                  label="lokasi_ruang" :options="listRuangEdit">
                  <template #no-options>
                    Ruang tidak ditemukan.
                  </template>
                </v-select>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="submit" class="btn btn-primary">Perbarui</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import WebSocketService from './WebSocketService';

export default {
  name: 'PenjadwalanGuru',
  data() {
    return {
      dataListener: (d) => {
        if (this.guru) {
          switch (d) {
            case 'hari':
              this.getHariTersedia();
              this.getJadwalGuru();
              break;
            case 'statusHari':
              this.getHariTersedia();
              this.getJadwalGuru();
              break;
            case 'jam':
              this.getJamTersedia();
              break;
            case 'statusJam':
              this.getJamTersedia();
              break;
            case 'ruang':
              this.getRuangTersedia();
              break;
            case 'statusRuang':
              this.getRuangTersedia();
              break;
            default:
              break;
          }
        }
      },
      mapelDiampuListener: (d) => {
        const data = JSON.parse(d);
        if (data.semester_id == this.semester.semester_id) {
          if (this.guru) {
            this.getMapelTersedia();
            if (this.editData.jam_awal) {
              this.getMapelTersediaEdit();
            }
          }
        }
      },
      penjadwalanListener: (d) => {
        const data = JSON.parse(d);
        if (data.semester_id == this.semester.semester_id) {
          if ((data.guru_id && data.guru_id == this.guru.guru_id) ||
            (data.old_guru_id && data.old_guru_id == this.guru.guru_id) ||
            (data.new_guru_id && data.new_guru_id == this.guru.guru_id)) {
            document.getElementById('tutupFormEdit').click();
            this.getJadwalGuru();
            this.getHariTersedia();
            this.getMapelTersedia();
          } else if (data.hari_id == this.hari.hari_id || (data.listHariId && data.listHariId.includes(this.hari.hari_id))) {
            if (this.jamAkhir) {
              this.getRombelTersedia();
            }
          } else if (data.hari_id == this.editData.hari_id || (data.listHariId && data.listHariId.includes(this.editData.hari_id))) {
            this.getRombelTersediaEdit();
          }
        }
      },
      listSemester: [],
      listRombel: [],
      listHari: [],
      listMapel: [],
      listJamAwal: [],
      listJamAkhir: [],
      listGuru: [],
      listRuang: [],
      jadwalGuru: [],
      jumlah: "",
      semester: "",
      rombel: "",
      hari: "",
      mapel: "",
      jamAwal: "",
      jamAkhir: "",
      guru: "",
      ruang: "",
      ruangDigunakan: "",
      editData: {},
      listMapelEdit: [],
      listRombelEdit: [],
      listRuangEdit: [],
      mapelEdit: "",
      rombelEdit: "",
      ruangEdit: "",
      rombelEditRequired: false,
    }
  },
  mounted() {
    WebSocketService.on("inserted-or-deleted-data", this.dataListener);
    WebSocketService.on("update-data", this.dataListener);
    WebSocketService.on("inserted-or-deleted-mapel-diampu", this.mapelDiampuListener);
    WebSocketService.on("update-mapel-diampu", this.mapelDiampuListener);
    WebSocketService.on("inserted-or-deleted-jadwal", this.penjadwalanListener);
    WebSocketService.on("update-jadwal", this.penjadwalanListener);
    this.semester = {
      tahun_ajaran_id: this.tpAktif.tahun_ajaran_id,
      ...this.semesterAktif
    };
    this.getListSemester();
  },
  beforeUnmount() {
    WebSocketService.off("inserted-or-deleted-data", this.dataListener);
    WebSocketService.off("update-data", this.dataListener);
    WebSocketService.off("inserted-or-deleted-mapel-diampu", this.mapelDiampuListener);
    WebSocketService.off("update-mapel-diampu", this.mapelDiampuListener);
    WebSocketService.off("inserted-or-deleted-jadwal", this.penjadwalanListener);
    WebSocketService.off("update-jadwal", this.penjadwalanListener);
  },
  computed: {
    tpAktif() {
      return this.$store.getters.getTp
    },
    semesterAktif() {
      return this.$store.getters.getSemester
    },
  },
  watch: {
    semesterAktif() {
      document.getElementById('tutupFormEdit').click();
      this.semester = {
        tahun_ajaran_id: this.tpAktif.tahun_ajaran_id,
        ...this.semesterAktif
      };
    },
    semester() {
      this.guru = "";
      this.getListGuru();
    },
    guru() {
      if (!this.guru) {
        this.listHari = [];
        this.listMapel = [];
        this.jadwalGuru = [];
      } else {
        this.getHariTersedia();
        this.getMapelTersedia();
        this.getJadwalGuru();
      }
    },
    hari() {
      if (!this.hari) {
        this.ruang = "";
        this.listJamAwal = [];
        this.listRombel = [];
      } else {
        this.getJamTersedia();
        if (this.mapel) {
          this.getRombelTersedia();
        }
      }
    },
    listHari() {
      const cekHari = this.listHari.findIndex((d) => JSON.stringify(d) == JSON.stringify(this.hari));
      if (cekHari < 0) {
        this.hari = "";
      }
    },
    mapel() {
      if (this.mapel) {
        if (this.hari) {
          this.getRombelTersedia();
        }
        if (this.jamAwal) {
          this.getJamSelesai();
        }
      } else {
        this.listRombel = [];
        this.jamAkhir = "";
        this.listJamAkhir = [];
      }
    },
    listMapel() {
      const cekMapel = this.listMapel.findIndex((d) => JSON.stringify(d) == JSON.stringify(this.mapel));
      if (cekMapel < 0) {
        this.mapel = "";
      }
    },
    jamAwal() {
      if (this.jamAwal && this.mapel) {
        this.getJamSelesai();
      } else {
        this.jamAkhir = "";
        this.listJamAkhir = [];
      }
    },
    listJamAwal() {
      const cekJam = this.listJamAwal.findIndex((d) => d.jam_id == this.jamAwal);
      if (cekJam < 0) {
        this.jamAwal = "";
      } else {
        if (this.jamAwal && this.mapel) {
          this.getJamSelesai();
        }
      }
    },
    jamAkhir() {
      if (this.jamAkhir) {
        this.getRombelTersedia();
        this.getRuangTersedia();
      } else {
        this.listRombel = [];
        this.listRuang = [];
      }
    },
    listJamAkhir() {
      const cekJam = this.listJamAkhir.findIndex((d) => d.jam_id == this.jamAkhir);
      if (cekJam < 0) {
        this.jamAkhir = "";
      } else {
        this.getRombelTersedia();
        this.getRuangTersedia();
      }
    },
    rombel() {
      if (!this.rombel) {
        this.ruang = "";
        if (this.hari) {
          this.getJamTersedia();
        }
      } else {
        this.getJamTersedia();
        this.getRuangDigunakan();
      }
    },
    listRombel() {
      const cekRombel = this.listRombel.findIndex((d) => JSON.stringify(d) == JSON.stringify(this.rombel));
      if (cekRombel < 0) {
        this.rombel = "";
      }
    },
    listRuang() {
      const cekRuang = this.listRuang.findIndex((d) => JSON.stringify(d) == JSON.stringify(this.ruangDigunakan));
      if (cekRuang < 0) {
        this.ruang = "";
      } else {
        this.ruang = this.ruangDigunakan;
      }
    },
    editData() {
      if (this.mapelEdit) {
        this.mapelEdit = "";
      } else {
        this.getRombelTersediaEdit();
      }
      this.rombelEdit = "";
      this.ruangEdit = "";
      this.rombelEditRequired = false;
      this.getMapelTersediaEdit();
      this.getRuangTersediaEdit();
    },
    mapelEdit() {
      this.getRombelTersediaEdit();
    },
    rombelEdit() {
      if (this.rombelEdit) {
        this.rombelEditRequired = false;
      }
    },
    listRombelEdit() {
      const cekRombel = this.listRombelEdit.findIndex((d) => d.rombel_id == this.editData.rombel_id);
      if (cekRombel < 0) {
        this.rombelEditRequired = true;
      }
    },
  },
  methods: {
    async getListSemester() {
      const headers = {
        Authorization: localStorage.getItem('token')
      };
      try {
        const response = await this.axios.post('/penjadwalan/semester/list', {}, {
          headers: headers
        });
        if (response.data) {
          this.listSemester = response.data.semester;
        }
      }
      catch (err) {
        if (err.response) {
          if (err.response.status == 401) {
            this.$swal({
              title: 'Gagal',
              text: 'Sesi berakhir. Silahkan login ulang.',
              icon: 'error',
              confirmButtonText: 'Baik'
            }).then(() => {
              this.$router.go();
            });
          } else {
            console.log(err);
          }
        } else {
          console.log(err)
        }
      }
    },
    async getListGuru() {
      const headers = {
        Authorization: localStorage.getItem('token')
      };
      try {
        const response = await this.axios.post('/guru/aktif', {}, {
          headers: headers
        });
        if (response.data) {
          this.listGuru = response.data.guru;
        }
      }
      catch (err) {
        if (err.response) {
          if (err.response.status == 401) {
            this.$swal({
              title: 'Gagal',
              text: 'Sesi berakhir. Silahkan login ulang.',
              icon: 'error',
              confirmButtonText: 'Baik'
            }).then(() => {
              this.$router.go();
            });
          } else {
            console.log(err);
          }
        } else {
          console.log(err)
        }
      }
    },
    async getHariTersedia() {
      const headers = {
        Authorization: localStorage.getItem('token')
      };
      try {
        const response = await this.axios.post('/hari/tersedia', {
          semester_id: this.semester.semester_id,
          guru_id: this.guru.guru_id,
        }, {
          headers: headers
        });
        if (response.data) {
          const dataHari = response.data.hari;
          for (const h of dataHari) {
            h.nama_sisa = `${h.nama} (sisa ${h.jumlah_jam - h.jumlah} jam)`;
          }
          this.listHari = dataHari;
        }
      }
      catch (err) {
        if (err.response) {
          if (err.response.status == 401) {
            this.$swal({
              title: 'Gagal',
              text: 'Sesi berakhir. Silahkan login ulang.',
              icon: 'error',
              confirmButtonText: 'Baik'
            }).then(() => {
              this.$router.go();
            });
          } else {
            console.log(err);
          }
        } else {
          console.log(err)
        }
      }
    },
    async getMapelTersedia() {
      const headers = {
        Authorization: localStorage.getItem('token')
      };
      try {
        const response = await this.axios.post('/mapel/tersedia', {
          tahun_ajaran_id: this.semester.tahun_ajaran_id,
          semester_id: this.semester.semester_id,
          guru_id: this.guru.guru_id,
        }, {
          headers: headers
        });
        if (response.data) {
          const dataMapel = response.data.mapel;
          for (const m of dataMapel) {
            m.nama_jam = `${m.tingkat} - ${m.nama} (${m.jumlah_jam} jam)`
          }
          this.listMapel = dataMapel;
        }
      }
      catch (err) {
        if (err.response) {
          if (err.response.status == 401) {
            this.$swal({
              title: 'Gagal',
              text: 'Sesi berakhir. Silahkan login ulang.',
              icon: 'error',
              confirmButtonText: 'Baik'
            }).then(() => {
              this.$router.go();
            });
          } else {
            console.log(err);
          }
        } else {
          console.log(err)
        }
      }
    },
    async getJamTersedia() {
      const headers = {
        Authorization: localStorage.getItem('token')
      };
      try {
        const response = await this.axios.post('/jam/tersedia', {
          semester_id: this.semester.semester_id,
          hari_id: this.hari.hari_id,
          guru_id: this.guru.guru_id,
          rombel_id: Object(this.rombel).rombel_id,
        }, {
          headers: headers
        });
        if (response.data) {
          this.listJamAwal = response.data.jam;
          for (const j of this.listJamAwal) {
            if (j.mulai_khusus && j.selesai_khusus) {
              j.mulai = j.mulai_khusus.split(':').slice(0, 2).join(':');
              j.selesai = j.selesai_khusus.split(':').slice(0, 2).join(':');
            } else {
              j.mulai = j.mulai.split(':').slice(0, 2).join(':');
              j.selesai = j.selesai.split(':').slice(0, 2).join(':');
            }
          }
        }
      }
      catch (err) {
        if (err.response) {
          if (err.response.status == 401) {
            this.$swal({
              title: 'Gagal',
              text: 'Sesi berakhir. Silahkan login ulang.',
              icon: 'error',
              confirmButtonText: 'Baik'
            }).then(() => {
              this.$router.go();
            });
          } else {
            console.log(err);
          }
        } else {
          console.log(err)
        }
      }
    },
    getJamSelesai() {
      this.listJamAkhir = [];
      const sisaJamHari = this.hari.jumlah_jam - this.hari.jumlah;
      const sisaJamRombel = this.rombel ? this.rombel.sisa_jam : sisaJamHari;
      const batasJam = Math.min(sisaJamHari, this.mapel.jumlah_jam, sisaJamRombel);
      let jam = 0;
      for (let i in this.listJamAwal) {
        if (jam == batasJam) break;
        if (this.listJamAwal[i].jam_id >= this.jamAwal) {
          if (this.listJamAwal[i].jam_id == this.jamAwal) {
            this.listJamAkhir.push(this.listJamAwal[i]);
            jam++;
          } else if (this.listJamAwal[i].jam_id - this.listJamAwal[i - 1].jam_id > 1) {
            break;
          } else {
            this.listJamAkhir.push(this.listJamAwal[i]);
            jam++;
          }

        }
      }
    },
    async getRombelTersedia() {
      const headers = {
        Authorization: localStorage.getItem('token')
      };
      try {
        const response = await this.axios.post('/rombel/tersedia', {
          semester_id: this.semester.semester_id,
          hari_id: this.hari.hari_id,
          mapel_id: this.mapel.mapel_id,
          jam_awal: this.jamAwal,
          jam_akhir: this.jamAkhir,
        }, {
          headers: headers
        });
        if (response.data) {
          const dataRombel = response.data.rombel;
          for (const r of dataRombel) {
            r.nama_sisa_jam = r.nama + ` (sisa ${r.sisa_jam} jam)`
          }
          this.listRombel = dataRombel;
        }
      }
      catch (err) {
        if (err.response) {
          if (err.response.status == 401) {
            this.$swal({
              title: 'Gagal',
              text: 'Sesi berakhir. Silahkan login ulang.',
              icon: 'error',
              confirmButtonText: 'Baik'
            }).then(() => {
              this.$router.go();
            });
          } else {
            console.log(err);
          }
        } else {
          console.log(err)
        }
      }
    },
    async getRuangDigunakan() {
      const headers = {
        Authorization: localStorage.getItem('token')
      };
      try {
        const response = await this.axios.post('/ruang/digunakan', {
          semester_id: this.semester.semester_id,
          hari_id: this.hari.hari_id,
          rombel_id: this.rombel.rombel_id,
        }, {
          headers: headers
        });
        if (response.data) {
          if (response.data.ruang.length) {
            this.ruang = this.ruangDigunakan = response.data.ruang[0];
            this.ruang.lokasi_ruang = this.ruangDigunakan.lokasi_ruang = response.data.ruang[0].kode_lokasi + ' - ' + response.data.ruang[0].nama;
          } else {
            this.ruangDigunakan = "";
          }
        }
      }
      catch (err) {
        if (err.response) {
          if (err.response.status == 401) {
            this.$swal({
              title: 'Gagal',
              text: 'Sesi berakhir. Silahkan login ulang.',
              icon: 'error',
              confirmButtonText: 'Baik'
            }).then(() => {
              this.$router.go();
            });
          } else {
            console.log(err);
          }
        } else {
          console.log(err)
        }
      }
    },
    async getRuangTersedia() {
      const headers = {
        Authorization: localStorage.getItem('token')
      };
      try {
        const response = await this.axios.post('/ruang/tersedia', {
          semester_id: this.semester.semester_id,
          hari_id: this.hari.hari_id,
          jam_awal: this.jamAwal,
          jam_akhir: this.jamAkhir,
        }, {
          headers: headers
        });
        if (response.data) {
          const dataRuang = response.data.ruang;
          for (const r of dataRuang) {
            r.lokasi_ruang = r.kode_lokasi + ' - ' + r.nama
          }
          this.listRuang = dataRuang;
        }
      }
      catch (err) {
        if (err.response) {
          if (err.response.status == 401) {
            this.$swal({
              title: 'Gagal',
              text: 'Sesi berakhir. Silahkan login ulang.',
              icon: 'error',
              confirmButtonText: 'Baik'
            }).then(() => {
              this.$router.go();
            });
          } else {
            console.log(err);
          }
        } else {
          console.log(err)
        }
      }
    },
    async getJadwalGuru() {
      const headers = {
        Authorization: localStorage.getItem('token')
      };
      try {
        const response = await this.axios.post('/jadwal/guru', {
          semester_id: this.semester.semester_id,
          guru_id: this.guru.guru_id,
        }, {
          headers: headers
        });
        if (response.data) {
          this.jumlah = response.data.count;
          this.jadwalGuru = response.data.jadwal;
          for (const j of this.jadwalGuru) {
            if (j.waktu_mulai_khusus && j.waktu_selesai_khusus) {
              j.waktu_mulai = j.waktu_mulai_khusus.split(':').slice(0, 2).join(':');
              j.waktu_selesai = j.waktu_selesai_khusus.split(':').slice(0, 2).join(':');
            } else {
              j.waktu_mulai = j.waktu_mulai.split(':').slice(0, 2).join(':');
              j.waktu_selesai = j.waktu_selesai.split(':').slice(0, 2).join(':');
            }
          }
        }
      }
      catch (err) {
        if (err.response) {
          if (err.response.status == 401) {
            this.$swal({
              title: 'Gagal',
              text: 'Sesi berakhir. Silahkan login ulang.',
              icon: 'error',
              confirmButtonText: 'Baik'
            }).then(() => {
              this.$router.go();
            });
          } else {
            console.log(err);
          }
        } else {
          console.log(err)
        }
      }
    },
    async getMapelTersediaEdit() {
      const headers = {
        Authorization: localStorage.getItem('token')
      };
      try {
        const response = await this.axios.post('/mapel/tersedia', {
          tahun_ajaran_id: this.semester.tahun_ajaran_id,
          semester_id: this.semester.semester_id,
          guru_id: this.guru.guru_id,
          jam_awal: this.editData.jam_awal,
          jam_akhir: this.editData.jam_akhir,
        }, {
          headers: headers
        });
        if (response.data) {
          const dataMapel = response.data.mapel;
          for (const m of dataMapel) {
            m.nama_jam = `${m.nama} (${m.jumlah_jam} jam)`
          }
          this.listMapelEdit = dataMapel;
        }
      }
      catch (err) {
        if (err.response) {
          if (err.response.status == 401) {
            this.$swal({
              title: 'Gagal',
              text: 'Sesi berakhir. Silahkan login ulang.',
              icon: 'error',
              confirmButtonText: 'Baik'
            }).then(() => {
              this.$router.go();
            });
          } else {
            console.log(err);
          }
        } else {
          console.log(err)
        }
      }
    },
    async getRombelTersediaEdit() {
      const headers = {
        Authorization: localStorage.getItem('token')
      };
      try {
        const response = await this.axios.post('/rombel/tersedia', {
          semester_id: this.semester.semester_id,
          hari_id: this.editData.hari_id,
          mapel_id: this.mapelEdit ? this.mapelEdit.mapel_id : this.editData.mapel_id,
          rombel_id: this.editData.rombel_id,
          jam_awal: this.editData.jam_awal,
          jam_akhir: this.editData.jam_akhir,
        }, {
          headers: headers
        });
        if (response.data) {
          const dataRombel = response.data.rombel;
          for (const r of dataRombel) {
            r.nama_sisa_jam = r.nama + ` (sisa ${r.sisa_jam} jam)`
          }
          this.listRombelEdit = dataRombel;
        }
      }
      catch (err) {
        if (err.response) {
          if (err.response.status == 401) {
            this.$swal({
              title: 'Gagal',
              text: 'Sesi berakhir. Silahkan login ulang.',
              icon: 'error',
              confirmButtonText: 'Baik'
            }).then(() => {
              this.$router.go();
            });
          } else {
            console.log(err);
          }
        } else {
          console.log(err)
        }
      }
    },
    async getRuangTersediaEdit() {
      const headers = {
        Authorization: localStorage.getItem('token')
      };
      try {
        const response = await this.axios.post('/ruang/tersedia', {
          semester_id: this.semester.semester_id,
          hari_id: this.editData.hari_id,
          jam_awal: this.editData.jam_awal,
          jam_akhir: this.editData.jam_akhir,
        }, {
          headers: headers
        });
        if (response.data) {
          const dataRuang = response.data.ruang;
          for (const r of dataRuang) {
            r.lokasi_ruang = r.kode_lokasi + ' - ' + r.nama
          }
          this.listRuangEdit = dataRuang;
        }
      }
      catch (err) {
        if (err.response) {
          if (err.response.status == 401) {
            this.$swal({
              title: 'Gagal',
              text: 'Sesi berakhir. Silahkan login ulang.',
              icon: 'error',
              confirmButtonText: 'Baik'
            }).then(() => {
              this.$router.go();
            });
          } else {
            console.log(err);
          }
        } else {
          console.log(err)
        }
      }
    },
    async simpanJadwal() {
      let memuat = this.$loading.show({
        container: this.$refs.formCreate,
        loader: 'spinner'
      });
      const newData = {
        semester_id: this.semester.semester_id,
        hari_id: this.hari.hari_id,
        jam_awal: this.jamAwal,
        jam_akhir: this.jamAkhir,
        rombel_id: this.rombel.rombel_id,
        mapel_id: this.mapel.mapel_id,
        guru_id: this.guru.guru_id,
        ruang_id: this.ruang.ruang_id,
      }
      const headers = {
        Authorization: localStorage.getItem('token')
      };
      try {
        const response = await this.axios.post('/jadwal-pelajaran/tambah', newData, {
          headers: headers
        });
        if (response.data) {
          this.$swal({
            title: 'Sukses',
            text: response.data.message,
            icon: 'success',
            showConfirmButton: false,
            timer: 1500
          })
        }
      }
      catch (err) {
        if (err.response) {
          if (err.response.status == 401) {
            this.$swal({
              title: 'Gagal',
              text: 'Sesi berakhir. Silahkan login ulang.',
              icon: 'error',
              confirmButtonText: 'Baik'
            }).then(() => {
              this.$router.go();
            });
          } else if (err.response.status == 403) {
            this.$swal({
              title: 'Gagal',
              text: 'Anda tidak memiliki akses untuk menambahkan data.',
              icon: 'error',
              confirmButtonText: 'Baik'
            });
          } else {
            this.$swal({
              title: 'Gagal',
              html: `<p class="text-danger">${err.response.data.error}</p>
                    <code class="text-secondary text-bg-secondary bg-opacity-10">code: ${err.response.data.code}<br/>
                      message: ${err.response.data.message}</code>`,
              icon: 'error',
              confirmButtonText: 'Baik'
            });
          }
        } else {
          console.log(err)
        }
      }
      setTimeout(() => {
        memuat.hide();
      }, 250);
    },
    async perbaruiJadwal() {
      const newData = {
        semester_id: this.semester.semester_id,
        hari_id: this.editData.hari_id,
        jam_awal: this.editData.jam_awal,
        jam_akhir: this.editData.jam_akhir,
        old_rombel_id: this.editData.rombel_id,
        old_mapel_id: this.editData.mapel_id,
        old_guru_id: this.guru.guru_id,
        old_ruang_id: this.editData.ruang_id,
        new_rombel_id: this.rombelEdit ? this.rombelEdit.rombel_id : this.editData.rombel_id,
        new_mapel_id: this.mapelEdit ? this.mapelEdit.mapel_id : this.editData.mapel_id,
        new_guru_id: this.guru.guru_id,
        new_ruang_id: this.ruangEdit ? this.ruangEdit.ruang_id : this.editData.ruang_id,
      }
      if (this.mapelEdit || this.rombelEdit || this.ruangEdit) {
        const headers = {
          Authorization: localStorage.getItem('token')
        };
        try {
          const response = await this.axios.post('/jadwal-pelajaran/perbarui', newData, {
            headers: headers
          });
          if (response.data) {
            this.$swal({
              title: 'Sukses',
              text: response.data.message,
              icon: 'success',
              showConfirmButton: false,
              timer: 1500
            })
          }
        }
        catch (err) {
          if (err.response) {
            if (err.response.status == 401) {
              this.$swal({
                title: 'Gagal',
                text: 'Sesi berakhir. Silahkan login ulang.',
                icon: 'error',
                confirmButtonText: 'Baik'
              }).then(() => {
                this.$router.go();
              });
            } else if (err.response.status == 403) {
              this.$swal({
                title: 'Gagal',
                text: 'Anda tidak memiliki akses untuk memperbarui data.',
                icon: 'error',
                confirmButtonText: 'Baik'
              });
            } else {
              this.$swal({
                title: 'Gagal',
                html: `<p class="text-danger">${err.response.data.error}</p>
                    <code class="text-secondary text-bg-secondary bg-opacity-10">code: ${err.response.data.code}<br/>
                      message: ${err.response.data.message}</code>`,
                icon: 'error',
                confirmButtonText: 'Baik'
              });
            }
          } else {
            console.log(err)
          }
        }
      } else {
        this.$swal({
          title: 'Perhatian',
          text: 'Anda belum melakukan perubahan',
          icon: 'warning',
          confirmButtonText: 'Baik'
        });
      }
    },
    confirmDelete(d) {
      this.$swal({
        title: 'Konfirmasi',
        html: `<span class="h5">Anda yakin ingin menghapus Jadwal Mengajar <code class="text-black text-bg-secondary bg-opacity-50">${this.guru.nama}</code>
          hari <code class="text-black text-bg-secondary bg-opacity-50">${d.nama_hari}</code> jam ke <code class="text-black text-bg-secondary bg-opacity-50">${d.jam_awal}-${d.jam_akhir}</code>?</span><br/>
          <small class="fst-italic text-muted">Tindakan ini tidak dapat diurungkan</small>`,
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: 'Ya, Hapus',
        cancelButtonText: 'Batal',
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          this.hapusData(d);
        }
      });
    },
    async hapusData(d) {
      const headers = {
        Authorization: localStorage.getItem('token')
      };
      const dataToDelete = {
        semester_id: this.semester.semester_id,
        hari_id: d.hari_id,
        jam_awal: d.jam_awal,
        jam_akhir: d.jam_akhir,
        rombel_id: d.rombel_id,
        mapel_id: d.mapel_id,
        guru_id: this.guru.guru_id,
        ruang_id: d.ruang_id,
      }
      try {
        const response = await this.axios.post('/jadwal-pelajaran/hapus', dataToDelete, {
          headers: headers
        });
        if (response.data) {
          this.$swal({
            title: 'Sukses',
            text: response.data.message,
            icon: 'success',
            showConfirmButton: false,
            timer: 1500
          })
        } else {
          this.$swal({
            title: 'Gagal',
            text: 'Data tidak ditemukan.',
            icon: 'error',
            confirmButtonText: 'Baik'
          });
        }
      }
      catch (err) {
        if (err.response) {
          if (err.response.status == 401) {
            this.$swal({
              title: 'Gagal',
              text: 'Sesi berakhir. Silahkan login ulang.',
              icon: 'error',
              confirmButtonText: 'Baik'
            }).then(() => {
              this.$router.go();
            });
          } else if (err.response.status == 403) {
            this.$swal({
              title: 'Gagal',
              text: 'Anda tidak memiliki akses untuk menghapus data.',
              icon: 'error',
              confirmButtonText: 'Baik'
            });
          } else {
            this.$swal({
              title: 'Gagal',
              html: `<p class="text-danger">${err.response.data.error}</p>
                <code class="text-secondary text-bg-secondary bg-opacity-10">code: ${err.response.data.code}<br/>
                  message: ${err.response.data.message}</code>`,
              icon: 'error',
              confirmButtonText: 'Baik'
            });
          }
        } else {
          console.log(err)
        }
      }
    },
    confirmDeleteAll() {
      this.$swal({
        title: 'Konfirmasi',
        html: `<span class="h5">Anda yakin ingin menghapus seluruh Jadwal Mengajar/Piket <code class="text-black text-bg-secondary bg-opacity-50">${this.guru.nama}</code>?</span><br/>
          <small class="fst-italic text-muted">Tindakan ini tidak dapat diurungkan</small>`,
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: 'Ya, Hapus',
        cancelButtonText: 'Batal',
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          this.hapusDataAll();
        }
      });
    },
    async hapusDataAll() {
      const headers = {
        Authorization: localStorage.getItem('token')
      };
      let listHariId = [];
      for (const h of this.jadwalGuru) {
        listHariId.push(h.hari_id);
      }
      try {
        const response = await this.axios.post('/jadwal/hapus/guru', {
          semester_id: this.semester.semester_id,
          guru_id: this.guru.guru_id,
          listHariId: listHariId
        }, {
          headers: headers
        });
        if (response.data) {
          this.$swal({
            title: 'Sukses',
            text: response.data.message,
            icon: 'success',
            showConfirmButton: false,
            timer: 1500
          })
        } else {
          this.$swal({
            title: 'Gagal',
            text: 'Data tidak ditemukan.',
            icon: 'error',
            confirmButtonText: 'Baik'
          });
        }
      }
      catch (err) {
        if (err.response) {
          if (err.response.status == 401) {
            this.$swal({
              title: 'Gagal',
              text: 'Sesi berakhir. Silahkan login ulang.',
              icon: 'error',
              confirmButtonText: 'Baik'
            }).then(() => {
              this.$router.go();
            });
          } else if (err.response.status == 403) {
            this.$swal({
              title: 'Gagal',
              text: 'Anda tidak memiliki akses untuk menghapus data.',
              icon: 'error',
              confirmButtonText: 'Baik'
            });
          } else {
            this.$swal({
              title: 'Gagal',
              html: `<p class="text-danger">${err.response.data.error}</p>
                <code class="text-secondary text-bg-secondary bg-opacity-10">code: ${err.response.data.code}<br/>
                  message: ${err.response.data.message}</code>`,
              icon: 'error',
              confirmButtonText: 'Baik'
            });
          }
        } else {
          console.log(err)
        }
      }
    },
  },
}
</script>

<style scoped></style>