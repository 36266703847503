<template>
  <nav-bar v-if="user.role === 'admin'" :user="user" style="z-index: 4;" />
  <main class="container mt-2 mb-4 shadow-lg p-3 small">
    <router-view />
  </main>
  <footer class="fixed-bottom d-flex justify-content-end px-2 text-bg-secondary bg-opacity-75 shadow small">
    <span class="small">&copy; 2023</span>
  </footer>
</template>

<script>
import NavBar from './components/NavBar.vue';
import WebSocketService from '@/components/WebSocketService';
export default {
  name: 'App',
  data() {
    return {
    };
  },
  components: { NavBar },
  computed: {
    user() {
      return this.$store.state.userData;
    },
  },
  created() {
    WebSocketService.connect("/penjadwalan");
    WebSocketService.on("update-semester", () => {
      this.getMainData();
    })
    if (localStorage.getItem('token')) {
      this.callback();
      this.getMainData();
    } else {
      window.open(this.$sso + '/' + this.$appId, '_parent');
    }
  },
  methods: {
    async callback() {
      let memuat = this.$loading.show({
        loader: 'spinner'
      });
      const headers = {
        Authorization: localStorage.getItem('token')
      };
      try {
        const response = await this.axios.post(this.$auth + '/info', {}, {
          headers: headers
        });
        if (response.data.user) {
          if (response.data.user.role === 'admin' &&
            (response.data.user.ket === 'it' || response.data.user.ket === 'kurikulum' || response.data.user.ket === 'jadwal')) {
            this.$store.dispatch('updateUser', response.data.user);
          } else {
            this.$router.push('/forbidden');
          }
        }
      }
      catch (err) {
        localStorage.removeItem('token');
        localStorage.removeItem('user');
        window.open(this.$sso + '/' + this.$appId, '_parent');
      }
      setTimeout(() => {
        memuat.hide();
      }, 250);
    },
    async getMainData() {
      const headers = {
        Authorization: localStorage.getItem('token')
      };
      try {
        const response = await this.axios.post('/penjadwalan/data', {
          status: 1
        }, {
          headers: headers
        });
        if (response.data) {
          this.$store.dispatch('updateDataPenjadwalan', response.data);
        }
      }
      catch (err) {
        console.log(err)
      }
    }
  },
}
</script>

<style>
main,
nav {
  font-family: 'Trebuchet MS', sans-serif;
}

#cari {
  background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="16" fill="gray" class="bi bi-search" viewBox="0 0 16 16"><path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"/%3E%3C/svg%3E');
  background-repeat: no-repeat;
  background-position: 7px;
  background-position-y: center;
  text-indent: 21px;
  background-size: 16px;
}

::-webkit-input-placeholder {
  font-style: italic;
}

:-moz-placeholder {
  font-style: italic;
}

::-moz-placeholder {
  font-style: italic;
}

:-ms-input-placeholder {
  font-style: italic;
}

.v-select {
  --vs-line-height: 1.36;
  --vs-border-radius: .25rem;
  --vs-controls-size: .75;
  --vs-border-style: none;
  --vs-border-width: 1px;
  --vs-border-style: solid;
  --vs-border-color: #dee2e6;
  --vs-search-input-placeholder-color: #595c5f;
  --vs-font-size: .875rem;
}

.reset-button {
  background: transparent;
  border: 1px solid #dee2e6;
  fill: rgba(60, 60, 60, 0.5);
  cursor: pointer;
}

.select-wrapper {
  position: relative;
  display: inline-block;
}
</style>