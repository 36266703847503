<template>
  <div class="row row-cols-auto justify-content-between mb-2 mx-auto gap-2">
    <div class="col-auto p-0 d-inline-flex">
      <h5 class="my-auto">Guru Kosong</h5>
    </div>
    <div class="col-auto p-0 ms-auto">
      <select class="form-select form-select-sm shadow-sm" v-model="semester">
        <option disabled value="">-- Pilih Semester --</option>
        <option v-for="s in listSemester" :key="s" :value="s">{{ s.nama }}</option>
      </select>
    </div>
  </div>
  <div class="col-12 col-md-10 col-lg-8 mb-2">
    <div class="row mb-1">
      <label class="col-4 col-md-3 col-xl-2 col-form-label col-form-label-sm">Hari</label>
      <div class="col-8 col-md-9 col-xl-10">
        <v-select v-model="hari" placeholder="Pilih Hari" label="nama" :options="listHari">
          <template #no-options>
            Hari tidak ditemukan.
          </template>
        </v-select>
      </div>
    </div>
    <div class="row mb-0">
      <label class="col-4 col-md-3 col-xl-2 col-form-label col-form-label-sm">Mata Pelajaran</label>
      <div class="col-8 col-md-9 col-xl-10">
        <v-select v-model="mapel" placeholder="Pilih Mata Pelajaran" label="tingkat_jurusan_nama" :options="listMapel">
          <template #no-options>
            Mata Pelajaran tidak ditemukan.
          </template>
        </v-select>
      </div>
    </div>
  </div>
  <div v-if="hari" class="table-responsive shadow">
    <table class="table table-sm table-striped table-hover mb-0">
      <thead class="table-secondary align-middle">
        <tr>
          <th>#</th>
          <th>Kode Guru</th>
          <th class="position-sticky start-0">Nama</th>
          <th>Jam Kosong</th>
        </tr>
      </thead>
      <tbody v-if="guruKosong.length">
        <tr v-for="g, index in guruKosong" :key="g">
          <td>{{ index+=1 + pageOffset }}</td>
          <td>{{ g.guru_id }}</td>
          <td class="position-sticky start-0">{{ g.nama }}</td>
          <td>{{ g.jam_kosong }}</td>
        </tr>
      </tbody>
      <tbody v-else>
        <tr>
          <td v-if="mapel" class="text-center fst-italic py-3" colspan="4">Tidak ada guru pengampu
            <strong>"{{ mapel.tingkat_jurusan_nama }}"</strong> yang kosong di hari <strong>"{{ hari.nama }}"</strong>.
          </td>
          <td v-else class="text-center fst-italic py-3" colspan="4">
            Tidak ada guru kosong di hari <strong>"{{ hari.nama }}"</strong>.
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <span v-if="guruKosong.length" class="small text-muted fst-italic">
    Menampilkan {{ pageOffset + 1 }}-{{ pageOffset + guruKosong.length }} dari total {{ count }} data
  </span>
  <div v-if="guruKosong.length" class="row row-cols-auto justify-content-sm-between justify-content-center mt-2">
    <div class="row row-cols-auto">
      <label class="col-auto col-form-label-sm pe-1" for="numRows">Tampilkan:</label>
      <div class="col-auto ps-1">
        <select class="form-select form-select-sm shadow" v-model="pageLimit" id="numRows">
          <option v-for="l in arrayLimit" :key="l" :value="l">
            {{ l }} baris
          </option>
        </select>
      </div>
    </div>
    <div class="col-auto">
      <ul class="pagination pagination-sm shadow mb-0">
        <li class="page-item">
          <button class="page-link" :class="{ disabled: pageCurrent == 1 }"
            @click="pageCurrent > 1 ? pageCurrent-- : ''">&laquo;</button>
        </li>
        <li class="page-item" v-for="p in  pageCount " :key="p">
          <button v-if="p == pageCurrent" class="page-link active fw-bold">{{ p }}</button>
          <button v-else-if="p == 1 || p == pageCount || Math.abs(p - pageCurrent) == 1" class="page-link"
            @click="pageCurrent = p">{{ p }}</button>
          <button v-else-if="(Math.abs(pageCurrent - 1) < 4 && Math.abs(p - 1) < 5) ||
            (Math.abs(pageCurrent - pageCount) < 4 && Math.abs(p - pageCount) < 5)" class="page-link"
            @click="pageCurrent = p">{{ p }}</button>
          <button
            v-else-if="(Math.abs(p - 1) == 5 || Math.abs(p - pageCount) == 5) && (Math.abs(p - pageCount) == 1 || Math.abs(p - 1) == 1)"
            class="page-link" @click="pageCurrent = p">{{ p }}</button>
          <button v-else-if="Math.abs(p - pageCurrent) == 2 ||
            (Math.abs(pageCurrent - 1) < 4 && Math.abs(p - 1) == 5) ||
            (Math.abs(pageCurrent - pageCount) < 4 && Math.abs(p - pageCount) == 5)" class="page-link px-1"
            @click="pageCurrent = p">...</button>
        </li>
        <li class="page-item">
          <button class="page-link" :class="{ disabled: pageCurrent == pageCount }"
            @click="pageCurrent < pageCount ? pageCurrent++ : ''">&raquo;</button>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import WebSocketService from './WebSocketService';
export default {
  name: 'GuruKosong',
  data() {
    return {
      dataListener: (d) => {
        if (d == 'hari' || d == 'statusHari') {
          this.getListHari();
        } else if (d == 'jam' || d == 'statusJam') {
          if (this.hari) {
            if (this.pageCurrent == 1) {
              this.getGuruKosong();
            } else {
              this.pageCurrent = 1;
            }
          }
        }
      },
      mapelDiampuListener: (d) => {
        const data = JSON.parse(d);
        if (data.semester_id == this.semester.semester_id) {
          if (this.hari && this.mapel) {
            const cekMapel = Array(data.listMapelId).findIndex((d) => d == this.mapel.mapel_id);
            if (cekMapel || data.mapel_id == this.mapel.mapel_id || data.old_mapel_id == this.mapel.mapel_id || data.new_mapel_id == this.mapel.mapel_id) {
              this.getGuruKosong();
            }
          }
        }
      },
      penjadwalanListener: (d) => {
        const data = JSON.parse(d);
        if (data.semester_id == this.semester.semester_id) {
          const cekHari = Array(data.listHariId).findIndex((d) => d == this.hari.hari_id);
          if (cekHari || data.hari_id == this.hari.hari_id) {
            this.getGuruKosong();
          }
        }
      },
      guruKosong: [],
      listSemester: [],
      listHari: [],
      listMapel: [],
      semester: "",
      hari: "",
      mapel: "",
      arrayLimit: [10, 25, 50, 100, 250],
      count: 0,
      pageCurrent: 1,
      pageOffset: 0,
      pageLimit: 10,
      pageCount: 0,
    }
  },
  mounted() {
    WebSocketService.on("inserted-or-deleted-data", this.dataListener);
    WebSocketService.on("update-data", this.dataListener);
    WebSocketService.on("inserted-or-deleted-mapel-diampu", this.mapelDiampuListener);
    WebSocketService.on("update-mapel-diampu", this.mapelDiampuListener);
    WebSocketService.on("inserted-or-deleted-jadwal", this.penjadwalanListener);
    WebSocketService.on("update-jadwal", this.penjadwalanListener);
    this.pageLimit = this.batasBaris;
    this.semester = {
      tahun_ajaran_id: this.tpAktif.tahun_ajaran_id,
      ...this.semesterAktif
    };
    this.getListSemester();
  },
  beforeUnmount() {
    WebSocketService.off("inserted-or-deleted-data", this.dataListener);
    WebSocketService.off("update-data", this.dataListener);
    WebSocketService.off("inserted-or-deleted-mapel-diampu", this.mapelDiampuListener);
    WebSocketService.off("update-mapel-diampu", this.mapelDiampuListener);
    WebSocketService.off("inserted-or-deleted-jadwal", this.penjadwalanListener);
    WebSocketService.off("update-jadwal", this.penjadwalanListener);
  },
  computed: {
    tpAktif() {
      return this.$store.getters.getTp
    },
    semesterAktif() {
      return this.$store.getters.getSemester
    },
    batasBaris() {
      return this.$store.getters.getDefaultLimit
    },
  },
  watch: {
    semesterAktif() {
      this.semester = {
        tahun_ajaran_id: this.tpAktif.tahun_ajaran_id,
        ...this.semesterAktif
      };
    },
    semester() {
      this.getListHari();
      this.getListMapel();
    },
    listHari() {
      const cekHari = this.listHari.findIndex((d) => d.hari_id == this.hari.hari_id);
      if (cekHari < 0) {
        this.hari = "";
      } else {
        if (this.pageCurrent == 1) {
          this.getGuruKosong();
        } else {
          this.pageCurrent = 1;
        }
      }
    },
    hari() {
      if (!this.hari) {
        this.guruKosong = [];
      } else {
        if (this.pageCurrent == 1) {
          this.getGuruKosong();
        } else {
          this.pageCurrent = 1;
        }
      }
    },
    listMapel() {
      const cekMapel = this.listMapel.findIndex((d) => d.mapel_id == this.mapel.mapel_id);
      if (cekMapel < 0) {
        this.mapel = "";
      } else {
        if (this.hari) {
          if (this.pageCurrent == 1) {
            this.getGuruKosong();
          } else {
            this.pageCurrent = 1;
          }
        }
      }
    },
    mapel() {
      if (this.hari) {
        if (this.pageCurrent == 1) {
          this.getGuruKosong();
        } else {
          this.pageCurrent = 1;
        }
      }
    },
    pageCurrent() {
      this.pageOffset = (this.pageCurrent - 1) * this.pageLimit;
      if (this.hari) {
        this.getGuruKosong();
      }
    },
    pageLimit() {
      if (this.pageCurrent == 1) {
        this.getGuruKosong();
      } else {
        this.pageCurrent = 1;
      }
    },
  },
  methods: {
    async getListSemester() {
      const headers = {
        Authorization: localStorage.getItem('token')
      };
      try {
        const response = await this.axios.post('/penjadwalan/semester/list', {}, {
          headers: headers
        });
        if (response.data) {
          this.listSemester = response.data.semester;
        }
      }
      catch (err) {
        if (err.response) {
          if (err.response.status == 401) {
            this.$swal({
              title: 'Gagal',
              text: 'Sesi berakhir. Silahkan login ulang.',
              icon: 'error',
              confirmButtonText: 'Baik'
            }).then(() => {
              this.$router.go();
            });
          } else {
            console.log(err);
          }
        } else {
          console.log(err)
        }
      }
    },
    async getListHari() {
      const headers = {
        Authorization: localStorage.getItem('token')
      };
      try {
        const response = await this.axios.post('/hari/aktif', {}, {
          headers: headers
        });
        if (response.data) {
          this.listHari = response.data.hari;
        }
      }
      catch (err) {
        if (err.response) {
          if (err.response.status == 401) {
            this.$swal({
              title: 'Gagal',
              text: 'Sesi berakhir. Silahkan login ulang.',
              icon: 'error',
              confirmButtonText: 'Baik'
            }).then(() => {
              this.$router.go();
            });
          } else {
            console.log(err);
          }
        } else {
          console.log(err)
        }
      }
    },
    async getListMapel() {
      const headers = {
        Authorization: localStorage.getItem('token')
      };
      try {
        const response = await this.axios.post('/mapel/list', {
          tahun_ajaran_id: this.semester.tahun_ajaran_id
        }, {
          headers: headers
        });
        if (response.data) {
          this.listMapel = response.data.mapel;
        }
      }
      catch (err) {
        if (err.response) {
          if (err.response.status == 401) {
            this.$swal({
              title: 'Gagal',
              text: 'Sesi berakhir. Silahkan login ulang.',
              icon: 'error',
              confirmButtonText: 'Baik'
            }).then(() => {
              this.$router.go();
            });
          } else {
            console.log(err);
          }
        } else {
          console.log(err)
        }
      }
    },
    async getGuruKosong() {
      const headers = {
        Authorization: localStorage.getItem('token')
      };
      try {
        const response = await this.axios.post('/guru/kosong', {
          semester_id: this.semester.semester_id,
          hari_id: this.hari.hari_id,
          mapel_id: this.mapel ? this.mapel.mapel_id : null,
          limit: this.pageLimit,
          offset: this.pageOffset,
        }, {
          headers: headers
        });
        if (response.data) {
          this.guruKosong = response.data.guru;
          this.count = response.data.count;
          this.pageCount = Math.ceil(this.count / this.pageLimit);
        }
      }
      catch (err) {
        if (err.response) {
          if (err.response.status == 401) {
            this.$swal({
              title: 'Gagal',
              text: 'Sesi berakhir. Silahkan login ulang.',
              icon: 'error',
              confirmButtonText: 'Baik'
            }).then(() => {
              this.$router.go();
            });
          } else {
            console.log(err);
          }
        } else {
          console.log(err)
        }
      }
    },
  },
}
</script>

<style></style>