<template>
  <div class="row row-cols-auto justify-content-between mb-2 mx-auto gap-2">
    <div class="col-auto p-0 d-inline-flex">
      <h5 class="my-auto">Riwayat Jadwal Piket</h5>
    </div>
    <div class="col-auto p-0 ms-auto">
      <select class="form-select form-select-sm shadow-sm" v-model="semester">
        <option disabled value="">-- Pilih Semester --</option>
        <option v-for="s in listSemester" :key="s" :value="s">{{ s.nama }}</option>
      </select>
    </div>
  </div>
  <div class="col-12 col-md-10 col-lg-8 mb-2">
    <div class="row mb-0">
      <label class="col-5 col-md-4 col-xl-3 col-form-label col-form-label-sm">Guru</label>
      <div class="col-7 col-md-8 col-xl-9">
        <v-select v-model="guru" placeholder="Pilih Guru" label="nama" :options="listGuru">
          <template #no-options>
            Guru tidak ditemukan.
          </template>
        </v-select>
      </div>
    </div>
  </div>
  <div class="table-responsive shadow">
    <table class="table table-sm table-striped table-hover mb-0">
      <thead class="table-secondary align-middle">
        <tr>
          <th rowspan="2">#</th>
          <th class="position-sticky start-0" rowspan="2">Hari</th>
          <th rowspan="2">Rentang Jam</th>
          <th rowspan="2">Jenis Piket</th>
          <th rowspan="2">Guru</th>
          <th rowspan="2">Lokasi</th>
          <th rowspan="2">Keterangan</th>
          <th colspan="3">Tindakan</th>
        </tr>
        <tr>
          <th class="small">Jenis</th>
          <th class="small">Oleh</th>
          <th class="small">Waktu</th>
        </tr>
      </thead>
      <tbody v-if="dataRiwayat.length">
        <template v-for="d, index in  dataRiwayat " :key="d">
          <tr>
            <td>{{ index+=1 + pageOffset }}</td>
            <td
              :class="{ 'text-decoration-line-through': d.riwayatEdit && d.tampilkan && d.nama_hari != d.riwayatEdit.nama_hari }"
              class="position-sticky start-0">
              {{ d.nama_hari }}</td>
            <td
              :class="{ 'text-decoration-line-through': d.riwayatEdit && d.tampilkan && (d.jam_awal != d.riwayatEdit.jam_awal || d.jam_akhir != d.riwayatEdit.jam_akhir) }">
              {{ d.jam_awal }} - {{ d.jam_akhir }}</td>
            <td
              :class="{ 'text-decoration-line-through': d.riwayatEdit && d.tampilkan && d.kode_piket != d.riwayatEdit.kode_piket }">
              {{ d.kode_piket }}</td>
            <td
              :class="{ 'text-decoration-line-through': d.riwayatEdit && d.tampilkan && d.nama_guru != d.riwayatEdit.nama_guru }">
              {{ d.nama_guru }}</td>
            <td
              :class="{ 'text-decoration-line-through': d.riwayatEdit && d.tampilkan && d.kode_lokasi != d.riwayatEdit.kode_lokasi }">
              {{ d.kode_lokasi }}</td>
            <td
              :class="{ 'text-decoration-line-through': d.riwayatEdit && d.tampilkan && d.keterangan != d.riwayatEdit.keterangan }">
              {{ d.keterangan }}</td>
            <td v-if="d.action_type == 'update'" class="py-0">
              <div class="hstack gap-1 justify-content-between">
                {{ d.action_type }}
                <button class="btn btn-sm btn-outline-secondary border-0" @click="getRiwayatJadwalEdit(d)">
                  <svg xmlns="http://www.w3.org/2000/svg" height="16" fill="currentColor" class="bi bi-info-circle"
                    viewBox="0 0 16 16">
                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                    <path
                      d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
                  </svg>
                </button>
              </div>
            </td>
            <td v-else>{{ d.action_type }}</td>
            <td>{{ d.username }}</td>
            <td>{{ d.actioned_at }}</td>
          </tr>
          <tr v-if="d.riwayatEdit && d.tampilkan" class="fst-italic">
            <td>>></td>
            <td>{{ d.riwayatEdit.nama_hari }}</td>
            <td>{{ d.riwayatEdit.jam_awal }} - {{ d.riwayatEdit.jam_akhir }}</td>
            <td>{{ d.riwayatEdit.kode_piket }}</td>
            <td>{{ d.riwayatEdit.nama_guru }}</td>
            <td>{{ d.riwayatEdit.kode_lokasi }}</td>
            <td>{{ d.riwayatEdit.keterangan }}</td>
            <td colspan="3">Data baru</td>
          </tr>
        </template>
      </tbody>
      <tbody v-else>
        <tr>
          <td class="text-center fst-italic py-3" colspan="10">
            Data tidak ditemukan.
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <span v-if="dataRiwayat.length" class="small text-muted fst-italic">
    Menampilkan {{ pageOffset + 1 }}-{{ pageOffset + dataRiwayat.length }} dari total {{ count }} data
  </span>
  <div v-if="dataRiwayat.length" class="row row-cols-auto justify-content-sm-between justify-content-center mt-2">
    <div class="row row-cols-auto">
      <label class="col-auto col-form-label-sm pe-1" for="numRows">Tampilkan:</label>
      <div class="col-auto ps-1">
        <select class="form-select form-select-sm shadow" v-model="pageLimit" id="numRows">
          <option v-for=" l  in  arrayLimit " :key="l" :value="l">
            {{ l }} baris
          </option>
        </select>
      </div>
    </div>
    <div class="col-auto">
      <ul class="pagination pagination-sm shadow mb-0">
        <li class="page-item">
          <button class="page-link" :class="{ disabled: pageCurrent == 1 }"
            @click="pageCurrent > 1 ? pageCurrent-- : ''">&laquo;</button>
        </li>
        <li class="page-item" v-for=" p  in   pageCount  " :key="p">
          <button v-if="p == pageCurrent" class="page-link active fw-bold">{{ p }}</button>
          <button v-else-if="p == 1 || p == pageCount || Math.abs(p - pageCurrent) == 1" class="page-link"
            @click="pageCurrent = p">{{ p }}</button>
          <button v-else-if="(Math.abs(pageCurrent - 1) < 4 && Math.abs(p - 1) < 5) ||
            (Math.abs(pageCurrent - pageCount) < 4 && Math.abs(p - pageCount) < 5)
            " class="page-link" @click="pageCurrent = p">{{ p }}</button>
          <button
            v-else-if="(Math.abs(p - 1) == 5 || Math.abs(p - pageCount) == 5) && (Math.abs(p - pageCount) == 1 || Math.abs(p - 1) == 1)"
            class="page-link" @click="pageCurrent = p">{{ p }}</button>
          <button v-else-if="Math.abs(p - pageCurrent) == 2 ||
            (Math.abs(pageCurrent - 1) < 4 && Math.abs(p - 1) == 5) ||
            (Math.abs(pageCurrent - pageCount) < 4 && Math.abs(p - pageCount) == 5)
            " class="page-link px-1" @click="pageCurrent = p">...</button>
        </li>
        <li class="page-item">
          <button class="page-link" :class="{ disabled: pageCurrent == pageCount }"
            @click="pageCurrent < pageCount ? pageCurrent++ : ''">&raquo;</button>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import WebSocketService from './WebSocketService';
export default {
  name: 'RiwayatJadwalPelajaran',
  data() {
    return {
      penjadwalanListener: (d) => {
        const data = JSON.parse(d);
        if (data.semester_id == this.semester.semester_id) {
          this.getRiwayatJadwal();
        }
      },
      dataRiwayat: [],
      listSemester: [],
      listRombel: [],
      listGuru: [],
      semester: "",
      rombel: "",
      guru: "",
      arrayLimit: [10, 25, 50, 100, 250],
      count: 0,
      pageCurrent: 1,
      pageOffset: 0,
      pageLimit: 10,
      pageCount: 0,
    }
  },
  mounted() {
    WebSocketService.on("inserted-or-deleted-jadwal", this.penjadwalanListener);
    WebSocketService.on("update-jadwal", this.penjadwalanListener);
    this.pageLimit = this.batasBaris;
    this.semester = {
      tahun_ajaran_id: this.tpAktif.tahun_ajaran_id,
      ...this.semesterAktif
    };
    this.getListSemester();
  },
  beforeUnmount() {
    WebSocketService.off("inserted-or-deleted-jadwal", this.penjadwalanListener);
    WebSocketService.off("update-jadwal", this.penjadwalanListener);
  },
  computed: {
    tpAktif() {
      return this.$store.getters.getTp
    },
    semesterAktif() {
      return this.$store.getters.getSemester
    },
    batasBaris() {
      return this.$store.getters.getDefaultLimit
    },
  },
  watch: {
    semesterAktif() {
      this.semester = {
        tahun_ajaran_id: this.tpAktif.tahun_ajaran_id,
        ...this.semesterAktif
      };
    },
    semester() {
      this.getListGuru();
      this.getRiwayatJadwal();
    },
    guru() {
      if (this.pageCurrent == 1) {
        this.getRiwayatJadwal();
      } else {
        this.pageCurrent = 1;
      }
    },
    pageCurrent() {
      this.pageOffset = (this.pageCurrent - 1) * this.pageLimit;
      this.getRiwayatJadwal();
    },
    pageLimit() {
      if (this.pageCurrent == 1) {
        this.getRiwayatJadwal();
      } else {
        this.pageCurrent = 1;
      }
    },
  },
  methods: {
    async getListSemester() {
      const headers = {
        Authorization: localStorage.getItem('token')
      };
      try {
        const response = await this.axios.post('/penjadwalan/semester/list', {}, {
          headers: headers
        });
        if (response.data) {
          this.listSemester = response.data.semester;
        }
      }
      catch (err) {
        if (err.response) {
          if (err.response.status == 401) {
            this.$swal({
              title: 'Gagal',
              text: 'Sesi berakhir. Silahkan login ulang.',
              icon: 'error',
              confirmButtonText: 'Baik'
            }).then(() => {
              this.$router.go();
            });
          } else {
            console.log(err);
          }
        } else {
          console.log(err)
        }
      }
    },
    async getListGuru() {
      const headers = {
        Authorization: localStorage.getItem('token')
      };
      try {
        const response = await this.axios.post('/guru/aktif', {}, {
          headers: headers
        });
        if (response.data) {
          this.listGuru = response.data.guru;
        }
      }
      catch (err) {
        if (err.response) {
          if (err.response.status == 401) {
            this.$swal({
              title: 'Gagal',
              text: 'Sesi berakhir. Silahkan login ulang.',
              icon: 'error',
              confirmButtonText: 'Baik'
            }).then(() => {
              this.$router.go();
            });
          } else {
            console.log(err);
          }
        } else {
          console.log(err)
        }
      }
    },
    async getRiwayatJadwal() {
      const headers = {
        Authorization: localStorage.getItem('token')
      };
      try {
        const response = await this.axios.post('/jadwal/riwayat/piket', {
          semester_id: this.semester.semester_id,
          guru_id: Object(this.guru).guru_id,
          limit: this.pageLimit,
          offset: this.pageOffset,
        }, {
          headers: headers
        });
        if (response.data) {
          this.dataRiwayat = response.data.riwayat;
          this.count = response.data.count;
          this.pageCount = Math.ceil(this.count / this.pageLimit);
          for (const r of this.dataRiwayat) {
            r.actioned_at = new Date(r.actioned_at).toLocaleString('id-ID');
          }
        }
      }
      catch (err) {
        if (err.response) {
          if (err.response.status == 401) {
            this.$swal({
              title: 'Gagal',
              text: 'Sesi berakhir. Silahkan login ulang.',
              icon: 'error',
              confirmButtonText: 'Baik'
            }).then(() => {
              this.$router.go();
            });
          } else {
            console.log(err);
          }
        } else {
          console.log(err)
        }
      }
      setTimeout(() => {
        this.memuat = false;
      }, 250)
    },
    async getRiwayatJadwalEdit(d) {
      if (!d.riwayatEdit) {
        d.tampilkan = 1;
        const headers = {
          Authorization: localStorage.getItem('token')
        };
        try {
          const response = await this.axios.post('/jadwal/riwayat/piket', {
            semester_id: this.semester.semester_id,
            riwayat_jadwal_piket_id: d.riwayat_jadwal_piket_id,
          }, {
            headers: headers
          });
          if (response.data) {
            d.riwayatEdit = response.data.riwayatEdit;
            d.riwayatEdit.actioned_at = new Date(d.riwayatEdit.actioned_at).toLocaleString('id-ID');
          }
        }
        catch (err) {
          if (err.response) {
            if (err.response.status == 401) {
              this.$swal({
                title: 'Gagal',
                text: 'Sesi berakhir. Silahkan login ulang.',
                icon: 'error',
                confirmButtonText: 'Baik'
              }).then(() => {
                this.$router.go();
              });
            } else {
              console.log(err);
            }
          } else {
            console.log(err)
          }
        }
      } else {
        d.tampilkan = !d.tampilkan;
      }
    },
  },
}
</script>

<style></style>