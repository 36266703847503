<template>
  <div class="row row-cols-auto justify-content-between mb-2 mx-auto gap-2">
    <div class="col-auto p-0 d-inline-flex">
      <h5 class="my-auto">Jenis Hari Non KBM</h5>
    </div>
    <div class="col-auto p-0">
      <button type="button" class="btn btn-sm btn-secondary hstack gap-1 shadow-sm" data-bs-toggle="modal"
        data-bs-target="#tambahData">
        <svg xmlns="http://www.w3.org/2000/svg" height="16" fill="currentColor" class="bi bi-plus-lg" viewBox="0 0 16 16">
          <path fill-rule="evenodd"
            d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2Z" />
        </svg>Tambahkan Hari Non KBM
      </button>
    </div>
  </div>
  <div v-if="tercentang.length" class="mb-2 text-end">
    <div class="hstack gap-1 justify-content-end">
      <button class="btn btn-sm btn-info shadow-sm" @click="confirmUpdateStatusSelected(1)">Aktifkan data
        terpilih</button>
      <div class="vr"></div>
      <button class="btn btn-sm btn-warning shadow-sm" @click="confirmUpdateStatusSelected(0)">Nonaktifkan data
        terpilih</button>
      <div class="vr"></div>
      <button class="btn btn-sm btn-danger shadow-sm" @click="confirmDeleteSelected">Hapus data terpilih</button>
    </div>
  </div>
  <div class="table-responsive shadow vl-parent" ref="table">
    <table class="table table-sm table-striped table-hover mb-0">
      <thead class="table-secondary align-middle">
        <tr>
          <th>#</th>
          <th class="position-sticky start-0">Kode</th>
          <th>Nama</th>
          <th>Aktif</th>
          <th>
            <div class="d-flex justify-content-between">
              Tindakan
              <input class="form-check-input" type="checkbox" v-model="semuaTercentang" @click="centangSemua">
            </div>
          </th>
        </tr>
      </thead>
      <tbody v-if="dataLibur.length">
        <tr v-for="d, index in dataLibur" :key="d">
          <td>{{ index+=1 + pageOffset }}</td>
          <td class="position-sticky start-0">{{ d.kode }}</td>
          <td>{{ d.nama }}</td>
          <td>
            <div class="form-check form-switch">
              <input class="form-check-input" type="checkbox" role="switch" :checked="d.status"
                @change="aktifkanStatus(d)">
            </div>
          </td>
          <td>
            <div class="d-flex justify-content-between">
              <div class="hstack gap-1 me-1">
                <button class="btn btn-sm btn-outline-primary d-flex" @click="editData(d)" data-bs-toggle="modal"
                  data-bs-target="#editData">
                  <svg xmlns="http://www.w3.org/2000/svg" width="13" fill="currentColor" class="bi bi-pencil-square"
                    viewBox="0 0 16 16">
                    <path
                      d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                    <path fill-rule="evenodd"
                      d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z" />
                  </svg>
                </button>
                <div class="vr"></div>
                <button class="btn btn-sm btn-outline-danger d-flex" @click="confirmDelete(d)">
                  <svg xmlns="http://www.w3.org/2000/svg" width="13" fill="currentColor" class="bi bi-trash-fill"
                    viewBox="0 0 16 16">
                    <path
                      d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1H2.5zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5zM8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5zm3 .5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 1 0z" />
                  </svg>
                </button>
              </div>
              <input class="form-check-input" type="checkbox" v-model="d.centang" @click="centangSatu(d)">
            </div>
          </td>
        </tr>
      </tbody>
      <tbody v-else>
        <tr>
          <td class="text-center fst-italic py-3" colspan="5">Data tidak ditemukan.</td>
        </tr>
      </tbody>
    </table>
  </div>
  <span v-if="dataLibur.length" class="small text-muted fst-italic">
    Menampilkan {{ pageOffset + 1 }}-{{ pageOffset + dataLibur.length }} dari total {{ count }} data
  </span>
  <div v-if="dataLibur.length" class="row row-cols-auto justify-content-sm-between justify-content-center mt-2">
    <div class="row row-cols-auto">
      <label class="col-auto col-form-label-sm pe-1" for="numRows">Tampilkan:</label>
      <div class="col-auto ps-1">
        <select class="form-select form-select-sm shadow" v-model="pageLimit" id="numRows">
          <option v-for="l in arrayLimit" :key="l" :value="l">
            {{ l }} baris
          </option>
        </select>
      </div>
    </div>
    <div class="col-auto">
      <ul class="pagination pagination-sm shadow mb-0">
        <li class="page-item">
          <button class="page-link" :class="{ disabled: pageCurrent == 1 }"
            @click="pageCurrent > 1 ? pageCurrent-- : ''">&laquo;</button>
        </li>
        <li class="page-item" v-for="p in  pageCount " :key="p">
          <button v-if="p == pageCurrent" class="page-link active fw-bold">{{ p }}</button>
          <button v-else-if="p == 1 || p == pageCount || Math.abs(p - pageCurrent) == 1" class="page-link"
            @click="pageCurrent = p">{{ p }}</button>
          <button v-else-if="(Math.abs(pageCurrent - 1) < 4 && Math.abs(p - 1) < 5) ||
            (Math.abs(pageCurrent - pageCount) < 4 && Math.abs(p - pageCount) < 5)" class="page-link"
            @click="pageCurrent = p">{{ p }}</button>
          <button
            v-else-if="(Math.abs(p - 1) == 5 || Math.abs(p - pageCount) == 5) && (Math.abs(p - pageCount) == 1 || Math.abs(p - 1) == 1)"
            class="page-link" @click="pageCurrent = p">{{ p }}</button>
          <button v-else-if="Math.abs(p - pageCurrent) == 2 ||
            (Math.abs(pageCurrent - 1) < 4 && Math.abs(p - 1) == 5) ||
            (Math.abs(pageCurrent - pageCount) < 4 && Math.abs(p - pageCount) == 5)" class="page-link px-1"
            @click="pageCurrent = p">...</button>
        </li>
        <li class="page-item">
          <button class="page-link" :class="{ disabled: pageCurrent == pageCount }"
            @click="pageCurrent < pageCount ? pageCurrent++ : ''">&raquo;</button>
        </li>
      </ul>
    </div>
  </div>
  <div class="modal fade" id="tambahData" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content vl-parent" ref="formCreate">
        <form @submit.prevent="simpanData">
          <div class="modal-header">
            <legend class="modal-title">Tambah Data Hari Non KBM</legend>
            <button type="button" id="tutupFormCreate" class="btn-close" data-bs-dismiss="modal"></button>
          </div>
          <div class="modal-body">
            <div class="row mb-3">
              <label class="col-sm-3 col-form-label col-form-label-sm">Kode</label>
              <div class="col-sm-9">
                <input type="text" class="form-control form-control-sm" placeholder="Kode" v-model="formCreate.kode"
                  required>
              </div>
            </div>
            <div class="row mb-3">
              <label class="col-sm-3 col-form-label col-form-label-sm">Nama</label>
              <div class="col-sm-9">
                <input type="text" class="form-control form-control-sm" placeholder="Nama" v-model="formCreate.nama"
                  required>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="submit" class="btn btn-primary">Simpan</button>
          </div>
        </form>
      </div>
    </div>
  </div>
  <div class="modal fade" id="editData" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content vl-parent" ref="formEdit">
        <form @submit.prevent="updateData">
          <div class="modal-header">
            <legend class="modal-title">Edit Data Hari Non KBM</legend>
            <button type="button" id="tutupFormEdit" class="btn-close" data-bs-dismiss="modal"></button>
          </div>
          <div class="modal-body">
            <div class="row mb-3">
              <label class="col-sm-3 col-form-label col-form-label-sm">Kode</label>
              <div class="col-sm-9">
                <input type="text" class="form-control form-control-sm" placeholder="Kode" v-model="formEdit.kode"
                  required>
              </div>
            </div>
            <div class="row mb-3">
              <label class="col-sm-3 col-form-label col-form-label-sm">Nama</label>
              <div class="col-sm-9">
                <input type="text" class="form-control form-control-sm" placeholder="Nama" v-model="formEdit.nama"
                  required>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="submit" class="btn btn-primary">Perbarui</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import WebSocketService from './WebSocketService';
export default {
  name: 'DataLibur',
  data() {
    return {
      insertOrDeleteListener: (d) => {
        if (d == 'libur') {
          if (this.pageCurrent == 1) {
            this.getDataLibur();
          } else {
            this.pageCurrent = 1;
          }
        }
      },
      updateListener: (d) => {
        if (d == 'libur' || d == 'statusLibur') {
          this.getDataLibur();
        }
      },
      dataLibur: [],
      arrayLimit: [10, 25, 50, 100, 250],
      count: 0,
      pageCurrent: 1,
      pageOffset: 0,
      pageLimit: 10,
      pageCount: 0,
      formCreate: {
        kode: null,
        nama: null,
      },
      selectedData: {},
      formEdit: {},
      tercentang: [],
      semuaTercentang: false,
    }
  },
  mounted() {
    WebSocketService.on("inserted-or-deleted-data", this.insertOrDeleteListener);
    WebSocketService.on("update-data", this.updateListener);
    this.pageLimit = this.batasBaris;
    this.getDataLibur();
  },
  beforeUnmount() {
    WebSocketService.off('inserted-or-deleted-data', this.insertOrDeleteListener);
    WebSocketService.off("update-data", this.updateListener);
  },
  computed: {
    batasBaris() {
      return this.$store.getters.getDefaultLimit
    },
  },
  watch: {
    pageCurrent() {
      this.pageOffset = (this.pageCurrent - 1) * this.pageLimit;
      this.getDataLibur();
    },
    pageLimit() {
      if (this.pageCurrent == 1) {
        this.getDataLibur();
      } else {
        this.pageCurrent = 1;
      }
    },
  },
  methods: {
    async getDataLibur() {
      let memuat = this.$loading.show({
        container: this.$refs.table,
        loader: 'dots'
      });
      const headers = {
        Authorization: localStorage.getItem('token')
      };
      try {
        const response = await this.axios.post('/libur/list', {
          limit: this.pageLimit,
          offset: this.pageOffset
        }, {
          headers: headers
        });
        if (response.data) {
          this.dataLibur = response.data.libur;
          this.count = response.data.count;
          this.pageCount = Math.ceil(this.count / this.pageLimit);
          this.tercentang = [];
          this.semuaTercentang = false;
        }
      }
      catch (err) {
        if (err.response) {
          if (err.response.status == 401) {
            this.$swal({
              title: 'Gagal',
              text: 'Sesi berakhir. Silahkan login ulang.',
              icon: 'error',
              confirmButtonText: 'Baik'
            }).then(() => {
              this.$router.go();
            });
          } else {
            console.log(err);
          }
        } else {
          console.log(err)
        }
      }
      setTimeout(() => {
        memuat.hide();
      }, 250);
    },
    async simpanData() {
      let memuat = this.$loading.show({
        container: this.$refs.formCreate,
        loader: 'spinner'
      });
      const headers = {
        Authorization: localStorage.getItem('token')
      };
      try {
        const response = await this.axios.post('/libur/tambah', this.formCreate, {
          headers: headers
        });
        if (response.data) {
          document.getElementById('tutupFormCreate').click();
          this.$swal({
            title: 'Sukses',
            text: response.data.message,
            icon: 'success',
            showConfirmButton: false,
            timer: 1500
          }).then(() => {
            this.formCreate = {
              kode: null,
              nama: null,
            }
          });
        }
      }
      catch (err) {
        if (err.response) {
          if (err.response.status == 401) {
            this.$swal({
              title: 'Gagal',
              text: 'Sesi berakhir. Silahkan login ulang.',
              icon: 'error',
              confirmButtonText: 'Baik'
            }).then(() => {
              this.$router.go();
            });
          } else if (err.response.status == 403) {
            this.$swal({
              title: 'Gagal',
              text: 'Anda tidak memiliki akses untuk menambahkan data.',
              icon: 'error',
              confirmButtonText: 'Baik'
            });
          } else {
            this.$swal({
              title: 'Gagal',
              html: `<p class="text-danger">${err.response.data.error}</p>
                <code class="text-secondary text-bg-secondary bg-opacity-10">code: ${err.response.data.code}<br/>
                  message: ${err.response.data.message}</code>`,
              icon: 'error',
              confirmButtonText: 'Baik'
            });
          }
        } else {
          console.log(err)
        }
      }
      setTimeout(() => {
        memuat.hide();
      }, 250);
    },
    editData(d) {
      this.selectedData = { ...d };
      this.formEdit = { ...this.selectedData };
    },
    async updateData() {
      let memuat = this.$loading.show({
        container: this.$refs.formEdit,
        loader: 'spinner'
      });
      if (JSON.stringify(this.formEdit) == JSON.stringify(this.selectedData)) {
        this.$swal({
          title: 'Perhatian',
          text: 'Anda belum melakukan perubahan',
          icon: 'warning',
          confirmButtonText: 'Baik'
        });
      } else {
        const headers = {
          Authorization: localStorage.getItem('token')
        };
        try {
          const response = await this.axios.post('/libur/perbarui', this.formEdit, { headers: headers });
          if (response.data) {
            document.getElementById('tutupFormEdit').click();
            this.$swal({
              title: 'Sukses',
              text: 'Berhasil memperbarui data Hari Non KBM',
              icon: 'success',
              showConfirmButton: false,
              timer: 1500
            });
          } else {
            this.$swal({
              title: 'Gagal',
              text: 'Data tidak ditemukan',
              icon: 'error',
              confirmButtonText: 'Baik'
            });
          }
        }
        catch (err) {
          if (err.response) {
            if (err.response.status == 401) {
              this.$swal({
                title: 'Gagal',
                text: 'Sesi berakhir. Silahkan login ulang.',
                icon: 'error',
                confirmButtonText: 'Baik'
              }).then(() => {
                this.$router.go();
              });
            } else if (err.response.status == 403) {
              this.$swal({
                title: 'Gagal',
                text: 'Anda tidak memiliki akses untuk memperbarui data.',
                icon: 'error',
                confirmButtonText: 'Baik'
              });
            } else {
              this.$swal({
                title: 'Gagal',
                html: `<p class="text-danger">${err.response.data.error}</p>
                <code class="text-secondary text-bg-secondary bg-opacity-10">code: ${err.response.data.code}<br/>
                  message: ${err.response.data.message}</code>`,
                icon: 'error',
                confirmButtonText: 'Baik'
              });
            }
          } else {
            console.log(err)
          }
        }
      }
      setTimeout(() => {
        memuat.hide();
      }, 250);
    },
    aktifkanStatus(d) {
      const a = d.status;
      d.status = d.status == 1 ? 0 : 1;
      if (a) {
        this.$swal({
          title: 'Konfirmasi',
          html: `<span class="h5">Anda yakin ingin menonaktifkan Hari Non KBM <code class="text-black text-bg-secondary bg-opacity-50">${d.nama}</code>?</span>`,
          icon: 'question',
          showCancelButton: true,
          confirmButtonText: 'Ya',
          cancelButtonText: 'Batal',
          reverseButtons: true,
        }).then((result) => {
          if (result.isConfirmed) {
            this.updateStatus(d);
          } else {
            d.status = 1;
          }
        });
      } else {
        this.$swal({
          title: 'Konfirmasi',
          html: `<span class="h5">Anda yakin ingin mengaktifkan Hari Non KBM <code class="text-black text-bg-secondary bg-opacity-50">${d.nama}</code>?</span>`,
          icon: 'question',
          showCancelButton: true,
          confirmButtonText: 'Ya',
          cancelButtonText: 'Batal',
          reverseButtons: true,
        }).then((result) => {
          if (result.isConfirmed) {
            this.updateStatus(d);
          } else {
            d.status = 0;
          }
        });
      }
    },
    async updateStatus(d) {
      const info = d.status ? 'mengaktifkan' : 'menonaktifkan';
      const headers = {
        Authorization: localStorage.getItem('token')
      };
      try {
        const response = await this.axios.post('/libur/perbarui-status', {
          libur_id: d.libur_id,
          status: d.status
        }, { headers: headers });
        if (response.data) {
          this.$swal({
            title: 'Sukses',
            text: `Berhasil ${info} Hari Non KBM ${d.nama}`,
            icon: 'success',
            showConfirmButton: false,
            timer: 1500
          })
        } else {
          this.$swal({
            title: 'Gagal',
            text: 'Data tidak ditemukan',
            icon: 'error',
            confirmButtonText: 'Baik'
          });
        }
      }
      catch (err) {
        if (err.response) {
          if (err.response.status == 401) {
            this.$swal({
              title: 'Gagal',
              text: 'Sesi berakhir. Silahkan login ulang.',
              icon: 'error',
              confirmButtonText: 'Baik'
            }).then(() => {
              this.$router.go();
            });
          } else if (err.response.status == 403) {
            this.$swal({
              title: 'Gagal',
              text: 'Anda tidak memiliki akses untuk memperbarui data.',
              icon: 'error',
              confirmButtonText: 'Baik'
            });
          } else {
            this.$swal({
              title: 'Gagal',
              html: `<p class="text-danger">${err.response.data.error}</p>
                <code class="text-secondary text-bg-secondary bg-opacity-10">code: ${err.response.data.code}<br/>
                  message: ${err.response.data.message}</code>`,
              icon: 'error',
              confirmButtonText: 'Baik'
            });
          }
        } else {
          console.log(err)
        }
      }
    },
    confirmUpdateStatusSelected(s) {
      const konfirmasi = s ? 'mengaktifkan' : 'menonaktifkan';
      this.$swal({
        title: "Konfirmasi",
        html: `Anda yakin ingin ${konfirmasi} data Hari Non KBM terpilih?`,
        icon: "question",
        showCancelButton: true,
        confirmButtonText: "Ya",
        cancelButtonText: "Batal",
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          this.updateStatusSelected(s)
        }
      });
    },
    async updateStatusSelected(s) {
      const headers = {
        Authorization: localStorage.getItem('token')
      };
      try {
        const response = await this.axios.post('/libur/perbarui-status', {
          listLiburId: this.tercentang,
          status: s
        }, { headers: headers });
        if (response.data) {
          this.$swal({
            title: 'Sukses',
            text: response.data.message,
            icon: 'success',
            showConfirmButton: false,
            timer: 1500
          })
        } else {
          this.$swal({
            title: 'Gagal',
            text: 'Data tidak ditemukan',
            icon: 'error',
            confirmButtonText: 'Baik'
          });
        }
      }
      catch (err) {
        if (err.response) {
          if (err.response.status == 401) {
            this.$swal({
              title: 'Gagal',
              text: 'Sesi berakhir. Silahkan login ulang.',
              icon: 'error',
              confirmButtonText: 'Baik'
            }).then(() => {
              this.$router.go();
            });
          } else if (err.response.status == 403) {
            this.$swal({
              title: 'Gagal',
              text: 'Anda tidak memiliki akses untuk memperbarui data.',
              icon: 'error',
              confirmButtonText: 'Baik'
            });
          } else {
            this.$swal({
              title: 'Gagal',
              html: `<p class="text-danger">${err.response.data.error}</p>
                <code class="text-secondary text-bg-secondary bg-opacity-10">code: ${err.response.data.code}<br/>
                  message: ${err.response.data.message}</code>`,
              icon: 'error',
              confirmButtonText: 'Baik'
            });
          }
        } else {
          console.log(err)
        }
      }
    },
    confirmDelete(d) {
      this.$swal({
        title: 'Konfirmasi',
        html: `<span class="h5">Anda yakin ingin menghapus data Hari Non KBM <code class="text-black text-bg-secondary bg-opacity-50">${d.nama}</code>?</span><br/>
          <small class="fst-italic text-muted">Tindakan ini tidak dapat diurungkan</small>`,
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: 'Ya, Hapus',
        cancelButtonText: 'Batal',
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          this.hapusData(d);
        }
      });
    },
    async hapusData(d) {
      let memuat = this.$loading.show({
        opacity: 0,
        loader: 'spinner'
      });
      const headers = {
        Authorization: localStorage.getItem('token')
      };
      try {
        const response = await this.axios.post('/libur/hapus', {
          libur_id: d.libur_id
        }, {
          headers: headers
        });
        if (response.data) {
          this.$swal({
            title: 'Sukses',
            text: response.data.message,
            icon: 'success',
            showConfirmButton: false,
            timer: 1500
          })
        } else {
          this.$swal({
            title: 'Gagal',
            text: 'Data tidak ditemukan.',
            icon: 'error',
            confirmButtonText: 'Baik'
          });
        }
      }
      catch (err) {
        if (err.response) {
          if (err.response.status == 401) {
            this.$swal({
              title: 'Gagal',
              text: 'Sesi berakhir. Silahkan login ulang.',
              icon: 'error',
              confirmButtonText: 'Baik'
            }).then(() => {
              this.$router.go();
            });
          } else if (err.response.status == 403) {
            this.$swal({
              title: 'Gagal',
              text: 'Anda tidak memiliki akses untuk menghapus data.',
              icon: 'error',
              confirmButtonText: 'Baik'
            });
          } else {
            this.$swal({
              title: 'Gagal',
              html: `<p class="text-danger">${err.response.data.error}</p>
                <code class="text-secondary text-bg-secondary bg-opacity-10">code: ${err.response.data.code}<br/>
                  message: ${err.response.data.message}</code>`,
              icon: 'error',
              confirmButtonText: 'Baik'
            });
          }
        } else {
          console.log(err)
        }
      }
      setTimeout(() => {
        memuat.hide();
      }, 250);
    },
    centangSatu(d) {
      const ditemukan = this.tercentang.findIndex((j) =>
        j == d.libur_id
      );
      if (ditemukan >= 0) {
        this.tercentang.splice(ditemukan, 1);
      } else {
        this.tercentang.push(d.libur_id);
      }
      if (this.tercentang.length == this.dataLibur.length) {
        this.semuaTercentang = true;
      } else {
        this.semuaTercentang = false;
      }
    },
    centangSemua() {
      this.tercentang = []
      if (this.semuaTercentang) {
        for (const d of this.dataLibur) {
          d.centang = false;
          this.tercentang = [];
        }
      } else {
        for (const d of this.dataLibur) {
          d.centang = true;
          this.tercentang.push(d.libur_id);
        }
      }
    },
    confirmDeleteSelected() {
      this.$swal({
        title: "Konfirmasi",
        html: `Anda yakin ingin menghapus data Hari Non KBM terpilih?<br/>
						&#9432; <em>Tindakan ini tidak dapat diurungkan.</em>`,
        icon: "question",
        showCancelButton: true,
        confirmButtonText: "Ya, Hapus",
        cancelButtonText: "Batal",
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          this.deleteSelected()
        }
      });
    },
    async deleteSelected() {
      let memuat = this.$loading.show({
        opacity: 0,
        loader: 'spinner'
      });
      const headers = {
        Authorization: localStorage.getItem('token')
      }; try {
        const response = await this.axios.post("/libur/hapus", {
          listLiburId: this.tercentang,
        }, {
          headers: headers
        });
        if (response.data) {
          this.$swal({
            title: 'Sukses',
            text: response.data.message,
            icon: 'success',
            showConfirmButton: false,
            timer: 1500
          })
        } else {
          this.$swal({
            title: 'Gagal',
            text: 'Data tidak ditemukan.',
            icon: 'error',
            confirmButtonText: 'Baik'
          });
        }
      }
      catch (err) {
        if (err.response) {
          if (err.response.status == 401) {
            this.$swal({
              title: 'Gagal',
              text: 'Sesi berakhir. Silahkan login ulang.',
              icon: 'error',
              confirmButtonText: 'Baik'
            }).then(() => {
              this.$router.go();
            });
          } else if (err.response.status == 403) {
            this.$swal({
              title: 'Gagal',
              text: 'Anda tidak memiliki akses untuk menghapus data.',
              icon: 'error',
              confirmButtonText: 'Baik'
            });
          } else {
            this.$swal({
              title: 'Gagal',
              html: `<p class="text-danger">${err.response.data.error}</p>
                <code class="text-secondary text-bg-secondary bg-opacity-10">code: ${err.response.data.code}<br/>
                  message: ${err.response.data.message}</code>`,
              icon: 'error',
              confirmButtonText: 'Baik'
            });
          }
        } else {
          console.log(err)
        }
      }
      setTimeout(() => {
        memuat.hide();
      }, 250);
    },
  }
}
</script>

<style scoped></style>