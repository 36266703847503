import { createStore } from "vuex";

export default createStore({
    state: {
        userData: JSON.parse(localStorage.getItem("user")) || {},
        tp: {
            tahun_ajaran_id: 2023,
            nama: "2023/2024",
        },
        semester: {
            semester_id: 20231,
            nama: "2023/2024 Gasal",
        },
        defaultLimit: 10,
    },
    getters: {
        getTp(state) {
            return state.tp;
        },
        getSemester(state) {
            return state.semester;
        },
        getDefaultLimit(state) {
            return state.defaultLimit;
        },
    },
    mutations: {
        updateUserData(state, userData) {
            state.userData = userData;
            localStorage.setItem("user", JSON.stringify(userData));
        },
        updateTp(state, tp) {
            state.tp = tp;
            localStorage.setItem("tpId", tp.tahun_ajaran_id);
            localStorage.setItem("nama", tp.nama);
        },
        updateSemester(state, semester) {
            state.semester = semester;
            localStorage.setItem("semesterId", semester.semester_id);
            localStorage.setItem("nama", semester.nama);
        },
        updateDefaultLimit(state, limit) {
            state.defaultLimit = limit;
        },
    },
    actions: {
        updateUser({ commit }, userData) {
            commit("updateUserData", userData);
        },
        updateDataPenjadwalan({ commit }, dataPenjadwalan) {
            commit("updateTp", dataPenjadwalan.tahun_ajaran);
            commit("updateSemester", dataPenjadwalan.semester);
        },
        setDefaultLimit({ commit }, batasBaris) {
            commit("updateDefaultLimit", batasBaris);
        },
    },
    modules: {},
});
