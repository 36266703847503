<template>
  <nav class="navbar navbar-expand-md py-1 sticky-top" data-bs-theme="dark">
    <div class="container-fluid">
      <router-link class="navbar-brand fs-5" to="/" @click="tutupNavBar">
        <img src="../assets/logo.png" class="d-inline-block align-text-bottom" alt="Logo" height="24">
        Aplikasi Jadwal
      </router-link>
      <button id="toggler" class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#menu"
        aria-controls="menu" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="navbar-collapse collapse" id="menu">
        <ul class="navbar-nav me-auto">
          <li class="nav-item">
            <router-link to="/" class="nav-link" :active-class="'active'" @click="tutupNavBar">Beranda</router-link>
          </li>
          <li class="nav-item" v-if="this.user.ket === 'it' || this.user.ket === 'kurikulum'">
            <router-link to="/data" class="nav-link" :class="{ active: $route.name == 'data' }"
              @click="tutupNavBar">Data</router-link>
          </li>
          <li class="nav-item">
            <router-link to="/kontrol" class="nav-link" :class="{ active: $route.name == 'kontrol' }"
              @click="tutupNavBar">Kontrol</router-link>
          </li>
          <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false"
              title="jadwal">
              <span :class="{ 'text-white': $route.name == 'jadwal' }">Jadwal</span>
            </a>
            <ul class="dropdown-menu dropdown-menu-dark shadow-lg">
              <li>
                <router-link to="/jadwal/rombel" class="dropdown-item" :active-class="'active'"
                  @click="tutupNavBar">Rombel</router-link>
              </li>
              <li>
                <router-link to="/jadwal/guru" class="dropdown-item" :active-class="'active'"
                  @click="tutupNavBar">Guru</router-link>
              </li>
              <li>
                <router-link to="/jadwal/mapel" class="dropdown-item" :active-class="'active'" @click="tutupNavBar">Mata
                  Pelajaran</router-link>
              </li>
              <li>
                <router-link to="/jadwal/ruang" class="dropdown-item" :active-class="'active'"
                  @click="tutupNavBar">Pengguna
                  Ruang</router-link>
              </li>
              <li>
                <router-link to="/jadwal/piket" class="dropdown-item" :active-class="'active'"
                  @click="tutupNavBar">Piket</router-link>
              </li>
            </ul>
          </li>
          <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false"
              title="penjadwalan">
              <span :class="{ 'text-white': $route.name == 'penjadwalan' }">Penjadwalan</span>
            </a>
            <ul class="dropdown-menu dropdown-menu-dark shadow-lg">
              <li>
                <router-link to="/penjadwalan/rombel" class="dropdown-item" :active-class="'active'"
                  @click="tutupNavBar">Rombel</router-link>
              </li>
              <li>
                <router-link to="/penjadwalan/guru" class="dropdown-item" :active-class="'active'"
                  @click="tutupNavBar">Guru</router-link>
              </li>
              <li>
                <router-link to="/penjadwalan/piket" class="dropdown-item" :active-class="'active'"
                  @click="tutupNavBar">Piket</router-link>
              </li>
            </ul>
          </li>
          <li class="nav-item">
            <router-link to="/riwayat" class="nav-link" :class="{ active: $route.name == 'riwayat' }"
              @click="tutupNavBar">Riwayat</router-link>
          </li>
        </ul>
        <ul class="navbar-nav ms-auto">
          <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false"
              title="nama pengguna">
              <img v-if="user.foto" :src="user.foto" height="24" alt="" class="rounded-circle"
                :class="{ 'border border-light': $route.name == 'ganti-sandi' || $route.name == 'profil' }">
              <img v-else src="../assets/person-light.png" height="24" alt="">
            </a>
            <ul class="dropdown-menu dropdown-menu-dark dropdown-menu-end shadow-lg">
              <li>
                <div class="dropdown-header text-center py-0">
                  <img v-if="user.foto" :src="user.foto" height="72" alt="" class="rounded-circle">
                  <img v-else src="../assets/person-light.png" height="72" alt="">
                  <h6 class="h6 text-light mb-0">{{ user.nama }}</h6>
                  <span class="fst-italic small text-light opacity-50">{{ user.username }}</span>
                </div>
              </li>
              <li>
                <hr class="dropdown-divider">
              </li>
              <li>
                <router-link to="/profil" class="dropdown-item" :active-class="'active'"
                  @click="tutupNavBar">Profil</router-link>
              </li>
              <li>
                <router-link to="/ganti-sandi" class="dropdown-item" :active-class="'active'" @click="tutupNavBar">Ganti
                  Kata Sandi</router-link>
              </li>
              <li><a class="dropdown-item" @click="logout">Keluar</a></li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  name: 'NavBar',
  data() {
    return {
      activeClass: 'nav-link dropdown-toggle active'
    }
  },
  props: {
    user: Object
  },
  created() {
  },
  methods: {
    async logout() {
      const headers = {
        Authorization: localStorage.getItem('token')
      }
      try {
        const response = await this.axios.post(this.$auth + '/logout', {}, {
          headers: headers
        });
        if (response) {
          localStorage.removeItem('token');
          localStorage.removeItem('user');
          window.location.href = this.$sso + '/' + this.$appId
        }
      } catch (err) {
        localStorage.removeItem('token');
        localStorage.removeItem('user');
        window.location.href = this.$sso + '/' + this.$appId
      }
    },
    tutupNavBar() {
      const menu = document.getElementById('menu');
      const toggler = document.getElementById('toggler');
      if (menu.getAttribute('class') == 'navbar-collapse collapse show' && toggler.getAttribute('class') == 'navbar-toggler') {
        toggler.click();
      }
    }
  }
}
</script>

<style scoped>
nav,
.dropdown-menu {
  background-color: #184b89;
}
</style>