import { createRouter, createWebHistory } from "vue-router";
import SetToken from "../components/SetToken";
import HomeView from "../views/HomeView";
import DataView from "../views/DataView";
import KontrolView from "../views/KontrolView";
import JadwalView from "../views/JadwalView";
import PenjadwalanView from "../views/PenjadwalanView";
import RiwayatView from "../views/RiwayatView";
import ProfilView from "../views/ProfilView";
import GantiSandiView from "../views/GantiSandiView";
import ForbiddenView from "../views/ForbiddenView";
import NotFound from "../components/NotFound";

const routes = [
    {
        path: "/auth/:token?",
        name: "auth",
        component: SetToken,
        props: (route) => ({ token: route.params.token }),
    },
    {
        path: "/",
        name: "home",
        component: HomeView,
    },
    {
        path: "/data/:data?",
        name: "data",
        component: DataView,
    },
    {
        path: "/kontrol/:data?",
        name: "kontrol",
        component: KontrolView,
    },
    {
        path: "/jadwal/:data",
        name: "jadwal",
        component: JadwalView,
    },
    {
        path: "/penjadwalan/:data",
        name: "penjadwalan",
        component: PenjadwalanView,
    },
    {
        path: "/riwayat/:data?",
        name: "riwayat",
        component: RiwayatView,
    },
    {
        path: "/profil",
        name: "profil",
        component: ProfilView,
    },
    {
        path: "/ganti-sandi",
        name: "ganti-sandi",
        component: GantiSandiView,
    },
    {
        path: "/forbidden/:halaman(.*)?",
        name: "forbidden",
        component: ForbiddenView,
    },
    {
        path: "/:catchAll(.*)",
        name: "NotFound",
        component: NotFound,
    },
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

export default router;
