<template>
  <h4>Pembuatan Jadwal Piket Guru</h4>
  <div class="card shadow">
    <div class="card-header">
      <div class="col-md-10 col-lg-8 col-xl-6 mx-auto my-3">
        <div class="card shadow vl-parent" ref="formCreate">
          <div class="card-header">
            <div class="row row-cols-auto justify-content-between">
              <div class="col-auto d-inline-flex">
                <h5 class="my-auto">Penjadwalan</h5>
              </div>
              <div class="col-auto ms-auto">
                <select class="form-select form-select-sm shadow-sm" v-model="semester">
                  <option disabled value="">-- Pilih Semester --</option>
                  <option v-for="s in listSemester" :key="s" :value="s">{{ s.nama }}</option>
                </select>
              </div>
            </div>
          </div>
          <form @submit.prevent="simpanJadwal">
            <div class="card-body">
              <div class="row mb-1">
                <label class="col-sm-4 col-form-label col-form-label-sm">Guru</label>
                <div class="col-sm-8">
                  <v-select v-model="guru" placeholder="Pilih Nama Guru" label="nama" :options="listGuru">
                    <template #search="{ attributes, events }">
                      <input class="vs__search" :required="!guru" v-bind="attributes" v-on="events" />
                    </template>
                    <template #no-options>
                      Guru tidak ditemukan.
                    </template>
                  </v-select>
                </div>
              </div>
              <div class="row mb-1">
                <label class="col-sm-4 col-form-label col-form-label-sm">Hari</label>
                <div class="col-sm-8">
                  <v-select v-model="hari" placeholder="Pilih Hari" label="nama_sisa" :options="listHari">
                    <template #search="{ attributes, events }">
                      <input class="vs__search" :required="!hari" v-bind="attributes" v-on="events" />
                    </template>
                    <template #no-options>
                      Hari tidak ditemukan.
                    </template>
                  </v-select>
                </div>
              </div>
              <div class="row mb-1">
                <label class="col-sm-4 col-form-label col-form-label-sm">Jenis Piket</label>
                <div class="col-sm-8">
                  <v-select v-model="piket" placeholder="Pilih Jenis Piket" label="nama" :options="listPiket">
                    <template #search="{ attributes, events }">
                      <input class="vs__search" :required="!piket" v-bind="attributes" v-on="events" />
                    </template>
                    <template #no-options>
                      Jenis Piket tidak ditemukan.
                    </template>
                  </v-select>
                </div>
              </div>
              <div class="row mb-1">
                <label class="col-sm-4 col-form-label col-form-label-sm">Rentang Jam</label>
                <div class="col-sm-8 select-wrapper">
                  <div class="input-group input-group-sm">
                    <select class="form-control form-control-sm" :class="{ 'border-end-0': jamAwal }" v-model="jamAwal"
                      required>
                      <option selected value="" disabled>Jam Mulai</option>
                      <option v-for="j in listJamAwal" :key="j" :value="j.jam_id">
                        {{ j.jam_id }} ({{ j.mulai }})
                      </option>
                    </select>
                    <button type="button" v-if="jamAwal" @click="jamAwal = ''" class="reset-button border-start-0">
                      <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10">
                        <path
                          d="M6.895455 5l2.842897-2.842898c.348864-.348863.348864-.914488 0-1.263636L9.106534.261648c-.348864-.348864-.914489-.348864-1.263636 0L5 3.104545 2.157102.261648c-.348863-.348864-.914488-.348864-1.263636 0L.261648.893466c-.348864.348864-.348864.914489 0 1.263636L3.104545 5 .261648 7.842898c-.348864.348863-.348864.914488 0 1.263636l.631818.631818c.348864.348864.914773.348864 1.263636 0L5 6.895455l2.842898 2.842897c.348863.348864.914772.348864 1.263636 0l.631818-.631818c.348864-.348864.348864-.914489 0-1.263636L6.895455 5z">
                        </path>
                      </svg>
                    </button>
                    <span class="input-group-text">s.d.</span>
                    <select class="form-control form-control-sm" :class="{ 'border-end-0': jamAkhir }" v-model="jamAkhir"
                      required>
                      <option selected value="" disabled>Jam Selesai</option>
                      <option v-for="j in listJamAkhir" :key="j" :value="j.jam_id">
                        {{ j.jam_id }} ({{ j.selesai }})
                      </option>
                    </select>
                    <button type="button" v-if="jamAkhir" @click="jamAkhir = ''"
                      class="reset-button border-start-0 rounded-end-1">
                      <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10">
                        <path
                          d="M6.895455 5l2.842897-2.842898c.348864-.348863.348864-.914488 0-1.263636L9.106534.261648c-.348864-.348864-.914489-.348864-1.263636 0L5 3.104545 2.157102.261648c-.348863-.348864-.914488-.348864-1.263636 0L.261648.893466c-.348864.348864-.348864.914489 0 1.263636L3.104545 5 .261648 7.842898c-.348864.348863-.348864.914488 0 1.263636l.631818.631818c.348864.348864.914773.348864 1.263636 0L5 6.895455l2.842898 2.842897c.348863.348864.914772.348864 1.263636 0l.631818-.631818c.348864-.348864.348864-.914489 0-1.263636L6.895455 5z">
                        </path>
                      </svg>
                    </button>
                  </div>
                </div>
              </div>
              <div class="row mb-1">
                <label class="col-sm-4 col-form-label col-form-label-sm">Lokasi</label>
                <div class="col-sm-8">
                  <v-select v-model="lokasi" placeholder="Pilih Lokasi" label="nama" :options="listLokasi">
                    <template #search="{ attributes, events }">
                      <input class="vs__search" :required="!lokasi" v-bind="attributes" v-on="events" />
                    </template>
                    <template #no-options>
                      Lokai tidak ditemukan.
                    </template>
                  </v-select>
                </div>
              </div>
              <div class="row">
                <label class="col-sm-4 col-form-label col-form-label-sm">Keterangan</label>
                <div class="col-sm-8">
                  <input type="text" v-model="keterangan" placeholder="Keterangan Piket"
                    class="form-control form-control-sm" required>
                </div>
              </div>
            </div>
            <div class="card-footer">
              <button type="submit" class="btn btn-sm btn-secondary d-flex ms-auto shadow-sm">Simpan</button>
            </div>
          </form>
        </div>
      </div>
    </div>
    <div class="card-body" v-if="guru">
      <div class="row row-cols-auto justify-content-between mb-2 mx-auto gap-2">
        <div class="col-auto p-0 d-inline-flex">
          <h5 class="my-auto">Jadwal Mengajar/Piket {{ guru.nama }} ({{ jumlah.jumlah_jam_terjadwal + "/" +
            jumlah.total_jam
          }})</h5>
        </div>
        <div class="col-auto p-0" v-if="jadwalGuru.length">
          <button type="button" class="btn btn-sm btn-outline-danger" @click="confirmDeleteAll">
            Hapus jadwal guru
          </button>
        </div>
      </div>
      <div class="table-responsive shadow vl-parent" ref="table">
        <table class="table table-sm table-striped table-hover mb-0 align-middle">
          <thead class="table-secondary">
            <tr>
              <th class="position-sticky start-0">Hari</th>
              <th>Jam Ke</th>
              <th>Rombongan Belajar</th>
              <th>Mata Pelajaran</th>
              <th>Ruang</th>
              <th>Keterangan</th>
              <th>
                Tindakan
              </th>
            </tr>
          </thead>
          <tbody v-if="jadwalGuru.length">
            <tr v-for="d in jadwalGuru" :key="d">
              <td class="position-sticky start-0">{{ d.nama_hari }}</td>
              <td>
                <strong>{{ d.jam_awal + " - " + d.jam_akhir }}</strong><br />
                <em>({{ d.waktu_mulai + " - " + d.waktu_selesai }})</em>
              </td>
              <td>{{ d.nama_rombel || "-" }}</td>
              <td>{{ d.nama_mapel }}</td>
              <td>{{ d.kode_lokasi }} {{ d.nama_ruang == null ? "" : " - " + d.nama_ruang }}</td>
              <td>{{ d.keterangan || "-" }}</td>
              <td v-if="d.rombel_id">-</td>
              <td v-else>
                <div class="hstack gap-1 me-1">
                  <button class="btn btn-sm btn-outline-primary d-flex" @click="editData = d" data-bs-toggle="modal"
                    data-bs-target="#editData">
                    <svg xmlns="http://www.w3.org/2000/svg" width="13" fill="currentColor" class="bi bi-pencil-square"
                      viewBox="0 0 16 16">
                      <path
                        d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                      <path fill-rule="evenodd"
                        d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z" />
                    </svg>
                  </button>
                  <div class="vr"></div>
                  <button class="btn btn-sm btn-outline-danger d-flex" @click="confirmDelete(d)">
                    <svg xmlns="http://www.w3.org/2000/svg" width="13" fill="currentColor" class="bi bi-trash-fill"
                      viewBox="0 0 16 16">
                      <path
                        d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1H2.5zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5zM8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5zm3 .5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 1 0z" />
                    </svg>
                  </button>
                </div>
              </td>
            </tr>
          </tbody>
          <tbody v-else>
            <tr>
              <td class="text-center fst-italic py-3" colspan="7">Data tidak ditemukan.</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <div class="modal fade" id="editData" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
    aria-hidden="true">
    <div class="modal-lg modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <form @submit.prevent="perbaruiJadwal">
          <div class="modal-header">
            <legend class="modal-title">Edit Jadwal Piket {{ Object(guru).nama }}</legend>
            <button type="button" id="tutupFormEdit" class="btn-close" data-bs-dismiss="modal"></button>
          </div>
          <div class="modal-body">
            <div class="row mb-1">
              <label class="col-sm-4 col-form-label col-form-label-sm">Guru</label>
              <div class="col-sm-8">
                <span class="form-control form-control-sm fw-bold">{{ Object(guru).nama }}</span>
              </div>
            </div>
            <div class="row mb-1">
              <label class="col-sm-4 col-form-label col-form-label-sm">Hari</label>
              <div class="col-sm-8">
                <v-select v-model="hariEdit" :placeholder="editData.nama_hari" label="nama_sisa" :options="listHari">
                  <template #search="{ attributes, events }">
                    <input class="vs__search" v-bind="attributes" v-on="events" />
                  </template>
                  <template #no-options>
                    Hari tidak ditemukan.
                  </template>
                </v-select>
              </div>
            </div>
            <div class="row mb-1">
              <label class="col-sm-4 col-form-label col-form-label-sm">Jenis Piket</label>
              <div class="col-sm-8">
                <v-select v-model="piketEdit" :placeholder="editData.nama_mapel" label="nama" :options="listPiket">
                  <template #no-options>
                    Jenis Piket tidak ditemukan.
                  </template>
                </v-select>
              </div>
            </div>
            <div class="row mb-1">
              <label class="col-sm-4 col-form-label col-form-label-sm">Rentang Jam</label>
              <div class="col-sm-8 select-wrapper">
                <div class="input-group input-group-sm">
                  <select class="form-control form-control-sm" :class="{ 'border-end-0': jamAwalEdit }"
                    v-model="jamAwalEdit" :required="jamAwalEditRequired">
                    <option selected value="" disabled>Jam Mulai ~ <em>({{ editData.jam_awal }})</em></option>
                    <option v-for="j in listJamAwalEdit" :key="j" :value="j.jam_id">
                      {{ j.jam_id }} ({{ j.mulai }})
                    </option>
                  </select>
                  <button type="button" v-if="jamAwalEdit" @click="jamAwalEdit = ''" class="reset-button border-start-0">
                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10">
                      <path
                        d="M6.895455 5l2.842897-2.842898c.348864-.348863.348864-.914488 0-1.263636L9.106534.261648c-.348864-.348864-.914489-.348864-1.263636 0L5 3.104545 2.157102.261648c-.348863-.348864-.914488-.348864-1.263636 0L.261648.893466c-.348864.348864-.348864.914489 0 1.263636L3.104545 5 .261648 7.842898c-.348864.348863-.348864.914488 0 1.263636l.631818.631818c.348864.348864.914773.348864 1.263636 0L5 6.895455l2.842898 2.842897c.348863.348864.914772.348864 1.263636 0l.631818-.631818c.348864-.348864.348864-.914489 0-1.263636L6.895455 5z">
                      </path>
                    </svg>
                  </button>
                  <span class="input-group-text">s.d.</span>
                  <select class="form-control form-control-sm" :class="{ 'border-end-0': jamAkhirEdit }"
                    v-model="jamAkhirEdit" :required="jamAkhirEditRequired">
                    <option selected value="" disabled>Jam Selesai ~ <em>({{ editData.jam_akhir }})</em></option>
                    <option v-for="j in listJamAkhirEdit" :key="j" :value="j.jam_id">
                      {{ j.jam_id }} ({{ j.selesai }})
                    </option>
                  </select>
                  <button type="button" v-if="jamAkhirEdit" @click="jamAkhirEdit = ''"
                    class="reset-button border-start-0 rounded-end-1">
                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10">
                      <path
                        d="M6.895455 5l2.842897-2.842898c.348864-.348863.348864-.914488 0-1.263636L9.106534.261648c-.348864-.348864-.914489-.348864-1.263636 0L5 3.104545 2.157102.261648c-.348863-.348864-.914488-.348864-1.263636 0L.261648.893466c-.348864.348864-.348864.914489 0 1.263636L3.104545 5 .261648 7.842898c-.348864.348863-.348864.914488 0 1.263636l.631818.631818c.348864.348864.914773.348864 1.263636 0L5 6.895455l2.842898 2.842897c.348863.348864.914772.348864 1.263636 0l.631818-.631818c.348864-.348864.348864-.914489 0-1.263636L6.895455 5z">
                      </path>
                    </svg>
                  </button>
                </div>
              </div>
            </div>
            <div class="row mb-1">
              <label class="col-sm-4 col-form-label col-form-label-sm">Lokasi</label>
              <div class="col-sm-8">
                <v-select v-model="lokasiEdit" :placeholder="editData.kode_lokasi" label="nama" :options="listLokasi">
                  <template #no-options>
                    Lokasi tidak ditemukan.
                  </template>
                </v-select>
              </div>
            </div>
            <div class="row">
              <label class="col-sm-4 col-form-label col-form-label-sm">Keterangan</label>
              <div class="col-sm-8">
                <input type="text" v-model="keteranganEdit" :placeholder="editData.keterangan"
                  class="form-control form-control-sm">
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="submit" class="btn btn-primary">Perbarui</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import WebSocketService from './WebSocketService';

export default {
  name: 'PenjadwalanPiket',
  data() {
    return {
      dataListener: (d) => {
        if (this.guru) {
          switch (d) {
            case 'hari':
              this.getHariTersedia();
              this.getJadwalGuru();
              break;
            case 'statusHari':
              this.getHariTersedia();
              this.getJadwalGuru();
              break;
            case 'jam':
              this.getJamTersedia();
              break;
            case 'statusJam':
              this.getJamTersedia();
              break;
            case 'piket':
              this.getJenisPiket();
              break;
            case 'statusPiket':
              this.getJenisPiket();
              break;
            default:
              break;
          }
        } else if (d == 'lokasi') {
          this.getLokasi();
        }
      },
      penjadwalanListener: (d) => {
        const data = JSON.parse(d);
        if (data.semester_id == this.semester.semester_id) {
          if ((data.guru_id && data.guru_id == this.guru.guru_id) ||
            (data.old_guru_id && data.old_guru_id == this.guru.guru_id) ||
            (data.new_guru_id && data.new_guru_id == this.guru.guru_id)) {
            document.getElementById('tutupFormEdit').click();
            this.getJadwalGuru();
            this.getHariTersedia();
          }
        }
      },
      listSemester: [],
      listGuru: [],
      listHari: [],
      listPiket: [],
      listJamAwal: [],
      listJamAkhir: [],
      listLokasi: [],
      jadwalGuru: [],
      jumlah: "",
      semester: "",
      guru: "",
      hari: "",
      piket: "",
      jamAwal: "",
      jamAkhir: "",
      lokasi: "",
      keterangan: "",
      editData: {},
      hariEdit: "",
      listJamAwalEdit: [],
      listJamAkhirEdit: [],
      jamAwalEdit: "",
      jamAkhirEdit: "",
      piketEdit: "",
      lokasiEdit: "",
      keteranganEdit: "",
      jamAwalEditRequired: false,
      jamAkhirEditRequired: false,
    }
  },
  mounted() {
    WebSocketService.on("inserted-or-deleted-data", this.dataListener);
    WebSocketService.on("update-data", this.dataListener);
    WebSocketService.on("inserted-or-deleted-jadwal", this.penjadwalanListener);
    WebSocketService.on("update-jadwal", this.penjadwalanListener);
    this.semester = {
      tahun_ajaran_id: this.tpAktif.tahun_ajaran_id,
      ...this.semesterAktif
    };
    this.getListSemester();
    this.getJenisPiket();
    this.getLokasi();
  },
  beforeUnmount() {
    WebSocketService.off("inserted-or-deleted-data", this.dataListener);
    WebSocketService.off("update-data", this.dataListener);
    WebSocketService.off("inserted-or-deleted-jadwal", this.penjadwalanListener);
    WebSocketService.off("update-jadwal", this.penjadwalanListener);
  },
  computed: {
    tpAktif() {
      return this.$store.getters.getTp
    },
    semesterAktif() {
      return this.$store.getters.getSemester
    },
  },
  watch: {
    semesterAktif() {
      document.getElementById('tutupFormEdit').click();
      this.semester = {
        tahun_ajaran_id: this.tpAktif.tahun_ajaran_id,
        ...this.semesterAktif
      };
    },
    semester() {
      this.guru = "";
      this.getListGuru();
    },
    guru() {
      if (!this.guru) {
        this.listHari = [];
        this.jadwalGuru = [];
      } else {
        this.getHariTersedia();
        this.getJadwalGuru();
      }
    },
    hari() {
      if (!this.hari) {
        this.listJamAwal = [];
      } else {
        this.getJamTersedia();
      }
    },
    listHari() {
      const cekHari = this.listHari.findIndex((d) => JSON.stringify(d) == JSON.stringify(this.hari));
      if (cekHari < 0) {
        this.hari = "";
      }
    },
    listPiket() {
      const cekPiket = this.listPiket.findIndex((d) => JSON.stringify(d) == JSON.stringify(this.piket));
      if (cekPiket < 0) {
        this.piket = "";
      }
    },
    jamAwal() {
      if (this.jamAwal) {
        this.getJamSelesai();
      } else {
        this.jamAkhir = "";
        this.listJamAkhir = [];
      }
    },
    listJamAwal() {
      const cekJam = this.listJamAwal.findIndex((d) => d.jam_id == this.jamAwal);
      if (cekJam < 0) {
        this.jamAwal = "";
      } else {
        if (this.jamAwal) {
          this.getJamSelesai();
        }
      }
    },
    listLokasi() {
      const cekLokasi = this.listLokasi.findIndex((d) => JSON.stringify(d) == JSON.stringify(this.lokasi));
      if (cekLokasi < 0) {
        this.lokasi = "";
      }
    },
    editData() {
      this.hariEdit = "";
      this.piketEdit = "";
      this.jamAwalEdit = "";
      this.jamAkhirEdit = "";
      this.lokasiEdit = "";
      this.keteranganEdit = "";
      this.getJamTersediaEdit();
    },
    hariEdit() {
      if (!this.hariEdit) {
        this.jamAwalEdit = "";
        this.jamAwalEditRequired = false;
        this.jamAkhirEditRequired = false;
      } else {
        this.jamAwalEditRequired = true;
        this.jamAkhirEditRequired = true;
      }
      this.getJamTersediaEdit();
    },
    jamAwalEdit() {
      if (this.jamAwalEdit) {
        this.getJamSelesaiEdit();
        this.jamAkhirEditRequired = true;
      } else {
        this.jamAkhirEdit = "";
        this.listJamAkhirEdit = [];
      }
    },
    listJamAwalEdit() {
      this.listJamAkhirEdit = [];
      const cekJam = this.listJamAwalEdit.findIndex((d) => d.jam_id == this.jamAwalEdit);
      if (cekJam < 0) {
        this.jamAwalEdit = "";
      }
    },
  },
  methods: {
    async getListSemester() {
      const headers = {
        Authorization: localStorage.getItem('token')
      };
      try {
        const response = await this.axios.post('/penjadwalan/semester/list', {}, {
          headers: headers
        });
        if (response.data) {
          this.listSemester = response.data.semester;
        }
      }
      catch (err) {
        if (err.response) {
          if (err.response.status == 401) {
            this.$swal({
              title: 'Gagal',
              text: 'Sesi berakhir. Silahkan login ulang.',
              icon: 'error',
              confirmButtonText: 'Baik'
            }).then(() => {
              this.$router.go();
            });
          } else {
            console.log(err);
          }
        } else {
          console.log(err)
        }
      }
    },
    async getListGuru() {
      const headers = {
        Authorization: localStorage.getItem('token')
      };
      try {
        const response = await this.axios.post('/guru/aktif', {}, {
          headers: headers
        });
        if (response.data) {
          this.listGuru = response.data.guru;
        }
      }
      catch (err) {
        if (err.response) {
          if (err.response.status == 401) {
            this.$swal({
              title: 'Gagal',
              text: 'Sesi berakhir. Silahkan login ulang.',
              icon: 'error',
              confirmButtonText: 'Baik'
            }).then(() => {
              this.$router.go();
            });
          } else {
            console.log(err);
          }
        } else {
          console.log(err)
        }
      }
    },
    async getHariTersedia() {
      const headers = {
        Authorization: localStorage.getItem('token')
      };
      try {
        const response = await this.axios.post('/hari/tersedia', {
          semester_id: this.semester.semester_id,
          guru_id: this.guru.guru_id,
        }, {
          headers: headers
        });
        if (response.data) {
          const dataHari = response.data.hari;
          for (const h of dataHari) {
            h.nama_sisa = `${h.nama} (sisa ${h.jumlah_jam - h.jumlah} jam)`;
          }
          this.listHari = dataHari;
        }
      }
      catch (err) {
        if (err.response) {
          if (err.response.status == 401) {
            this.$swal({
              title: 'Gagal',
              text: 'Sesi berakhir. Silahkan login ulang.',
              icon: 'error',
              confirmButtonText: 'Baik'
            }).then(() => {
              this.$router.go();
            });
          } else {
            console.log(err);
          }
        } else {
          console.log(err)
        }
      }
    },
    async getJenisPiket() {
      const headers = {
        Authorization: localStorage.getItem('token')
      };
      try {
        const response = await this.axios.post('/piket/list', {}, {
          headers: headers
        });
        if (response.data) {
          this.listPiket = response.data.piket;
        }
      }
      catch (err) {
        if (err.response) {
          if (err.response.status == 401) {
            this.$swal({
              title: 'Gagal',
              text: 'Sesi berakhir. Silahkan login ulang.',
              icon: 'error',
              confirmButtonText: 'Baik'
            }).then(() => {
              this.$router.go();
            });
          } else {
            console.log(err);
          }
        } else {
          console.log(err)
        }
      }
    },
    async getJamTersedia() {
      const headers = {
        Authorization: localStorage.getItem('token')
      };
      try {
        const response = await this.axios.post('/jam/tersedia', {
          semester_id: this.semester.semester_id,
          hari_id: this.hari.hari_id,
          guru_id: this.guru.guru_id,
        }, {
          headers: headers
        });
        if (response.data) {
          this.listJamAwal = response.data.jam;
          for (const j of this.listJamAwal) {
            if (j.mulai_khusus && j.selesai_khusus) {
              j.mulai = j.mulai_khusus.split(':').slice(0, 2).join(':');
              j.selesai = j.selesai_khusus.split(':').slice(0, 2).join(':');
            } else {
              j.mulai = j.mulai.split(':').slice(0, 2).join(':');
              j.selesai = j.selesai.split(':').slice(0, 2).join(':');
            }
          }
        }
      }
      catch (err) {
        if (err.response) {
          if (err.response.status == 401) {
            this.$swal({
              title: 'Gagal',
              text: 'Sesi berakhir. Silahkan login ulang.',
              icon: 'error',
              confirmButtonText: 'Baik'
            }).then(() => {
              this.$router.go();
            });
          } else {
            console.log(err);
          }
        } else {
          console.log(err)
        }
      }
    },
    async getJamTersediaEdit() {
      const headers = {
        Authorization: localStorage.getItem('token')
      };
      try {
        const response = await this.axios.post('/jam/tersedia', {
          semester_id: this.semester.semester_id,
          hari_id: this.hariEdit ? this.hariEdit.hari_id : this.editData.hari_id,
          guru_id: this.guru.guru_id,
          jam_awal: this.hariEdit ? null : this.editData.jam_awal,
          jam_akhir: this.hariEdit ? null : this.editData.jam_akhir,
        }, {
          headers: headers
        });
        if (response.data) {
          this.listJamAwalEdit = response.data.jam;
          for (const j of this.listJamAwalEdit) {
            if (j.mulai_khusus && j.selesai_khusus) {
              j.mulai = j.mulai_khusus.split(':').slice(0, 2).join(':');
              j.selesai = j.selesai_khusus.split(':').slice(0, 2).join(':');
            } else {
              j.mulai = j.mulai.split(':').slice(0, 2).join(':');
              j.selesai = j.selesai.split(':').slice(0, 2).join(':');
            }
          }
        }
      }
      catch (err) {
        if (err.response) {
          if (err.response.status == 401) {
            this.$swal({
              title: 'Gagal',
              text: 'Sesi berakhir. Silahkan login ulang.',
              icon: 'error',
              confirmButtonText: 'Baik'
            }).then(() => {
              this.$router.go();
            });
          } else {
            console.log(err);
          }
        } else {
          console.log(err)
        }
      }
    },
    getJamSelesai() {
      this.listJamAkhir = [];
      for (let i in this.listJamAwal) {
        if (this.listJamAwal[i].jam_id >= this.jamAwal) {
          if (this.listJamAwal[i].jam_id == this.jamAwal) {
            this.listJamAkhir.push(this.listJamAwal[i]);
          } else if (this.listJamAwal[i].jam_id - this.listJamAwal[i - 1].jam_id > 1) {
            break;
          } else {
            this.listJamAkhir.push(this.listJamAwal[i]);
          }
        }
      }
    },
    getJamSelesaiEdit() {
      this.listJamAkhirEdit = [];
      for (let i in this.listJamAwalEdit) {
        if (this.listJamAwalEdit[i].jam_id >= this.jamAwalEdit) {
          if (this.listJamAwalEdit[i].jam_id == this.jamAwalEdit) {
            this.listJamAkhirEdit.push(this.listJamAwalEdit[i]);
          } else if (this.listJamAwalEdit[i].jam_id - this.listJamAwalEdit[i - 1].jam_id > 1) {
            break;
          } else {
            this.listJamAkhirEdit.push(this.listJamAwalEdit[i]);
          }
        }
      }
    },
    async getLokasi() {
      const headers = {
        Authorization: localStorage.getItem('token')
      };
      try {
        const response = await this.axios.post('/lokasi/list', {}, {
          headers: headers
        });
        if (response.data) {
          this.listLokasi = response.data.lokasi;
        }
      }
      catch (err) {
        if (err.response) {
          if (err.response.status == 401) {
            this.$swal({
              title: 'Gagal',
              text: 'Sesi berakhir. Silahkan login ulang.',
              icon: 'error',
              confirmButtonText: 'Baik'
            }).then(() => {
              this.$router.go();
            });
          } else {
            console.log(err);
          }
        } else {
          console.log(err)
        }
      }
    },
    async getJadwalGuru() {
      const headers = {
        Authorization: localStorage.getItem('token')
      };
      try {
        const response = await this.axios.post('/jadwal/guru', {
          semester_id: this.semester.semester_id,
          guru_id: this.guru.guru_id,
        }, {
          headers: headers
        });
        if (response.data) {
          this.jumlah = response.data.count;
          this.jadwalGuru = response.data.jadwal;
          for (const j of this.jadwalGuru) {
            if (j.waktu_mulai_khusus && j.waktu_selesai_khusus) {
              j.waktu_mulai = j.waktu_mulai_khusus.split(':').slice(0, 2).join(':');
              j.waktu_selesai = j.waktu_selesai_khusus.split(':').slice(0, 2).join(':');
            } else {
              j.waktu_mulai = j.waktu_mulai.split(':').slice(0, 2).join(':');
              j.waktu_selesai = j.waktu_selesai.split(':').slice(0, 2).join(':');
            }
          }
        }
      }
      catch (err) {
        if (err.response) {
          if (err.response.status == 401) {
            this.$swal({
              title: 'Gagal',
              text: 'Sesi berakhir. Silahkan login ulang.',
              icon: 'error',
              confirmButtonText: 'Baik'
            }).then(() => {
              this.$router.go();
            });
          } else {
            console.log(err);
          }
        } else {
          console.log(err)
        }
      }
    },
    async simpanJadwal() {
      let memuat = this.$loading.show({
        container: this.$refs.formCreate,
        loader: 'spinner'
      });
      const newData = {
        semester_id: this.semester.semester_id,
        hari_id: this.hari.hari_id,
        jam_awal: this.jamAwal,
        jam_akhir: this.jamAkhir,
        piket_id: this.piket.piket_id,
        guru_id: this.guru.guru_id,
        lokasi_id: this.lokasi.lokasi_id,
        keterangan: this.keterangan,
      }
      const headers = {
        Authorization: localStorage.getItem('token')
      };
      try {
        const response = await this.axios.post('/jadwal-piket/tambah', newData, {
          headers: headers
        });
        if (response.data) {
          this.$swal({
            title: 'Sukses',
            text: response.data.message,
            icon: 'success',
            showConfirmButton: false,
            timer: 1500
          })
        }
      }
      catch (err) {
        if (err.response) {
          if (err.response.status == 401) {
            this.$swal({
              title: 'Gagal',
              text: 'Sesi berakhir. Silahkan login ulang.',
              icon: 'error',
              confirmButtonText: 'Baik'
            }).then(() => {
              this.$router.go();
            });
          } else if (err.response.status == 403) {
            this.$swal({
              title: 'Gagal',
              text: 'Anda tidak memiliki akses untuk menambahkan data.',
              icon: 'error',
              confirmButtonText: 'Baik'
            });
          } else {
            this.$swal({
              title: 'Gagal',
              html: `<p class="text-danger">${err.response.data.error}</p>
                    <code class="text-secondary text-bg-secondary bg-opacity-10">code: ${err.response.data.code}<br/>
                      message: ${err.response.data.message}</code>`,
              icon: 'error',
              confirmButtonText: 'Baik'
            });
          }
        } else {
          console.log(err)
        }
      }
      setTimeout(() => {
        memuat.hide();
      }, 250);
    },
    async perbaruiJadwal() {
      const newData = {
        semester_id: this.semester.semester_id,
        old_hari_id: this.editData.hari_id,
        old_jam_awal: this.editData.jam_awal,
        old_jam_akhir: this.editData.jam_akhir,
        old_piket_id: this.editData.mapel_id,
        old_guru_id: this.guru.guru_id,
        old_lokasi_id: this.editData.lokasi_id,
        old_keterangan: this.editData.keterangan,
        new_hari_id: this.hariEdit ? this.hariEdit.hari_id : this.editData.hari_id,
        new_jam_awal: this.jamAwalEdit ? this.jamAwalEdit : this.editData.jam_awal,
        new_jam_akhir: this.jamAkhirEdit ? this.jamAkhirEdit : this.editData.jam_akhir,
        new_piket_id: this.piketEdit ? this.piketEdit.piket_id : this.editData.mapel_id,
        new_guru_id: this.guru.guru_id,
        new_lokasi_id: this.lokasiEdit ? this.lokasiEdit.lokasi_id : this.editData.lokasi_id,
        new_keterangan: this.keteranganEdit ? this.keteranganEdit : this.editData.keterangan,
      }
      if (this.hariEdit || this.jamAwalEdit || this.jamAkhirEdit ||
        this.piketEdit || this.lokasiEdit || this.keteranganEdit) {
        const headers = {
          Authorization: localStorage.getItem('token')
        };
        try {
          const response = await this.axios.post('/jadwal-piket/perbarui', newData, {
            headers: headers
          });
          if (response.data) {
            this.$swal({
              title: 'Sukses',
              text: response.data.message,
              icon: 'success',
              showConfirmButton: false,
              timer: 1500
            })
          }
        }
        catch (err) {
          if (err.response) {
            if (err.response.status == 401) {
              this.$swal({
                title: 'Gagal',
                text: 'Sesi berakhir. Silahkan login ulang.',
                icon: 'error',
                confirmButtonText: 'Baik'
              }).then(() => {
                this.$router.go();
              });
            } else if (err.response.status == 403) {
              this.$swal({
                title: 'Gagal',
                text: 'Anda tidak memiliki akses untuk memperbarui data.',
                icon: 'error',
                confirmButtonText: 'Baik'
              });
            } else {
              this.$swal({
                title: 'Gagal',
                html: `<p class="text-danger">${err.response.data.error}</p>
                    <code class="text-secondary text-bg-secondary bg-opacity-10">code: ${err.response.data.code}<br/>
                      message: ${err.response.data.message}</code>`,
                icon: 'error',
                confirmButtonText: 'Baik'
              });
            }
          } else {
            console.log(err)
          }
        }
      } else {
        this.$swal({
          title: 'Perhatian',
          text: 'Anda belum melakukan perubahan',
          icon: 'warning',
          confirmButtonText: 'Baik'
        });
      }
    },
    confirmDelete(d) {
      this.$swal({
        title: 'Konfirmasi',
        html: `<span class="h5">Anda yakin ingin menghapus Jadwal Piket <code class="text-black text-bg-secondary bg-opacity-50">${this.guru.nama}</code>
          hari <code class="text-black text-bg-secondary bg-opacity-50">${d.nama_hari}</code> jam ke <code class="text-black text-bg-secondary bg-opacity-50">${d.jam_awal}-${d.jam_akhir}</code>?</span><br/>
          <small class="fst-italic text-muted">Tindakan ini tidak dapat diurungkan</small>`,
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: 'Ya, Hapus',
        cancelButtonText: 'Batal',
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          this.hapusData(d);
        }
      });
    },
    async hapusData(d) {
      const headers = {
        Authorization: localStorage.getItem('token')
      };
      const dataToDelete = {
        semester_id: this.semester.semester_id,
        hari_id: d.hari_id,
        jam_awal: d.jam_awal,
        jam_akhir: d.jam_akhir,
        piket_id: d.mapel_id,
        guru_id: this.guru.guru_id,
        lokasi_id: d.lokasi_id,
        keterangan: d.keterangan,
      }
      try {
        const response = await this.axios.post('/jadwal-piket/hapus', dataToDelete, {
          headers: headers
        });
        if (response.data) {
          this.$swal({
            title: 'Sukses',
            text: response.data.message,
            icon: 'success',
            showConfirmButton: false,
            timer: 1500
          })
        } else {
          this.$swal({
            title: 'Gagal',
            text: 'Data tidak ditemukan.',
            icon: 'error',
            confirmButtonText: 'Baik'
          });
        }
      }
      catch (err) {
        if (err.response) {
          if (err.response.status == 401) {
            this.$swal({
              title: 'Gagal',
              text: 'Sesi berakhir. Silahkan login ulang.',
              icon: 'error',
              confirmButtonText: 'Baik'
            }).then(() => {
              this.$router.go();
            });
          } else if (err.response.status == 403) {
            this.$swal({
              title: 'Gagal',
              text: 'Anda tidak memiliki akses untuk menghapus data.',
              icon: 'error',
              confirmButtonText: 'Baik'
            });
          } else {
            this.$swal({
              title: 'Gagal',
              html: `<p class="text-danger">${err.response.data.error}</p>
                <code class="text-secondary text-bg-secondary bg-opacity-10">code: ${err.response.data.code}<br/>
                  message: ${err.response.data.message}</code>`,
              icon: 'error',
              confirmButtonText: 'Baik'
            });
          }
        } else {
          console.log(err)
        }
      }
    },
    confirmDeleteAll() {
      this.$swal({
        title: 'Konfirmasi',
        html: `<span class="h5">Anda yakin ingin menghapus seluruh Jadwal Mengajar/Piket <code class="text-black text-bg-secondary bg-opacity-50">${this.guru.nama}</code>?</span><br/>
          <small class="fst-italic text-muted">Tindakan ini tidak dapat diurungkan</small>`,
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: 'Ya, Hapus',
        cancelButtonText: 'Batal',
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          this.hapusDataAll();
        }
      });
    },
    async hapusDataAll() {
      const headers = {
        Authorization: localStorage.getItem('token')
      };
      let listHariId = [];
      for (const h of this.jadwalGuru) {
        listHariId.push(h.hari_id);
      }
      try {
        const response = await this.axios.post('/jadwal/hapus/guru', {
          semester_id: this.semester.semester_id,
          guru_id: this.guru.guru_id,
          listHariId: listHariId
        }, {
          headers: headers
        });
        if (response.data) {
          this.$swal({
            title: 'Sukses',
            text: response.data.message,
            icon: 'success',
            showConfirmButton: false,
            timer: 1500
          })
        } else {
          this.$swal({
            title: 'Gagal',
            text: 'Data tidak ditemukan.',
            icon: 'error',
            confirmButtonText: 'Baik'
          });
        }
      }
      catch (err) {
        if (err.response) {
          if (err.response.status == 401) {
            this.$swal({
              title: 'Gagal',
              text: 'Sesi berakhir. Silahkan login ulang.',
              icon: 'error',
              confirmButtonText: 'Baik'
            }).then(() => {
              this.$router.go();
            });
          } else if (err.response.status == 403) {
            this.$swal({
              title: 'Gagal',
              text: 'Anda tidak memiliki akses untuk menghapus data.',
              icon: 'error',
              confirmButtonText: 'Baik'
            });
          } else {
            this.$swal({
              title: 'Gagal',
              html: `<p class="text-danger">${err.response.data.error}</p>
                <code class="text-secondary text-bg-secondary bg-opacity-10">code: ${err.response.data.code}<br/>
                  message: ${err.response.data.message}</code>`,
              icon: 'error',
              confirmButtonText: 'Baik'
            });
          }
        } else {
          console.log(err)
        }
      }
    },
  },
}
</script>

<style scoped></style>