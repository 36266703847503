<template>
  <div class="row row-cols-auto justify-content-between mb-2 mx-auto gap-2">
    <div class="col-auto p-0 d-inline-flex">
      <h5 class="my-auto">Jam Pembelajaran Khusus</h5>
    </div>
    <div class="col-auto p-0">
      <button type="button" class="btn btn-sm btn-secondary hstack gap-1 shadow-sm" data-bs-toggle="modal"
        data-bs-target="#tambahData">
        <svg xmlns="http://www.w3.org/2000/svg" height="16" fill="currentColor" class="bi bi-plus-lg" viewBox="0 0 16 16">
          <path fill-rule="evenodd"
            d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2Z" />
        </svg>Tambahkan Jam Khusus
      </button>
    </div>
  </div>
  <div v-if="listJamKhusus.length" class="accordion shadow rounded-bottom">
    <div class="accordion-item" v-for="djk, index in listJamKhusus" :key="djk">
      <h6 class="accordion-header">
        <button class="accordion-button collapsed py-2" type="button" data-bs-toggle="collapse"
          :data-bs-target="'#djk' + djk.jam_khusus_id" aria-expanded="false" :aria-controls="djk.jam_khusus_id">
          <div class="d-flex w-100 me-3 justify-content-between">
            {{ djk.ket }}
            <div class="hstack gap-1 me-1">
              <button class="btn btn-sm btn-outline-primary d-flex" @click="editData(djk)" data-bs-toggle="modal"
                data-bs-target="#editData">
                <svg xmlns="http://www.w3.org/2000/svg" width="13" fill="currentColor" class="bi bi-pencil-square"
                  viewBox="0 0 16 16">
                  <path
                    d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                  <path fill-rule="evenodd"
                    d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z" />
                </svg>
              </button>
              <div class="vr"></div>
              <button class="btn btn-sm btn-outline-danger d-flex" @click="confirmDelete(djk)">
                <svg xmlns="http://www.w3.org/2000/svg" width="13" fill="currentColor" class="bi bi-trash-fill"
                  viewBox="0 0 16 16">
                  <path
                    d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1H2.5zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5zM8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5zm3 .5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 1 0z" />
                </svg>
              </button>
            </div>
          </div>
        </button>
      </h6>
      <div :id="'djk' + djk.jam_khusus_id" class="accordion-collapse collapse">
        <div class="accordion-body bg-light x-5" :class="{ 'rounded-bottom': index == listJamKhusus.length - 1 }">
          <table class="table table-sm table-striped table-hover mb-0">
            <thead class="table-secondary align-middle">
              <tr>
                <th>Jam Ke</th>
                <th>Mulai</th>
                <th>Selesai</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="d in djk.jamKhusus" :key="d">
                <td>{{ d.jam_id }}</td>
                <td>{{ d.mulai }}</td>
                <td>{{ d.selesai }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
  <div v-else class="bg-light py-5">
    <h4 class="text-muted m-0 text-center">Data tidak ditemukan.</h4>
  </div>
  <div class="modal fade" id="tambahData" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
    aria-hidden="true">
    <div class="modal-dialog modal-lg modal-dialog-centered">
      <div class="modal-content vl-parent" ref="formCreate">
        <form @submit.prevent="simpanData">
          <div class="modal-header">
            <legend class="modal-title">Tambah Data Jam Pembelajaran Khusus</legend>
            <button type="button" id="tutupFormCreate" class="btn-close" data-bs-dismiss="modal"></button>
          </div>
          <div class="modal-body">
            <div class="row mb-3">
              <label class="col-sm-3 col-form-label col-form-label-sm">Keterangan</label>
              <div class="col-sm-9">
                <input type="text" class="form-control form-control-sm" placeholder="Keterangan" v-model="formCreate.ket"
                  required>
              </div>
            </div>
            <table class="table table-sm">
              <thead class="table-secondary align-middle">
                <tr>
                  <th>Jam Ke</th>
                  <th>Mulai</th>
                  <th>Selesai</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="d in formCreate.jamKhusus" :key="d">
                  <td class="align-middle">{{ d.jam_id }}</td>
                  <td>
                    <input type="time" class="form-control form-control-sm" placeholder="Waktu Mulai" v-model="d.mulai"
                      required>
                  </td>
                  <td>
                    <input type="time" class="form-control form-control-sm" placeholder="Waktu Selesai"
                      v-model="d.selesai" required>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="modal-footer">
            <button type="submit" class="btn btn-primary">Simpan</button>
          </div>
        </form>
      </div>
    </div>
  </div>
  <div class="modal fade" id="editData" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
    aria-hidden="true">
    <div class="modal-dialog modal-lg modal-dialog-centered">
      <div class="modal-content vl-parent" ref="formEdit">
        <form @submit.prevent="updateData">
          <div class="modal-header">
            <legend class="modal-title">Edit Data Jam Pembelajaran Khusus</legend>
            <button type="button" id="tutupFormEdit" class="btn-close" data-bs-dismiss="modal"></button>
          </div>
          <div class="modal-body">
            <div class="row mb-3">
              <label class="col-sm-3 col-form-label col-form-label-sm">Keterangan</label>
              <div class="col-sm-9">
                <input type="text" class="form-control form-control-sm" placeholder="Keterangan" v-model="formEdit.ket"
                  required>
              </div>
            </div>
            <table class="table table-sm">
              <thead class="table-secondary align-middle">
                <tr>
                  <th>Jam Ke</th>
                  <th>Mulai</th>
                  <th>Selesai</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="d in formEdit.jamKhusus" :key="d">
                  <td class="align-middle">{{ d.jam_id }}</td>
                  <td>
                    <input type="time" class="form-control form-control-sm" placeholder="Waktu Mulai" v-model="d.mulai"
                      required>
                  </td>
                  <td>
                    <input type="time" class="form-control form-control-sm" placeholder="Waktu Selesai"
                      v-model="d.selesai" required>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="modal-footer">
            <button type="submit" class="btn btn-primary">Perbarui</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import WebSocketService from './WebSocketService';
export default {
  name: 'DataJam',
  data() {
    return {
      insertOrDeleteListener: (d) => {
        if (d == 'jam') {
          this.getDataJam();
          this.getDataJamKhusus();
        }
      },
      updateListener: (d) => {
        if (d == 'jam' || d == 'statusJam') {
          this.getDataJam();
          this.getDataJamKhusus();
        }
      },
      dataJam: [],
      dataJamKhusus: [],
      listJamKhusus: [],
      formCreate: {
        ket: '',
        jamKhusus: []
      },
      selectedData: {},
      formEdit: {},
    }
  },
  mounted() {
    WebSocketService.on("inserted-or-deleted-data", this.insertOrDeleteListener);
    WebSocketService.on("update-data", this.updateListener);
    this.getDataJam();
    this.getDataJamKhusus();
  },
  beforeUnmount() {
    WebSocketService.off('inserted-or-deleted-data', this.insertOrDeleteListener);
    WebSocketService.off("update-data", this.updateListener);
  },
  computed: {
  },
  watch: {
  },
  methods: {
    async getDataJamKhusus() {
      const headers = {
        Authorization: localStorage.getItem('token')
      };
      try {
        const response = await this.axios.post('/jam/khusus', {}, {
          headers: headers
        });
        if (response.data) {
          this.dataJamKhusus = response.data.jam;
          this.listJamKhusus = Array.from(new Set(this.dataJamKhusus.map(d => d.jam_khusus_id))).map(
            id => {
              return {
                jam_khusus_id: id,
                ket: this.dataJamKhusus.find(jk => jk.jam_khusus_id === id).ket,
                jamKhusus: this.dataJamKhusus.filter(dj => dj.jam_khusus_id === id).map(
                  f => {
                    return {
                      jam_id: f.jam_id,
                      mulai: f.mulai,
                      selesai: f.selesai
                    }
                  }
                )
              }
            }
          )
        }
      }
      catch (err) {
        if (err.response) {
          if (err.response.status == 401) {
            this.$swal({
              title: 'Gagal',
              text: 'Sesi berakhir. Silahkan login ulang.',
              icon: 'error',
              confirmButtonText: 'Baik'
            }).then(() => {
              this.$router.go();
            });
          } else {
            console.log(err);
          }
        } else {
          console.log(err)
        }
      }
    },
    async getDataJam() {
      const headers = {
        Authorization: localStorage.getItem('token')
      };
      try {
        const response = await this.axios.post('/jam/aktif', {}, {
          headers: headers
        });
        if (response.data) {
          this.dataJam = response.data.jam;
          this.formCreate.jamKhusus = this.dataJam;
        }
      }
      catch (err) {
        if (err.response) {
          if (err.response.status == 401) {
            this.$swal({
              title: 'Gagal',
              text: 'Sesi berakhir. Silahkan login ulang.',
              icon: 'error',
              confirmButtonText: 'Baik'
            }).then(() => {
              this.$router.go();
            });
          } else {
            console.log(err);
          }
        } else {
          console.log(err)
        }
      }
    },
    async simpanData() {
      let memuat = this.$loading.show({
        container: this.$refs.formCreate,
        loader: 'spinner'
      });
      const headers = {
        Authorization: localStorage.getItem('token')
      };
      try {
        const response = await this.axios.post('/jam/khusus/tambah', this.formCreate, {
          headers: headers
        });
        if (response.data) {
          document.getElementById('tutupFormCreate').click();
          this.$swal({
            title: 'Sukses',
            text: response.data.message,
            icon: 'success',
            showConfirmButton: false,
            timer: 1500
          }).then(() => {
            this.formCreate = {
              ket: '',
              jamKhusus: this.dataJam
            };
          });
          this.getDataJamKhusus();
        }
      }
      catch (err) {
        if (err.response) {
          if (err.response.status == 401) {
            this.$swal({
              title: 'Gagal',
              text: 'Sesi berakhir. Silahkan login ulang.',
              icon: 'error',
              confirmButtonText: 'Baik'
            }).then(() => {
              this.$router.go();
            });
          } else if (err.response.status == 403) {
            this.$swal({
              title: 'Gagal',
              text: 'Anda tidak memiliki akses untuk menambahkan data.',
              icon: 'error',
              confirmButtonText: 'Baik'
            });
          } else {
            this.$swal({
              title: 'Gagal',
              html: `<p class="text-danger">${err.response.data.error}</p>
                <code class="text-secondary text-bg-secondary bg-opacity-10">code: ${err.response.data.code}<br/>
                  message: ${err.response.data.message}</code>`,
              icon: 'error',
              confirmButtonText: 'Baik'
            });
          }
        } else {
          console.log(err)
        }
      }
      setTimeout(() => {
        memuat.hide();
      }, 250);
    },
    editData(d) {
      this.selectedData = JSON.stringify(d);
      this.formEdit = JSON.parse(this.selectedData);
    },
    async updateData() {
      let memuat = this.$loading.show({
        container: this.$refs.formEdit,
        loader: 'spinner'
      });
      if (JSON.stringify(this.formEdit) === this.selectedData) {
        this.$swal({
          title: 'Perhatian',
          text: 'Anda belum melakukan perubahan',
          icon: 'warning',
          confirmButtonText: 'Baik'
        });
      } else {
        const headers = {
          Authorization: localStorage.getItem('token')
        };
        try {
          const response = await this.axios.post('/jam/khusus/perbarui', this.formEdit, { headers: headers });
          if (response.data) {
            document.getElementById('tutupFormEdit').click();
            this.$swal({
              title: 'Sukses',
              text: 'Berhasil memperbarui data Jam Pembelajaran Khusus',
              icon: 'success',
              showConfirmButton: false,
              timer: 1500
            }).then(() => {
              this.getDataJamKhusus();
            });
          } else {
            this.$swal({
              title: 'Gagal',
              text: 'Data tidak ditemukan',
              icon: 'error',
              confirmButtonText: 'Baik'
            });
          }
        }
        catch (err) {
          if (err.response) {
            if (err.response.status == 401) {
              this.$swal({
                title: 'Gagal',
                text: 'Sesi berakhir. Silahkan login ulang.',
                icon: 'error',
                confirmButtonText: 'Baik'
              }).then(() => {
                this.$router.go();
              });
            } else if (err.response.status == 403) {
              this.$swal({
                title: 'Gagal',
                text: 'Anda tidak memiliki akses untuk memperbarui data.',
                icon: 'error',
                confirmButtonText: 'Baik'
              });
            } else {
              this.$swal({
                title: 'Gagal',
                html: `<p class="text-danger">${err.response.data.error}</p>
                <code class="text-secondary text-bg-secondary bg-opacity-10">code: ${err.response.data.code}<br/>
                  message: ${err.response.data.message}</code>`,
                icon: 'error',
                confirmButtonText: 'Baik'
              });
            }
          } else {
            console.log(err)
          }
        }
      }
      setTimeout(() => {
        memuat.hide();
      }, 250);
    },
    confirmDelete(d) {
      this.$swal({
        title: 'Konfirmasi',
        html: `<span class="h5">Anda yakin ingin menghapus data Jam Pembelajaran Khusus <code class="text-black text-bg-secondary bg-opacity-50">${d.ket}</code>?</span><br/>
          <small class="fst-italic text-muted">Tindakan ini tidak dapat diurungkan</small>`,
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: 'Ya, Hapus',
        cancelButtonText: 'Batal',
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          this.hapusData(d);
        }
      });
    },
    async hapusData(d) {
      let memuat = this.$loading.show({
        opacity: 0,
        loader: 'spinner'
      });
      const headers = {
        Authorization: localStorage.getItem('token')
      };
      try {
        const response = await this.axios.post('/jam/khusus/hapus', {
          jam_khusus_id: d.jam_khusus_id
        }, {
          headers: headers
        });
        if (response.data) {
          this.$swal({
            title: 'Sukses',
            text: response.data.message,
            icon: 'success',
            showConfirmButton: false,
            timer: 1500
          }).then(() => {
            this.getDataJamKhusus();
          });
        } else {
          this.$swal({
            title: 'Gagal',
            text: 'Data tidak ditemukan.',
            icon: 'error',
            confirmButtonText: 'Baik'
          });
        }
      }
      catch (err) {
        if (err.response) {
          if (err.response.status == 401) {
            this.$swal({
              title: 'Gagal',
              text: 'Sesi berakhir. Silahkan login ulang.',
              icon: 'error',
              confirmButtonText: 'Baik'
            }).then(() => {
              this.$router.go();
            });
          } else if (err.response.status == 403) {
            this.$swal({
              title: 'Gagal',
              text: 'Anda tidak memiliki akses untuk menghapus data.',
              icon: 'error',
              confirmButtonText: 'Baik'
            });
          } else {
            this.$swal({
              title: 'Gagal',
              html: `<p class="text-danger">${err.response.data.error}</p>
                <code class="text-secondary text-bg-secondary bg-opacity-10">code: ${err.response.data.code}<br/>
                  message: ${err.response.data.message}</code>`,
              icon: 'error',
              confirmButtonText: 'Baik'
            });
          }
        } else {
          console.log(err)
        }
      }
      setTimeout(() => {
        memuat.hide();
      }, 250);
    },
  }
}
</script>

<style scoped></style>