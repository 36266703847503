<template>
  <div class="row row-cols-auto justify-content-between mb-2 mx-auto gap-2">
    <div class="col-auto p-0 d-inline-flex">
      <h5 class="my-auto">Mata Pelajaran dan Guru</h5>
    </div>
    <div class="col-auto p-0 ms-auto">
      <select class="form-select form-select-sm shadow-sm" v-model="semesterTerpilih">
        <option disabled value="">-- Pilih Semester --</option>
        <option v-for="s in listSemester" :key="s" :value="s">{{ s.nama }}</option>
      </select>
    </div>
  </div>
  <div class="col-md-10 col-lg-8 col-xl-6 mx-auto mb-3">
    <div class="card shadow vl-parent" ref="formCreate">
      <form @submit.prevent="simpanData">
        <div class="card-header">
          <ul class="nav nav-tabs card-header-tabs">
            <li class="nav-item">
              <button type="button" class="nav-link" :aria-current="berdasarkan == 'mapel'"
                :class="{ active: berdasarkan == 'mapel' }" @click="berdasarkan = 'mapel'">Mapel</button>
            </li>
            <li class="nav-item">
              <button type="button" class="nav-link" :aria-current="berdasarkan == 'guru'"
                :class="{ active: berdasarkan == 'guru' }" @click="berdasarkan = 'guru'">Guru</button>
            </li>
          </ul>
        </div>
        <div class="card-body" v-if="berdasarkan == 'mapel'">
          <div class="row mb-1">
            <label class="col-sm-4 col-form-label col-form-label-sm">Mata Pelajaran</label>
            <div class="col-sm-8">
              <v-select v-model="mapelTerpilih" placeholder="Pilih Mata Pelajaran" label="tingkat_jurusan_nama"
                :options="listMapel">
                <template #search="{ attributes, events }">
                  <input class="vs__search" :required="!mapelTerpilih" v-bind="attributes" v-on="events" />
                </template>
                <template #no-options>
                  Mata Pelajaran tidak ditemukan.
                </template>
              </v-select>
            </div>
          </div>
          <div class="row">
            <label class="col-sm-4 col-form-label col-form-label-sm">Guru Pengampu</label>
            <div class="col-sm-8">
              <v-select v-model="listGuruTerpilih" multiple placeholder="Pilih Guru Pengampu" label="nama"
                :options="listGuruTersedia" :clearSearchOnBlur="function () { return true }" :closeOnSelect="false">
                <template #search="{ attributes, events }">
                  <input class="vs__search" :required="!listGuruTerpilih.length" v-bind="attributes" v-on="events" />
                </template>
                <template #no-options>
                  Guru tidak ditemukan.
                </template>
              </v-select>
            </div>
          </div>
        </div>
        <div class="card-body" v-else>
          <div class="row mb-1">
            <label class="col-sm-4 col-form-label col-form-label-sm">Guru</label>
            <div class="col-sm-8">
              <v-select v-model="guruTerpilih" placeholder="Pilih Guru" label="nama" :options="listGuru">
                <template #search="{ attributes, events }">
                  <input class="vs__search" :required="!guruTerpilih" v-bind="attributes" v-on="events" />
                </template>
                <template #no-options>
                  Guru tidak ditemukan.
                </template>
              </v-select>
            </div>
          </div>
          <div class="row">
            <label class="col-sm-4 col-form-label col-form-label-sm">Mapel Diampu</label>
            <div class="col-sm-8">
              <v-select v-model="listMapelTerpilih" multiple placeholder="Pilih Mata Pelajaran Diampu"
                label="tingkat_jurusan_nama" :options="listMapelTersedia" :clearSearchOnBlur="function () { return true }"
                :closeOnSelect="false">
                <template #search="{ attributes, events }">
                  <input class="vs__search" :required="!listMapelTerpilih.length" v-bind="attributes" v-on="events" />
                </template>
                <template #no-options>
                  Mata Pelajaran tidak ditemukan.
                </template>
              </v-select>
            </div>
          </div>
        </div>
        <div class="card-footer text-end">
          <button type="submit" class="btn btn-sm btn-primary">Simpan</button>
        </div>
      </form>
    </div>
  </div>
  <div v-if="berdasarkan == 'mapel' && !mapelTerpilih">
    <h6>Data Mata Pelajaran<br />
      Tahun Ajaran {{ semesterTerpilih.nama }}</h6>
    <div class="table-responsive shadow vl-parent" ref="table">
      <table class="table table-sm table-striped table-hover mb-0">
        <thead class="table-secondary align-middle">
          <tr>
            <th>#</th>
            <th>Tingkat</th>
            <th>Jurusan</th>
            <th class="position-sticky start-0">Nama</th>
            <th>Jumlah Jam Per Periode</th>
            <th>Jumlah Guru Pengampu</th>
          </tr>
        </thead>
        <tbody v-if="mapelKontrol.length">
          <tr v-for="d, index in mapelKontrol" :key="d">
            <td>{{ index+=1 + pageOffsetMapel }}</td>
            <td>{{ d.tingkat }}</td>
            <td>{{ d.kode_jurusan }}</td>
            <td class="position-sticky start-0">{{ d.nama }}</td>
            <td>{{ d.jumlah_jam }}</td>
            <td>
              <div class="d-flex justify-content-between">
                {{ d.jumlah_guru_pengampu || '-' }}
                <button class="btn btn-sm btn-outline-secondary border-0 d-flex" @click="mapelTerpilihInfo = d"
                  data-bs-toggle="modal" data-bs-target="#infoMapel">
                  <svg xmlns="http://www.w3.org/2000/svg" width="13" fill="currentColor" class="bi bi-info-lg"
                    viewBox="0 0 16 16">
                    <path
                      d="m9.708 6.075-3.024.379-.108.502.595.108c.387.093.464.232.38.619l-.975 4.577c-.255 1.183.14 1.74 1.067 1.74.72 0 1.554-.332 1.933-.789l.116-.549c-.263.232-.65.325-.905.325-.363 0-.494-.255-.402-.704l1.323-6.208Zm.091-2.755a1.32 1.32 0 1 1-2.64 0 1.32 1.32 0 0 1 2.64 0Z" />
                  </svg>
                </button>
              </div>
            </td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr>
            <td class="text-center fst-italic py-3" colspan="6">Data tidak ditemukan.</td>
          </tr>
        </tbody>
      </table>
    </div>
    <span v-if="mapelKontrol.length" class="small text-muted fst-italic">
      Menampilkan {{ pageOffsetMapel + 1 }}-{{ pageOffsetMapel + mapelKontrol.length }} dari total {{ countMapel }} data
    </span>
    <div v-if="mapelKontrol.length" class="row row-cols-auto justify-content-sm-between justify-content-center mt-2">
      <div class="row row-cols-auto">
        <label class="col-auto col-form-label-sm pe-1" for="numRows">Tampilkan:</label>
        <div class="col-auto ps-1">
          <select class="form-select form-select-sm shadow" v-model="pageLimitMapel" id="numRows">
            <option v-for="l in arrayLimit" :key="l" :value="l">
              {{ l }} baris
            </option>
          </select>
        </div>
      </div>
      <div class="col-auto">
        <ul class="pagination pagination-sm shadow mb-0">
          <li class="page-item">
            <button class="page-link" :class="{ disabled: pageCurrentMapel == 1 }"
              @click="pageCurrentMapel > 1 ? pageCurrentMapel-- : ''">&laquo;</button>
          </li>
          <li class="page-item" v-for="p in  pageCountMapel " :key="p">
            <button v-if="p == pageCurrentMapel" class="page-link active fw-bold">{{ p }}</button>
            <button v-else-if="p == 1 || p == pageCountMapel || Math.abs(p - pageCurrentMapel) == 1" class="page-link"
              @click="pageCurrentMapel = p">{{ p }}</button>
            <button v-else-if="(Math.abs(pageCurrentMapel - 1) < 4 && Math.abs(p - 1) < 5) ||
              (Math.abs(pageCurrentMapel - pageCountMapel) < 4 && Math.abs(p - pageCountMapel) < 5)" class="page-link"
              @click="pageCurrentMapel = p">{{ p }}</button>
            <button
              v-else-if="(Math.abs(p - 1) == 5 || Math.abs(p - pageCountMapel) == 5) && (Math.abs(p - pageCountMapel) == 1 || Math.abs(p - 1) == 1)"
              class="page-link" @click="pageCurrentMapel = p">{{ p }}</button>
            <button v-else-if="Math.abs(p - pageCurrentMapel) == 2 ||
              (Math.abs(pageCurrentMapel - 1) < 4 && Math.abs(p - 1) == 5) ||
              (Math.abs(pageCurrentMapel - pageCountMapel) < 4 && Math.abs(p - pageCountMapel) == 5)"
              class="page-link px-1" @click="pageCurrentMapel = p">...</button>
          </li>
          <li class="page-item">
            <button class="page-link" :class="{ disabled: pageCurrentMapel == pageCountMapel }"
              @click="pageCurrentMapel < pageCountMapel ? pageCurrentMapel++ : ''">&raquo;</button>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <div v-else-if="berdasarkan == 'mapel' && mapelTerpilih">
    <h6>Data Guru Pengampu {{ mapelTerpilih.nama }} kelas {{ mapelTerpilih.tingkat }}<br />
      Tahun Ajaran {{
        semesterTerpilih.nama }}</h6>
    <div v-if="guruTercentang.listMapelDiampuId.length" class="mb-2 text-end">
      <button class="btn btn-sm btn-danger shadow-sm" @click="confirmDeleteSelected">Hapus data terpilih</button>
    </div>
    <div class="table-responsive shadow">
      <table class="table table-sm table-striped mb-0">
        <thead class="table-secondary align-middle">
          <tr>
            <th>Kode Guru</th>
            <th>Nama</th>
            <th>Jumlah Jam Terjadwal</th>
            <th>
              <div class="d-flex justify-content-between">
                Tindakan
                <input class="form-check-input" type="checkbox" v-model="semuaGuruTercentang" @click="centangSemuaGuru">
              </div>
            </th>
          </tr>
        </thead>
        <tbody v-if="guruPengampu.length">
          <tr v-for="d in guruPengampu" :key="d">
            <td>{{ d.guru_id }}</td>
            <td>{{ d.nama }}</td>
            <td>{{ d.jumlah_jam_terjadwal || '-' }}</td>
            <td>
              <div class="d-flex justify-content-between">
                <div class="hstack gap-1 me-1">
                  <button class="btn btn-sm btn-outline-primary d-flex" @click="selectedData = d" data-bs-toggle="modal"
                    data-bs-target="#editGuru">
                    <svg xmlns="http://www.w3.org/2000/svg" width="13" fill="currentColor" class="bi bi-pencil-square"
                      viewBox="0 0 16 16">
                      <path
                        d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                      <path fill-rule="evenodd"
                        d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z" />
                    </svg>
                  </button>
                  <div class="vr"></div>
                  <button class="btn btn-sm btn-outline-danger d-flex" @click="confirmDelete(d)">
                    <svg xmlns="http://www.w3.org/2000/svg" width="13" fill="currentColor" class="bi bi-trash-fill"
                      viewBox="0 0 16 16">
                      <path
                        d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1H2.5zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5zM8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5zm3 .5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 1 0z" />
                    </svg>
                  </button>
                </div>
                <input class="form-check-input" type="checkbox" v-model="d.centang" @click="centangSatuGuru(d)">
              </div>
            </td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr>
            <td class="text-center fst-italic py-3" colspan="4">Data tidak ditemukan.</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <div v-else-if="berdasarkan == 'guru' && !guruTerpilih">
    <h6>Data Guru Pengampu<br />
      Tahun Ajaran {{ semesterTerpilih.nama }}</h6>
    <div class="table-responsive shadow vl-parent" ref="table">
      <table class="table table-sm table-striped table-hover mb-0">
        <thead class="table-secondary align-middle">
          <tr>
            <th>#</th>
            <th>Kode Guru</th>
            <th class="position-sticky start-0">Nama</th>
            <th>Jumlah Mapel Diampu</th>
            <th>Jumlah Jam Terjadwal</th>
          </tr>
        </thead>
        <tbody v-if="guruKontrol.length">
          <tr v-for="d, index in guruKontrol" :key="d">
            <td>{{ index+=1 + pageOffsetGuru }}</td>
            <td>{{ d.guru_id }}</td>
            <td class="position-sticky start-0">{{ d.nama }}</td>
            <td>{{ d.jumlah_mapel_diampu || '-' }}</td>
            <td>
              <div class="d-flex justify-content-between">
                {{ d.jumlah_jam || '-' }} / {{ d.total_jam }}
                <button class="btn btn-sm btn-outline-secondary border-0 d-flex" @click="guruTerpilihInfo = d"
                  data-bs-toggle="modal" data-bs-target="#infoGuru">
                  <svg xmlns="http://www.w3.org/2000/svg" width="13" fill="currentColor" class="bi bi-info-lg"
                    viewBox="0 0 16 16">
                    <path
                      d="m9.708 6.075-3.024.379-.108.502.595.108c.387.093.464.232.38.619l-.975 4.577c-.255 1.183.14 1.74 1.067 1.74.72 0 1.554-.332 1.933-.789l.116-.549c-.263.232-.65.325-.905.325-.363 0-.494-.255-.402-.704l1.323-6.208Zm.091-2.755a1.32 1.32 0 1 1-2.64 0 1.32 1.32 0 0 1 2.64 0Z" />
                  </svg>
                </button>
              </div>
            </td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr>
            <td class="text-center fst-italic py-3" colspan="6">Data tidak ditemukan.</td>
          </tr>
        </tbody>
      </table>
    </div>
    <span v-if="guruKontrol.length" class="small text-muted fst-italic">
      Menampilkan {{ pageOffsetGuru + 1 }}-{{ pageOffsetGuru + guruKontrol.length }} dari total {{ countGuru }} data
    </span>
    <div v-if="guruKontrol.length" class="row row-cols-auto justify-content-sm-between justify-content-center mt-2">
      <div class="row row-cols-auto">
        <label class="col-auto col-form-label-sm pe-1" for="numRows">Tampilkan:</label>
        <div class="col-auto ps-1">
          <select class="form-select form-select-sm shadow" v-model="pageLimitGuru" id="numRows">
            <option v-for="l in arrayLimit" :key="l" :value="l">
              {{ l }} baris
            </option>
          </select>
        </div>
      </div>
      <div class="col-auto">
        <ul class="pagination pagination-sm shadow mb-0">
          <li class="page-item">
            <button class="page-link" :class="{ disabled: pageCurrentGuru == 1 }"
              @click="pageCurrentGuru > 1 ? pageCurrentGuru-- : ''">&laquo;</button>
          </li>
          <li class="page-item" v-for="p in  pageCountGuru " :key="p">
            <button v-if="p == pageCurrentGuru" class="page-link active fw-bold">{{ p }}</button>
            <button v-else-if="p == 1 || p == pageCountGuru || Math.abs(p - pageCurrentGuru) == 1" class="page-link"
              @click="pageCurrentGuru = p">{{ p }}</button>
            <button v-else-if="(Math.abs(pageCurrentGuru - 1) < 4 && Math.abs(p - 1) < 5) ||
              (Math.abs(pageCurrentGuru - pageCountGuru) < 4 && Math.abs(p - pageCountGuru) < 5)" class="page-link"
              @click="pageCurrentGuru = p">{{ p }}</button>
            <button
              v-else-if="(Math.abs(p - 1) == 5 || Math.abs(p - pageCountGuru) == 5) && (Math.abs(p - pageCountGuru) == 1 || Math.abs(p - 1) == 1)"
              class="page-link" @click="pageCurrentGuru = p">{{ p }}</button>
            <button v-else-if="Math.abs(p - pageCurrentGuru) == 2 ||
              (Math.abs(pageCurrentGuru - 1) < 4 && Math.abs(p - 1) == 5) ||
              (Math.abs(pageCurrentGuru - pageCountGuru) < 4 && Math.abs(p - pageCountGuru) == 5)"
              class="page-link px-1" @click="pageCurrentGuru = p">...</button>
          </li>
          <li class="page-item">
            <button class="page-link" :class="{ disabled: pageCurrentGuru == pageCountGuru }"
              @click="pageCurrentGuru < pageCountGuru ? pageCurrentGuru++ : ''">&raquo;</button>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <div v-else-if="berdasarkan == 'guru' && guruTerpilih">
    <h6>Data Mata Pelajaran Diampu {{ guruTerpilih.nama }}<br />
      Tahun Ajaran {{ semesterTerpilih.nama }}</h6>
    <div v-if="mapelTercentang.listMapelDiampuId.length" class="mb-2 text-end">
      <button class="btn btn-sm btn-danger shadow-sm" @click="confirmDeleteSelected">Hapus data terpilih</button>
    </div>
    <div class="table-responsive shadow">
      <table class="table table-sm table-striped mb-0">
        <thead class="table-secondary align-middle">
          <tr>
            <th>Tingkat</th>
            <th>Jurusan</th>
            <th>Nama</th>
            <th>Jumlah Jam Terjadwal</th>
            <th>
              <div class="d-flex justify-content-between">
                Tindakan
                <input class="form-check-input" type="checkbox" v-model="semuaMapelTercentang" @click="centangSemuaMapel">
              </div>
            </th>
          </tr>
        </thead>
        <tbody v-if="mapelDiampu.length">
          <tr v-for="d in mapelDiampu" :key="d">
            <td>{{ d.tingkat || '-' }}</td>
            <td>{{ d.kode_jurusan || '-' }}</td>
            <td>{{ d.nama }}</td>
            <td>{{ d.jumlah_jam_terjadwal || '-' }}</td>
            <td v-if="d.mapel_diampu_id">
              <div class="d-flex justify-content-between">
                <div class="hstack gap-1 me-1">
                  <button class="btn btn-sm btn-outline-primary d-flex" @click="selectedData = d" data-bs-toggle="modal"
                    data-bs-target="#editMapel">
                    <svg xmlns="http://www.w3.org/2000/svg" width="13" fill="currentColor" class="bi bi-pencil-square"
                      viewBox="0 0 16 16">
                      <path
                        d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                      <path fill-rule="evenodd"
                        d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z" />
                    </svg>
                  </button>
                  <div class="vr"></div>
                  <button class="btn btn-sm btn-outline-danger d-flex" @click="confirmDelete(d)">
                    <svg xmlns="http://www.w3.org/2000/svg" width="13" fill="currentColor" class="bi bi-trash-fill"
                      viewBox="0 0 16 16">
                      <path
                        d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1H2.5zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5zM8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5zm3 .5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 1 0z" />
                    </svg>
                  </button>
                </div>
                <input class="form-check-input" type="checkbox" v-model="d.centang" @click="centangSatuMapel(d)">
              </div>
            </td>
            <td v-else>-</td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr>
            <td class="text-center fst-italic py-3" colspan="5">Data tidak ditemukan.</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <div class="modal fade" id="infoMapel" tabindex="-1" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Guru Pengampu {{ mapelTerpilihInfo.nama }} kelas {{ mapelTerpilihInfo.tingkat }}</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal"></button>
        </div>
        <div class="modal-body">
          <table class="table table-sm table-striped">
            <thead class="table-secondary align-middle">
              <tr>
                <th>Kode Guru</th>
                <th>Nama</th>
                <th>Jumlah Jam Terjadwal</th>
              </tr>
            </thead>
            <tbody class="small">
              <tr v-for="d in guruPengampu" :key="d">
                <td>{{ d.guru_id }}</td>
                <td>{{ d.nama }}</td>
                <td>{{ d.jumlah_jam_terjadwal || '-' }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
  <div class="modal fade" id="infoGuru" tabindex="-1" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Guru Pengampu {{ guruTerpilihInfo.nama }} kelas {{ guruTerpilihInfo.tingkat }}</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal"></button>
        </div>
        <div class="modal-body">
          <table class="table table-sm table-striped">
            <thead class="table-secondary align-middle">
              <tr>
                <th>Tingkat</th>
                <th>Jurusan</th>
                <th>Nama</th>
                <th>Jumlah Jam Terjadwal</th>
              </tr>
            </thead>
            <tbody class="small">
              <tr v-for="d in mapelDiampu" :key="d">
                <td>{{ d.tingkat || '-' }}</td>
                <td>{{ d.kode_jurusan || '-' }}</td>
                <td>{{ d.nama }}</td>
                <td>{{ d.jumlah_jam_terjadwal || '-' }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
  <div class="modal fade" id="editGuru" tabindex="-1" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <form @submit.prevent="updateData">
          <div class="modal-header">
            <h5 class="modal-title">Edit Guru Pengampu {{ mapelTerpilih ? mapelTerpilih.nama : '' }} kelas {{
              mapelTerpilih ? mapelTerpilih.tingkat : '' }}
            </h5>
            <button type="button" id="tutupEditGuru" class="btn-close" data-bs-dismiss="modal"></button>
          </div>
          <div class="modal-body">
            <div class="row">
              <label class="col-sm-4 col-form-label">Guru Pengampu</label>
              <div class="col-sm-8">
                <v-select v-model="editGuruPengampu" :placeholder="selectedData.nama" label="nama"
                  :options="listGuruTersedia">
                  <template #search="{ attributes, events }">
                    <input class="vs__search" :required="!editGuruPengampu" v-bind="attributes" v-on="events" />
                  </template>
                  <template #no-options>
                    Guru tidak ditemukan.
                  </template>
                </v-select>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="submit" class="btn btn-primary">Perbarui</button>
          </div>
        </form>
      </div>
    </div>
  </div>
  <div class="modal fade" id="editMapel" tabindex="-1" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <form @submit.prevent="updateData">
          <div class="modal-header">
            <h5 class="modal-title">Edit Mapel Diampu {{ guruTerpilih ? guruTerpilih.nama : '' }}</h5>
            <button type="button" id="tutupEditMapel" class="btn-close" data-bs-dismiss="modal"></button>
          </div>
          <div class="modal-body">
            <div class="row">
              <label class="col-sm-4 col-form-label">Mata Pelajaran</label>
              <div class="col-sm-8">
                <v-select v-model="editMapelDiampu"
                  :placeholder="selectedData.tingkat + '-' + selectedData.kode_jurusan + '-' + selectedData.nama"
                  label="tingkat_jurusan_nama" :options="listMapelTersedia">
                  <template #search="{ attributes, events }">
                    <input class="vs__search" :required="!editMapelDiampu" v-bind="attributes" v-on="events" />
                  </template>
                  <template #no-options>
                    Mata Pelajaran tidak ditemukan.
                  </template>
                </v-select>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="submit" class="btn btn-primary">Perbarui</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import WebSocketService from './WebSocketService';
export default {
  name: 'MapelDiampu',
  data() {
    return {
      insertOrDeleteListener: (d) => {
        const data = JSON.parse(d);
        if (data.semester_id == this.semesterTerpilih.semester_id) {
          if (this.pageCurrentMapel == 1) {
            this.getMapelKontrol();
          } else {
            this.pageCurrentMapel = 1;
          }
          if (this.pageCurrentGuru == 1) {
            this.getGuruKontrol();
          } else {
            this.pageCurrentGuru = 1;
          }
          if (data.mapel_id) {
            if (Object(this.mapelTerpilih).mapel_id == data.mapel_id) {
              this.listGuruTerpilih = [];
              this.getListGuruTersedia();
              this.getGuruPengampu();
            }
            if (data.listGuruId && data.listGuruId.includes(Object(this.guruTerpilih).guru_id)) {
              this.listMapelTerpilih = [];
              this.getListMapelTersedia();
              this.getMapelDiampu();
            }
          }
          if (data.guru_id) {
            if (Object(this.guruTerpilih).guru_id == data.guru_id) {
              this.listMapelTerpilih = [];
              this.getListMapelTersedia();
              this.getMapelDiampu();
            }
            if (data.listMapelId && data.listMapelId.includes(Object(this.mapelTerpilih).mapel_id)) {
              this.listGuruTerpilih = [];
              this.getListGuruTersedia();
              this.getGuruPengampu();
            }
          }
        }
      },
      updateListener: (d) => {
        const data = JSON.parse(d);
        if (data.semester_id == this.semesterTerpilih.semester_id) {
          if (this.pageCurrentMapel == 1) {
            this.getMapelKontrol();
          } else {
            this.pageCurrentMapel = 1;
          }
          if (this.pageCurrentGuru == 1) {
            this.getGuruKontrol();
          } else {
            this.pageCurrentGuru = 1;
          }
          if (Object(this.mapelTerpilih).mapel_id == data.mapel_id || Object(this.mapelTerpilih).mapel_id == data.old_mapel_id) {
            this.listGuruTerpilih = [];
            this.getListGuruTersedia();
            this.getGuruPengampu();
          }
          if (Object(this.guruTerpilih).guru_id == data.guru_id || Object(this.guruTerpilih).guru_id == data.old_guru_id) {
            this.listMapelTerpilih = [];
            this.getListMapelTersedia();
            this.getMapelDiampu();
          }
        }
      },
      hariListener: (d) => {
        if (d == 'hari' || d == 'statusHari') {
          if (this.pageCurrentGuru == 1) {
            this.getGuruKontrol();
          } else {
            this.pageCurrentGuru = 1;
          }
        }
      },
      penjadwalanListener: (d) => {
        const data = JSON.parse(d);
        if (data.semester_id == this.semesterTerpilih.semester_id) {
          if (this.pageCurrentGuru == 1) {
            this.getGuruKontrol();
          } else {
            this.pageCurrentGuru = 1;
          }
          if (this.mapelTerpilih) {
            this.getGuruPengampu();
          }
          if (this.guruTerpilih) {
            this.getMapelDiampu();
          }
        }
      },
      mapelKontrol: [],
      guruKontrol: [],
      guruPengampu: [],
      mapelDiampu: [],
      listSemester: [],
      listMapel: [],
      listMapelTersedia: [],
      listGuru: [],
      listGuruTersedia: [],
      mapelTerpilih: "",
      listGuruTerpilih: [],
      guruTerpilih: "",
      listMapelTerpilih: [],
      arrayLimit: [10, 25, 50, 100, 250],
      countMapel: 0,
      pageCurrentMapel: 1,
      pageOffsetMapel: 0,
      pageLimitMapel: 10,
      pageCountMapel: 0,
      countGuru: 0,
      pageCurrentGuru: 1,
      pageOffsetGuru: 0,
      pageLimitGuru: 10,
      pageCountGuru: 0,
      mapelTerpilihInfo: "",
      guruTerpilihInfo: "",
      editGuruPengampu: "",
      editMapelDiampu: "",
      guruTercentang: {
        listMapelDiampuId: [],
        listGuruId: []
      },
      semuaGuruTercentang: false,
      mapelTercentang: {
        listMapelDiampuId: [],
        listMapelId: []
      },
      semuaMapelTercentang: false,
      semesterTerpilih: "",
      berdasarkan: "mapel",
      selectedData: "",
    }
  },
  mounted() {
    WebSocketService.on("inserted-or-deleted-mapel-diampu", this.insertOrDeleteListener);
    WebSocketService.on("update-mapel-diampu", this.updateListener);
    WebSocketService.on("inserted-or-deleted-data", this.hariListener);
    WebSocketService.on("update-data", this.hariListener);
    WebSocketService.on("inserted-or-deleted-jadwal", this.penjadwalanListener);
    WebSocketService.on("update-jadwal", this.penjadwalanListener);
    this.semesterTerpilih = {
      tahun_ajaran_id: this.tpAktif.tahun_ajaran_id,
      ...this.semesterAktif
    };
    this.pageLimitMapel = this.batasBaris;
    this.pageLimitGuru = this.batasBaris;
    this.getListSemester();
    this.$nextTick(() => {
      this.getListMapel();
      this.getListGuru();
      let memuat = this.$loading.show({
        container: this.$refs.table,
        loader: 'dots'
      });
      if (this.pageCurrentMapel == 1) {
        this.getMapelKontrol();
      } else {
        this.pageCurrentMapel = 1;
      }
      this.getGuruKontrol();
      setTimeout(() => {
        memuat.hide();
      }, 250);
    })
  },
  beforeUnmount() {
    WebSocketService.off('inserted-or-deleted-mapel-diampu', this.insertOrDeleteListener);
    WebSocketService.off("update-mapel-diampu", this.updateListener);
    WebSocketService.off("inserted-or-deleted-data", this.hariListener);
    WebSocketService.off("update-mapel-data", this.hariListener);
    WebSocketService.off("inserted-or-deleted-jadwal", this.penjadwalanListener);
    WebSocketService.off("update-jadwal", this.penjadwalanListener);
  },
  computed: {
    tpAktif() {
      return this.$store.getters.getTp
    },
    semesterAktif() {
      return this.$store.getters.getSemester
    },
    batasBaris() {
      return this.$store.getters.getDefaultLimit
    },
  },
  watch: {
    semesterAktif() {
      this.semesterTerpilih = {
        tahun_ajaran_id: this.tpAktif.tahun_ajaran_id,
        ...this.semesterAktif
      };
    },
    semesterTerpilih() {
      this.mapelTerpilih = "";
      this.guruTerpilih = "";
      this.getListMapel();
      if (this.pageCurrentMapel == 1) {
        this.getMapelKontrol();
      } else {
        this.pageCurrentMapel = 1;
      }
      if (this.pageCurrentGuru == 1) {
        this.getGuruKontrol();
      } else {
        this.pageCurrentGuru = 1;
      }
    },
    mapelTerpilih() {
      if (Object(this.mapelTerpilih).mapel_id) {
        this.getListGuruTersedia();
        this.getGuruPengampu();
      } else {
        this.listGuruTersedia = [];
        this.listGuruTerpilih = [];
      }
    },
    guruTerpilih() {
      if (Object(this.guruTerpilih).guru_id) {
        this.getListMapelTersedia();
        this.getMapelDiampu();
      } else {
        this.listMapelTersedia = [];
        this.listMapelTerpilih = [];
      }
    },
    mapelTerpilihInfo() {
      this.getGuruPengampu();
    },
    guruTerpilihInfo() {
      this.getMapelDiampu();
    },
    pageCurrentMapel() {
      this.pageOffsetMapel = (this.pageCurrentMapel - 1) * this.pageLimitMapel;
      this.getMapelKontrol();
    },
    pageLimitMapel() {
      if (this.pageCurrentMapel == 1) {
        this.getMapelKontrol();
      } else {
        this.pageCurrentMapel = 1;
      }
    },
    pageCurrentGuru() {
      this.pageOffsetGuru = (this.pageCurrentGuru - 1) * this.pageLimitGuru;
      this.getGuruKontrol();
    },
    pageLimitGuru() {
      if (this.pageCurrentGuru == 1) {
        this.getGuruKontrol();
      } else {
        this.pageCurrentGuru = 1;
      }
    },
  },
  methods: {
    async getListSemester() {
      const headers = {
        Authorization: localStorage.getItem('token')
      };
      try {
        const response = await this.axios.post('/penjadwalan/semester/list', {}, {
          headers: headers
        });
        if (response.data) {
          this.listSemester = response.data.semester;
        }
      }
      catch (err) {
        if (err.response) {
          if (err.response.status == 401) {
            this.$swal({
              title: 'Gagal',
              text: 'Sesi berakhir. Silahkan login ulang.',
              icon: 'error',
              confirmButtonText: 'Baik'
            }).then(() => {
              this.$router.go();
            });
          } else {
            console.log(err);
          }
        } else {
          console.log(err)
        }
      }
    },
    async getListMapel() {
      const headers = {
        Authorization: localStorage.getItem('token')
      };
      try {
        const response = await this.axios.post('/mapel/list', {
          tahun_ajaran_id: this.semesterTerpilih.tahun_ajaran_id
        }, {
          headers: headers
        });
        if (response.data) {
          this.listMapel = response.data.mapel;
        }
      }
      catch (err) {
        if (err.response) {
          if (err.response.status == 401) {
            this.$swal({
              title: 'Gagal',
              text: 'Sesi berakhir. Silahkan login ulang.',
              icon: 'error',
              confirmButtonText: 'Baik'
            }).then(() => {
              this.$router.go();
            });
          } else {
            console.log(err);
          }
        } else {
          console.log(err)
        }
      }
    },
    async getListGuru() {
      const headers = {
        Authorization: localStorage.getItem('token')
      };
      try {
        const response = await this.axios.post('/guru/aktif', {}, {
          headers: headers
        });
        if (response.data) {
          this.listGuru = response.data.guru;
        }
      }
      catch (err) {
        if (err.response) {
          if (err.response.status == 401) {
            this.$swal({
              title: 'Gagal',
              text: 'Sesi berakhir. Silahkan login ulang.',
              icon: 'error',
              confirmButtonText: 'Baik'
            }).then(() => {
              this.$router.go();
            });
          } else {
            console.log(err);
          }
        } else {
          console.log(err)
        }
      }
    },
    async getListMapelTersedia() {
      const headers = {
        Authorization: localStorage.getItem('token')
      };
      try {
        const response = await this.axios.post('/mapel/belum-diampu-guru', {
          tahun_ajaran_id: this.semesterTerpilih.tahun_ajaran_id,
          semester_id: this.semesterTerpilih.semester_id,
          guru_id: this.guruTerpilih.guru_id,
        }, {
          headers: headers
        });
        if (response.data) {
          this.listMapelTersedia = response.data.mapel;
        }
      }
      catch (err) {
        if (err.response) {
          if (err.response.status == 401) {
            this.$swal({
              title: 'Gagal',
              text: 'Sesi berakhir. Silahkan login ulang.',
              icon: 'error',
              confirmButtonText: 'Baik'
            }).then(() => {
              this.$router.go();
            });
          } else {
            console.log(err);
          }
        } else {
          console.log(err)
        }
      }
    },
    async getListGuruTersedia() {
      const headers = {
        Authorization: localStorage.getItem('token')
      };
      try {
        const response = await this.axios.post('/guru/belum-mengampu-mapel', {
          semester_id: this.semesterTerpilih.semester_id,
          mapel_id: this.mapelTerpilih.mapel_id,
        }, {
          headers: headers
        });
        if (response.data) {
          this.listGuruTersedia = response.data.guru;
        }
      }
      catch (err) {
        if (err.response) {
          if (err.response.status == 401) {
            this.$swal({
              title: 'Gagal',
              text: 'Sesi berakhir. Silahkan login ulang.',
              icon: 'error',
              confirmButtonText: 'Baik'
            }).then(() => {
              this.$router.go();
            });
          } else {
            console.log(err);
          }
        } else {
          console.log(err)
        }
      }
    },
    async getMapelKontrol() {
      const headers = {
        Authorization: localStorage.getItem('token')
      };
      try {
        const response = await this.axios.post('/mapel/kontrol', {
          tahun_ajaran_id: this.semesterTerpilih.tahun_ajaran_id,
          semester_id: this.semesterTerpilih.semester_id,
          limit: this.pageLimitMapel,
          offset: this.pageOffsetMapel,
        }, {
          headers: headers
        });
        if (response.data) {
          this.mapelKontrol = response.data.mapel;
          this.countMapel = response.data.count;
          this.pageCountMapel = Math.ceil(this.countMapel / this.pageLimitMapel);
        }
      }
      catch (err) {
        if (err.response) {
          if (err.response.status == 401) {
            this.$swal({
              title: 'Gagal',
              text: 'Sesi berakhir. Silahkan login ulang.',
              icon: 'error',
              confirmButtonText: 'Baik'
            }).then(() => {
              this.$router.go();
            });
          } else {
            console.log(err);
          }
        } else {
          console.log(err)
        }
      }
    },
    async getGuruKontrol() {
      const headers = {
        Authorization: localStorage.getItem('token')
      };
      try {
        const response = await this.axios.post('/guru/kontrol', {
          semester_id: this.semesterTerpilih.semester_id,
          limit: this.pageLimitGuru,
          offset: this.pageOffsetGuru
        }, {
          headers: headers
        });
        if (response.data) {
          this.guruKontrol = response.data.guru;
          this.countGuru = response.data.count;
          this.pageCountGuru = Math.ceil(this.countGuru / this.pageLimitGuru);
        }
      }
      catch (err) {
        if (err.response) {
          if (err.response.status == 401) {
            this.$swal({
              title: 'Gagal',
              text: 'Sesi berakhir. Silahkan login ulang.',
              icon: 'error',
              confirmButtonText: 'Baik'
            }).then(() => {
              this.$router.go();
            });
          } else {
            console.log(err);
          }
        } else {
          console.log(err)
        }
      }
    },
    async getGuruPengampu() {
      const headers = {
        Authorization: localStorage.getItem('token')
      };
      try {
        const response = await this.axios.post('/guru/mapel', {
          semester_id: this.semesterTerpilih.semester_id,
          mapel_id: this.mapelTerpilih ? this.mapelTerpilih.mapel_id : this.mapelTerpilihInfo.mapel_id
        }, {
          headers: headers
        });
        if (response.data) {
          this.guruPengampu = response.data.guru;
          this.guruTercentang = {
            listMapelDiampuId: [],
            listGuruId: []
          }
          this.semuaGuruTercentang = false;
        }
      }
      catch (err) {
        if (err.response) {
          if (err.response.status == 401) {
            this.$swal({
              title: 'Gagal',
              text: 'Sesi berakhir. Silahkan login ulang.',
              icon: 'error',
              confirmButtonText: 'Baik'
            }).then(() => {
              this.$router.go();
            });
          } else {
            console.log(err);
          }
        } else {
          console.log(err)
        }
      }
    },
    async getMapelDiampu() {
      const headers = {
        Authorization: localStorage.getItem('token')
      };
      try {
        const response = await this.axios.post('/mapel/kontrol', {
          tahun_ajaran_id: this.semesterTerpilih.tahun_ajaran_id,
          semester_id: this.semesterTerpilih.semester_id,
          guru_id: this.guruTerpilih ? this.guruTerpilih.guru_id : this.guruTerpilihInfo.guru_id
        }, {
          headers: headers
        });
        if (response.data) {
          this.mapelDiampu = response.data.mapel;
          this.mapelTercentang = {
            listMapelDiampuId: [],
            listMapelId: []
          }
          this.semuaMapelTercentang = false;
        }
      }
      catch (err) {
        if (err.response) {
          if (err.response.status == 401) {
            this.$swal({
              title: 'Gagal',
              text: 'Sesi berakhir. Silahkan login ulang.',
              icon: 'error',
              confirmButtonText: 'Baik'
            }).then(() => {
              this.$router.go();
            });
          } else {
            console.log(err);
          }
        } else {
          console.log(err)
        }
      }
    },
    async simpanData() {
      let memuat = this.$loading.show({
        container: this.$refs.formCreate,
        loader: 'spinner'
      });
      if (this.berdasarkan == 'mapel') {
        const listGuruId = this.listGuruTerpilih.map((g) => g.guru_id);
        const headers = {
          Authorization: localStorage.getItem('token')
        };
        const newData = {
          semester_id: this.semesterTerpilih.semester_id,
          listGuruId: listGuruId,
          mapel_id: this.mapelTerpilih.mapel_id
        }
        try {
          const response = await this.axios.post('/mapel-diampu/tambah', newData, {
            headers: headers
          });
          if (response.data) {
            this.$swal({
              title: 'Sukses',
              text: response.data.message,
              icon: 'success',
              showConfirmButton: false,
              timer: 1500
            })
          }
        }
        catch (err) {
          if (err.response) {
            if (err.response.status == 401) {
              this.$swal({
                title: 'Gagal',
                text: 'Sesi berakhir. Silahkan login ulang.',
                icon: 'error',
                confirmButtonText: 'Baik'
              }).then(() => {
                this.$router.go();
              });
            } else if (err.response.status == 403) {
              this.$swal({
                title: 'Gagal',
                text: 'Anda tidak memiliki akses untuk menambahkan data.',
                icon: 'error',
                confirmButtonText: 'Baik'
              });
            } else {
              this.$swal({
                title: 'Gagal',
                html: `<p class="text-danger">${err.response.data.error}</p>
                    <code class="text-secondary text-bg-secondary bg-opacity-10">code: ${err.response.data.code}<br/>
                      message: ${err.response.data.message}</code>`,
                icon: 'error',
                confirmButtonText: 'Baik'
              });
            }
          } else {
            console.log(err)
          }
        }
      } else {
        const listMapelId = this.listMapelTerpilih.map((m) => m.mapel_id)
        const headers = {
          Authorization: localStorage.getItem('token')
        };
        const newData = {
          semester_id: this.semesterTerpilih.semester_id,
          guru_id: this.guruTerpilih.guru_id,
          listMapelId: listMapelId
        }
        try {
          const response = await this.axios.post('/mapel-diampu/tambah', newData, {
            headers: headers
          });
          if (response.data) {
            this.$swal({
              title: 'Sukses',
              text: response.data.message,
              icon: 'success',
              showConfirmButton: false,
              timer: 1500
            })
          }
        }
        catch (err) {
          if (err.response) {
            if (err.response.status == 401) {
              this.$swal({
                title: 'Gagal',
                text: 'Sesi berakhir. Silahkan login ulang.',
                icon: 'error',
                confirmButtonText: 'Baik'
              }).then(() => {
                this.$router.go();
              });
            } else if (err.response.status == 403) {
              this.$swal({
                title: 'Gagal',
                text: 'Anda tidak memiliki akses untuk menambahkan data.',
                icon: 'error',
                confirmButtonText: 'Baik'
              });
            } else {
              this.$swal({
                title: 'Gagal',
                html: `<p class="text-danger">${err.response.data.error}</p>
                    <code class="text-secondary text-bg-secondary bg-opacity-10">code: ${err.response.data.code}<br/>
                      message: ${err.response.data.message}</code>`,
                icon: 'error',
                confirmButtonText: 'Baik'
              });
            }
          } else {
            console.log(err)
          }
        }
      }
      setTimeout(() => {
        memuat.hide();
      }, 250);
    },
    async updateData() {
      const headers = {
        Authorization: localStorage.getItem('token')
      };
      const newData = this.berdasarkan == 'mapel' ? {
        semester_id: this.semesterTerpilih.semester_id,
        mapel_diampu_id: this.selectedData.mapel_diampu_id,
        old_mapel_id: this.mapelTerpilih.mapel_id,
        mapel_id: this.mapelTerpilih.mapel_id,
        old_guru_id: this.selectedData.guru_id,
        guru_id: this.editGuruPengampu.guru_id,
      } : {
        semester_id: this.semesterTerpilih.semester_id,
        mapel_diampu_id: this.selectedData.mapel_diampu_id,
        old_mapel_id: this.selectedData.mapel_id,
        mapel_id: this.editMapelDiampu.mapel_id,
        old_guru_id: this.guruTerpilih.guru_id,
        guru_id: this.guruTerpilih.guru_id,
      }
      try {
        const response = await this.axios.post('/mapel-diampu/perbarui', newData, { headers: headers });
        if (response.data) {
          if (this.berdasarkan == 'mapel') {
            document.getElementById('tutupEditGuru').click();
            this.editGuruPengampu = "";
          } else {
            document.getElementById('tutupEditMapel').click();
            this.editMapelDiampu = "";
          }
          this.$swal({
            title: 'Sukses',
            text: this.berdasarkan == 'mapel' ? 'Berhasil memperbarui data guru pengampu' : 'Berhasil memperbarui data mapel diampu',
            icon: 'success',
            showConfirmButton: false,
            timer: 1500
          })
        } else {
          this.$swal({
            title: 'Gagal',
            text: 'Data tidak ditemukan',
            icon: 'error',
            confirmButtonText: 'Baik'
          });
        }
      }
      catch (err) {
        if (err.response) {
          if (err.response.status == 401) {
            this.$swal({
              title: 'Gagal',
              text: 'Sesi berakhir. Silahkan login ulang.',
              icon: 'error',
              confirmButtonText: 'Baik'
            }).then(() => {
              this.$router.go();
            });
          } else if (err.response.status == 403) {
            this.$swal({
              title: 'Gagal',
              text: 'Anda tidak memiliki akses untuk memperbarui data.',
              icon: 'error',
              confirmButtonText: 'Baik'
            });
          } else {
            this.$swal({
              title: 'Gagal',
              html: `<p class="text-danger">${err.response.data.error}</p>
                <code class="text-secondary text-bg-secondary bg-opacity-10">code: ${err.response.data.code}<br/>
                  message: ${err.response.data.message}</code>`,
              icon: 'error',
              confirmButtonText: 'Baik'
            });
          }
        } else {
          console.log(err)
        }
      }
    },
    confirmDelete(d) {
      const konfirmasi = this.berdasarkan == 'mapel' ?
        `<code class="text-black text-bg-secondary bg-opacity-50">${d.nama}</code>
          mengampu <code class="text-black text-bg-secondary bg-opacity-50">${this.mapelTerpilih.nama}</code>
          kelas <code class="text-black text-bg-secondary bg-opacity-50">${this.mapelTerpilih.tingkat}</code>?</span>` :
        `<code class="text-black text-bg-secondary bg-opacity-50">${this.guruTerpilih.nama}</code>
          mengampu <code class="text-black text-bg-secondary bg-opacity-50">${d.nama}</code>
          kelas <code class="text-black text-bg-secondary bg-opacity-50">${d.tingkat}</code>?</span>`;
      this.$swal({
        title: 'Konfirmasi',
        html: `<span class="h5">Anda yakin ingin menghapus ${konfirmasi}<br/>
          <small class="fst-italic text-muted">Tindakan ini tidak dapat diurungkan</small>`,
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: 'Ya, Hapus',
        cancelButtonText: 'Batal',
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          this.hapusData(d);
        }
      });
    },
    async hapusData(d) {
      const headers = {
        Authorization: localStorage.getItem('token')
      };
      try {
        const response = await this.axios.post('/mapel-diampu/hapus', {
          semester_id: this.semesterTerpilih.semester_id,
          mapel_diampu_id: d.mapel_diampu_id,
          guru_id: this.berdasarkan == 'mapel' ? d.guru_id : this.guruTerpilih.guru_id,
          mapel_id: this.berdasarkan == 'mapel' ? this.mapelTerpilih.mapel_id : d.mapel_id,
        }, {
          headers: headers
        });
        if (response.data) {
          this.$swal({
            title: 'Sukses',
            text: this.berdasarkan == 'mapel' ? 'Berhasil menghapus data guru pengampu' : 'Berhasil menghapus data mapel diampu',
            icon: 'success',
            showConfirmButton: false,
            timer: 1500
          })
        } else {
          this.$swal({
            title: 'Gagal',
            text: 'Data tidak ditemukan.',
            icon: 'error',
            confirmButtonText: 'Baik'
          });
        }
      }
      catch (err) {
        if (err.response) {
          if (err.response.status == 401) {
            this.$swal({
              title: 'Gagal',
              text: 'Sesi berakhir. Silahkan login ulang.',
              icon: 'error',
              confirmButtonText: 'Baik'
            }).then(() => {
              this.$router.go();
            });
          } else if (err.response.status == 403) {
            this.$swal({
              title: 'Gagal',
              text: 'Anda tidak memiliki akses untuk menghapus data.',
              icon: 'error',
              confirmButtonText: 'Baik'
            });
          } else {
            this.$swal({
              title: 'Gagal',
              html: `<p class="text-danger">${err.response.data.error}</p>
              <code class="text-secondary text-bg-secondary bg-opacity-10">code: ${err.response.data.code}<br/>
                message: ${err.response.data.message}</code>`,
              icon: 'error',
              confirmButtonText: 'Baik'
            });
          }
        } else {
          console.log(err)
        }
      }
    },
    centangSatuGuru(d) {
      const ditemukan = this.guruTercentang.listMapelDiampuId.findIndex((j) =>
        j == d.mapel_diampu_id
      );
      if (ditemukan >= 0) {
        this.guruTercentang.listMapelDiampuId.splice(ditemukan, 1);
        this.guruTercentang.listGuruId.splice(ditemukan, 1);
      } else {
        this.guruTercentang.listMapelDiampuId.push(d.mapel_diampu_id);
        this.guruTercentang.listGuruId.push(d.guru_id);
      }
      if (this.guruTercentang.listMapelDiampuId.length == this.guruPengampu.length) {
        this.semuaGuruTercentang = true;
      } else {
        this.semuaGuruTercentang = false;
      }
    },
    centangSemuaGuru() {
      this.guruTercentang = {
        listMapelDiampuId: [],
        listGuruId: []
      }
      if (this.semuaGuruTercentang) {
        for (const d of this.guruPengampu) {
          d.centang = false;
          this.guruTercentang = {
            listMapelDiampuId: [],
            listGuruId: []
          }
        }
      } else {
        for (const d of this.guruPengampu) {
          d.centang = true;
          this.guruTercentang.listMapelDiampuId.push(d.mapel_diampu_id);
          this.guruTercentang.listGuruId.push(d.guru_id);
        }
      }
    },
    centangSatuMapel(d) {
      const ditemukan = this.mapelTercentang.listMapelDiampuId.findIndex((j) =>
        j == d.mapel_diampu_id
      );
      if (ditemukan >= 0) {
        this.mapelTercentang.listMapelDiampuId.splice(ditemukan, 1);
        this.mapelTercentang.listMapelId.splice(ditemukan, 1);
      } else {
        this.mapelTercentang.listMapelDiampuId.push(d.mapel_diampu_id);
        this.mapelTercentang.listMapelId.push(d.mapel_id);
      }
      if (this.mapelTercentang.listMapelDiampuId.length == this.mapelDiampu.length) {
        this.semuaMapelTercentang = true;
      } else {
        this.semuaMapelTercentang = false;
      }
    },
    centangSemuaMapel() {
      this.mapelTercentang = {
        listMapelDiampuId: [],
        listMapelId: []
      }
      if (this.semuaMapelTercentang) {
        for (const d of this.mapelDiampu) {
          d.centang = false;
          this.mapelTercentang = {
            listMapelDiampuId: [],
            listMapelId: []
          }
        }
      } else {
        for (const d of this.mapelDiampu) {
          if (d.mapel_diampu_id) {
            d.centang = true;
            this.mapelTercentang.listMapelDiampuId.push(d.mapel_diampu_id);
            this.mapelTercentang.listMapelId.push(d.mapel_id);
          }
        }
      }
    },
    confirmDeleteSelected() {
      this.$swal({
        title: "Konfirmasi",
        html: `Anda yakin ingin menghapus data terpilih?<br/>
						&#9432; <em>Tindakan ini tidak dapat diurungkan.</em>`,
        icon: "question",
        showCancelButton: true,
        confirmButtonText: "Ya, Hapus",
        cancelButtonText: "Batal",
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          this.deleteSelected()
        }
      });
    },
    async deleteSelected() {
      const headers = {
        Authorization: localStorage.getItem('token')
      };
      const dataToDelete = this.berdasarkan == 'mapel' ? {
        ...this.guruTercentang,
        semester_id: this.semesterTerpilih.semester_id,
        mapel_id: this.mapelTerpilih.mapel_id,
      } : {
        ...this.mapelTercentang,
        semester_id: this.semesterTerpilih.semester_id,
        guru_id: this.guruTerpilih.guru_id,
      }
      try {
        const response = await this.axios.post("/mapel-diampu/hapus", dataToDelete, {
          headers: headers
        });
        if (response.data) {
          this.$swal({
            title: 'Sukses',
            text: response.data.message,
            icon: 'success',
            showConfirmButton: false,
            timer: 1500
          })
        } else {
          this.$swal({
            title: 'Gagal',
            text: 'Data tidak ditemukan.',
            icon: 'error',
            confirmButtonText: 'Baik'
          });
        }
      } catch (err) {
        if (err.response) {
          if (err.response.status == 401) {
            this.$swal({
              title: 'Gagal',
              text: 'Sesi berakhir. Silahkan login ulang.',
              icon: 'error',
              confirmButtonText: 'Baik'
            }).then(() => {
              this.$router.go();
            });
          } else if (err.response.status == 403) {
            this.$swal({
              title: 'Gagal',
              text: 'Anda tidak memiliki akses untuk menghapus data.',
              icon: 'error',
              confirmButtonText: 'Baik'
            });
          } else {
            this.$swal({
              title: 'Gagal',
              html: `<p class="text-danger">${err.response.data.error}</p>
                <code class="text-secondary text-bg-secondary bg-opacity-10">code: ${err.response.data.code}<br/>
                  message: ${err.response.data.message}</code>`,
              icon: 'error',
              confirmButtonText: 'Baik'
            });
          }
        } else {
          console.log(err)
        }
      }
    },
  }
}
</script>

<style scoped></style>