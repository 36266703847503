<template>
  <div class="row row-cols-auto justify-content-between mb-2 mx-auto gap-2">
    <div class="col-auto p-0 d-inline-flex">
      <h5 class="my-auto">Kalender Akademik</h5>
    </div>
    <div class="col-auto p-0">
      <select class="form-select form-select-sm shadow-sm" v-model="tahunAjaranTerpilih">
        <option disabled value="">-- Pilih Tahun Ajaran --</option>
        <option v-for="t in tahunAjaran" :key="t" :value="t">{{ t.nama }}</option>
      </select>
    </div>
  </div>
  <div class="row row-cols-auto justify-content-between mb-2 mx-auto gap-2">
    <div class="col-auto p-0">
      <button type="button" class="btn btn-sm btn-outline-dark hstack gap-1 shadow-sm" data-bs-toggle="modal"
        data-bs-target="#unggahData">
        <svg xmlns="http://www.w3.org/2000/svg" height="16" fill="currentColor" class="bi bi-upload" viewBox="0 0 16 16">
          <path
            d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
          <path
            d="M7.646 1.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 2.707V11.5a.5.5 0 0 1-1 0V2.707L5.354 4.854a.5.5 0 1 1-.708-.708l3-3z" />
        </svg>Unggah Data Kalender (.csv)
      </button>
    </div>
    <div class="col-auto p-0 hstack gap-1 ms-auto">
      <button class="btn btn-sm btn-outline-danger shadow-sm" @click="confirmDeleteKalender">Hapus Data Kalender</button>
      <button class="btn btn-sm btn-outline-success shadow-sm" @click="unduhPDF">Unduh Kalender</button>
    </div>
  </div>
  <div class="row row-cols-auto justify-content-between mb-2 mx-auto gap-2">
    <div class="col-auto p-0">
      <download-csv class="btn btn-sm btn-outline-success hstack gap-1 shadow-sm" :data="templateKalender"
        :fields="['bulan', 'tanggal', 'hari_id', 'libur_id']" name="template_kalender.csv"><svg
          xmlns="http://www.w3.org/2000/svg" height="16" fill="currentColor" class="bi bi-filetype-csv"
          viewBox="0 0 16 16">
          <path fill-rule="evenodd"
            d="M14 4.5V14a2 2 0 0 1-2 2h-1v-1h1a1 1 0 0 0 1-1V4.5h-2A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v9H2V2a2 2 0 0 1 2-2h5.5L14 4.5ZM3.517 14.841a1.13 1.13 0 0 0 .401.823c.13.108.289.192.478.252.19.061.411.091.665.091.338 0 .624-.053.859-.158.236-.105.416-.252.539-.44.125-.189.187-.408.187-.656 0-.224-.045-.41-.134-.56a1.001 1.001 0 0 0-.375-.357 2.027 2.027 0 0 0-.566-.21l-.621-.144a.97.97 0 0 1-.404-.176.37.37 0 0 1-.144-.299c0-.156.062-.284.185-.384.125-.101.296-.152.512-.152.143 0 .266.023.37.068a.624.624 0 0 1 .246.181.56.56 0 0 1 .12.258h.75a1.092 1.092 0 0 0-.2-.566 1.21 1.21 0 0 0-.5-.41 1.813 1.813 0 0 0-.78-.152c-.293 0-.551.05-.776.15-.225.099-.4.24-.527.421-.127.182-.19.395-.19.639 0 .201.04.376.122.524.082.149.2.27.352.367.152.095.332.167.539.213l.618.144c.207.049.361.113.463.193a.387.387 0 0 1 .152.326.505.505 0 0 1-.085.29.559.559 0 0 1-.255.193c-.111.047-.249.07-.413.07-.117 0-.223-.013-.32-.04a.838.838 0 0 1-.248-.115.578.578 0 0 1-.255-.384h-.765ZM.806 13.693c0-.248.034-.46.102-.633a.868.868 0 0 1 .302-.399.814.814 0 0 1 .475-.137c.15 0 .283.032.398.097a.7.7 0 0 1 .272.26.85.85 0 0 1 .12.381h.765v-.072a1.33 1.33 0 0 0-.466-.964 1.441 1.441 0 0 0-.489-.272 1.838 1.838 0 0 0-.606-.097c-.356 0-.66.074-.911.223-.25.148-.44.359-.572.632-.13.274-.196.6-.196.979v.498c0 .379.064.704.193.976.131.271.322.48.572.626.25.145.554.217.914.217.293 0 .554-.055.785-.164.23-.11.414-.26.55-.454a1.27 1.27 0 0 0 .226-.674v-.076h-.764a.799.799 0 0 1-.118.363.7.7 0 0 1-.272.25.874.874 0 0 1-.401.087.845.845 0 0 1-.478-.132.833.833 0 0 1-.299-.392 1.699 1.699 0 0 1-.102-.627v-.495Zm8.239 2.238h-.953l-1.338-3.999h.917l.896 3.138h.038l.888-3.138h.879l-1.327 4Z" />
        </svg>Template Kalender
      </download-csv>
    </div>
    <div class="col-auto p-0 hstack gap-1 ms-auto">
      <download-csv class="btn btn-sm btn-outline-secondary hstack gap-1 shadow-sm" :data="listHari"
        :fields="['hari_id', 'nama', 'jumlah_jam']" name="ref_data_hari.csv"><svg xmlns="http://www.w3.org/2000/svg"
          height="16" fill="currentColor" class="bi bi-filetype-csv" viewBox="0 0 16 16">
          <path fill-rule="evenodd"
            d="M14 4.5V14a2 2 0 0 1-2 2h-1v-1h1a1 1 0 0 0 1-1V4.5h-2A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v9H2V2a2 2 0 0 1 2-2h5.5L14 4.5ZM3.517 14.841a1.13 1.13 0 0 0 .401.823c.13.108.289.192.478.252.19.061.411.091.665.091.338 0 .624-.053.859-.158.236-.105.416-.252.539-.44.125-.189.187-.408.187-.656 0-.224-.045-.41-.134-.56a1.001 1.001 0 0 0-.375-.357 2.027 2.027 0 0 0-.566-.21l-.621-.144a.97.97 0 0 1-.404-.176.37.37 0 0 1-.144-.299c0-.156.062-.284.185-.384.125-.101.296-.152.512-.152.143 0 .266.023.37.068a.624.624 0 0 1 .246.181.56.56 0 0 1 .12.258h.75a1.092 1.092 0 0 0-.2-.566 1.21 1.21 0 0 0-.5-.41 1.813 1.813 0 0 0-.78-.152c-.293 0-.551.05-.776.15-.225.099-.4.24-.527.421-.127.182-.19.395-.19.639 0 .201.04.376.122.524.082.149.2.27.352.367.152.095.332.167.539.213l.618.144c.207.049.361.113.463.193a.387.387 0 0 1 .152.326.505.505 0 0 1-.085.29.559.559 0 0 1-.255.193c-.111.047-.249.07-.413.07-.117 0-.223-.013-.32-.04a.838.838 0 0 1-.248-.115.578.578 0 0 1-.255-.384h-.765ZM.806 13.693c0-.248.034-.46.102-.633a.868.868 0 0 1 .302-.399.814.814 0 0 1 .475-.137c.15 0 .283.032.398.097a.7.7 0 0 1 .272.26.85.85 0 0 1 .12.381h.765v-.072a1.33 1.33 0 0 0-.466-.964 1.441 1.441 0 0 0-.489-.272 1.838 1.838 0 0 0-.606-.097c-.356 0-.66.074-.911.223-.25.148-.44.359-.572.632-.13.274-.196.6-.196.979v.498c0 .379.064.704.193.976.131.271.322.48.572.626.25.145.554.217.914.217.293 0 .554-.055.785-.164.23-.11.414-.26.55-.454a1.27 1.27 0 0 0 .226-.674v-.076h-.764a.799.799 0 0 1-.118.363.7.7 0 0 1-.272.25.874.874 0 0 1-.401.087.845.845 0 0 1-.478-.132.833.833 0 0 1-.299-.392 1.699 1.699 0 0 1-.102-.627v-.495Zm8.239 2.238h-.953l-1.338-3.999h.917l.896 3.138h.038l.888-3.138h.879l-1.327 4Z" />
        </svg>Referensi Hari
      </download-csv>
      <download-csv class="btn btn-sm btn-outline-secondary hstack gap-1 shadow-sm" :data="listLibur"
        :fields="['libur_id', 'kode', 'nama']" name="ref_data_non_kbm.csv"><svg xmlns="http://www.w3.org/2000/svg"
          height="16" fill="currentColor" class="bi bi-filetype-csv" viewBox="0 0 16 16">
          <path fill-rule="evenodd"
            d="M14 4.5V14a2 2 0 0 1-2 2h-1v-1h1a1 1 0 0 0 1-1V4.5h-2A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v9H2V2a2 2 0 0 1 2-2h5.5L14 4.5ZM3.517 14.841a1.13 1.13 0 0 0 .401.823c.13.108.289.192.478.252.19.061.411.091.665.091.338 0 .624-.053.859-.158.236-.105.416-.252.539-.44.125-.189.187-.408.187-.656 0-.224-.045-.41-.134-.56a1.001 1.001 0 0 0-.375-.357 2.027 2.027 0 0 0-.566-.21l-.621-.144a.97.97 0 0 1-.404-.176.37.37 0 0 1-.144-.299c0-.156.062-.284.185-.384.125-.101.296-.152.512-.152.143 0 .266.023.37.068a.624.624 0 0 1 .246.181.56.56 0 0 1 .12.258h.75a1.092 1.092 0 0 0-.2-.566 1.21 1.21 0 0 0-.5-.41 1.813 1.813 0 0 0-.78-.152c-.293 0-.551.05-.776.15-.225.099-.4.24-.527.421-.127.182-.19.395-.19.639 0 .201.04.376.122.524.082.149.2.27.352.367.152.095.332.167.539.213l.618.144c.207.049.361.113.463.193a.387.387 0 0 1 .152.326.505.505 0 0 1-.085.29.559.559 0 0 1-.255.193c-.111.047-.249.07-.413.07-.117 0-.223-.013-.32-.04a.838.838 0 0 1-.248-.115.578.578 0 0 1-.255-.384h-.765ZM.806 13.693c0-.248.034-.46.102-.633a.868.868 0 0 1 .302-.399.814.814 0 0 1 .475-.137c.15 0 .283.032.398.097a.7.7 0 0 1 .272.26.85.85 0 0 1 .12.381h.765v-.072a1.33 1.33 0 0 0-.466-.964 1.441 1.441 0 0 0-.489-.272 1.838 1.838 0 0 0-.606-.097c-.356 0-.66.074-.911.223-.25.148-.44.359-.572.632-.13.274-.196.6-.196.979v.498c0 .379.064.704.193.976.131.271.322.48.572.626.25.145.554.217.914.217.293 0 .554-.055.785-.164.23-.11.414-.26.55-.454a1.27 1.27 0 0 0 .226-.674v-.076h-.764a.799.799 0 0 1-.118.363.7.7 0 0 1-.272.25.874.874 0 0 1-.401.087.845.845 0 0 1-.478-.132.833.833 0 0 1-.299-.392 1.699 1.699 0 0 1-.102-.627v-.495Zm8.239 2.238h-.953l-1.338-3.999h.917l.896 3.138h.038l.888-3.138h.879l-1.327 4Z" />
        </svg>Referensi Jenis Non KBM
      </download-csv>
    </div>
  </div>
  <div class="table-responsive shadow vl-parent" ref="table">
    <table class="table table-sm table-striped table-bordered border-secondary table-hover mb-0 text-center"
      id="tabelKalender">
      <thead class="table-secondary table-bordered border-secondary align-middle">
        <tr>
          <th rowspan="2">#</th>
          <th class="position-sticky start-0" rowspan="2">Bulan</th>
          <th colspan="31">Tanggal</th>
        </tr>
        <tr>
          <th v-for="t in 31" :key="t">{{ t }}</th>
        </tr>
      </thead>
      <tbody class="table-bordered border-secondary align-middle">
        <tr v-for="d, index in  kalenderAkademik " :key="d">
          <td>{{ index+=1 }}</td>
          <td class="position-sticky start-0 text-start">{{ d.nama }}</td>
          <td v-for="t, tanggal in  d.kalender " :key="t" class="p-0"
            :class="{ 'table-dark table-bordered border-secondary': !t.kalender_id, 'table-danger table-bordered border-secondary': t.libur_id }">
            <button v-if="t.kalender_id" class="btn btn-sm btn-outline-dark px-1 m-0 hari border-0" @click="editData(t)"
              data-bs-toggle="modal" data-bs-target="#editData">
              {{ t.kode_libur || t.nama_hari }}
            </button>
            <button v-else class="btn btn-sm m-0 btn-outline-secondary border-0 hari"
              @click="tambahData(d.bulan, tanggal += 1)" data-bs-toggle="modal" data-bs-target="#tambahData">+</button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="row justify-content-between mt-3">
    <div class="col-6 col-md-4">
      <table class="table table-sm shadow small" id="tabelKeterangan">
        <thead>
          <tr>
            <th colspan="2">Keterangan :</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="l in listLibur" :key="l">
            <td>{{ l.kode }}</td>
            <td>{{ l.nama }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="col-6 col-md-4">
      <table class="table table-sm shadow small" id="tabelHariLibur">
        <thead>
          <tr>
            <th colspan="2">Hari Libur :</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="hl in hariLibur" :key="hl">
            <td>{{ hl.tanggal.toLocaleDateString('id-ID') }}</td>
            <td>{{ hl.keterangan }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <div class="modal fade" id="tambahData" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content vl-parent" ref="formCreate">
        <form @submit.prevent="simpanData">
          <div class="modal-header">
            <legend class="modal-title">Tambah Data Kalender {{ tahunAjaranTerpilih.nama }}</legend>
            <button type="button" id="tutupFormCreate" class="btn-close" data-bs-dismiss="modal"></button>
          </div>
          <div class="modal-body">
            <div class="row mb-3">
              <label class="col-sm-3 col-form-label col-form-label-sm">Bulan</label>
              <label class="col-sm-9 col-form-label col-form-label-sm">{{ formCreate.bulan.nama }}</label>
            </div>
            <div class="row mb-3">
              <label class="col-sm-3 col-form-label col-form-label-sm">Tanggal</label>
              <label class="col-sm-9 col-form-label col-form-label-sm">{{ formCreate.tanggal }}</label>
            </div>
            <div class="row mb-3">
              <label class="col-sm-3 col-form-label col-form-label-sm">Hari</label>
              <div class="col-sm-9">
                <select class="form-select form-select-sm" v-model="formCreate.hari" :required="!formCreate.libur"
                  @change="formCreate.libur = ''">
                  <option disabled value="">-- Pilih Hari --</option>
                  <option v-for="h in listHari" :key="h" :value="h">{{ h.nama }}</option>
                </select>
              </div>
            </div>
            <div class="row mb-3">
              <label class="col-sm-3 col-form-label col-form-label-sm">Non KBM</label>
              <div class="col-sm-9">
                <select class="form-select form-select-sm" v-model="formCreate.libur" :required="!formCreate.hari"
                  @change="formCreate.hari = ''">
                  <option disabled value="">-- Pilih Jenis Non KBM --</option>
                  <option v-for="l in listLibur" :key="l" :value="l">{{ l.nama }}</option>
                </select>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="submit" class="btn btn-primary">Simpan</button>
          </div>
        </form>
      </div>
    </div>
  </div>
  <div class="modal fade" id="editData" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content vl-parent" ref="formEdit">
        <form @submit.prevent="updateData">
          <div class="modal-header">
            <legend class="modal-title">Edit Data Kalender {{ tahunAjaranTerpilih.nama }}</legend>
            <button type="button" id="tutupFormEdit" class="btn-close" data-bs-dismiss="modal"></button>
          </div>
          <div class="modal-body">
            <div class="row mb-3">
              <label class="col-sm-3 col-form-label col-form-label-sm">Bulan</label>
              <label class="col-sm-9 col-form-label col-form-label-sm">{{ formEdit.bulan.nama }}</label>
            </div>
            <div class="row mb-3">
              <label class="col-sm-3 col-form-label col-form-label-sm">Tanggal</label>
              <label class="col-sm-9 col-form-label col-form-label-sm">{{ formEdit.tanggal }}</label>
            </div>
            <div class="row mb-3">
              <label class="col-sm-3 col-form-label col-form-label-sm">Hari</label>
              <div class="col-sm-9">
                <select class="form-select form-select-sm" v-model="formEdit.hari" :required="!formEdit.libur"
                  @change="formEdit.libur = ''">
                  <option disabled value="">-- Pilih Hari --</option>
                  <option v-for="h in listHari" :key="h" :value="h">{{ h.nama }}</option>
                </select>
              </div>
            </div>
            <div class="row mb-3">
              <label class="col-sm-3 col-form-label col-form-label-sm">Non KBM</label>
              <div class="col-sm-9">
                <select class="form-select form-select-sm" v-model="formEdit.libur" :required="!formEdit.hari"
                  @change="formEdit.hari = ''">
                  <option disabled value="">-- Pilih Jenis Non KBM --</option>
                  <option v-for="l in listLibur" :key="l" :value="l">{{ l.nama }}</option>
                </select>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <div class="hstack gap-1">
              <button type="button" class="btn btn-danger" @click="confirmDelete">Hapus</button>
              <div class="vr"></div>
              <button type="submit" class="btn btn-primary">Perbarui</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
  <div class="modal fade" id="unggahData" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content vl-parent" ref="formUpload">
        <form @submit.prevent="unggahFile">
          <div class="modal-header">
            <legend class="modal-title">Unggah Data Kalender {{ tahunAjaranTerpilih.nama }}</legend>
            <button type="button" class="btn-close" data-bs-dismiss="modal"></button>
          </div>
          <div class="modal-body">
            <div class="row mb-3">
              <label class="col-sm-3 col-form-label col-form-label-sm">File CSV</label>
              <div class="col-sm-9">
                <input type="file" accept=".csv" class="form-control form-control-sm" placeholder="Pilih File CSV"
                  required oninvalid="this.setCustomValidity('Silahkan pilih file yang akan diunggah!')"
                  oninput="this.setCustomValidity('')" ref="fileInput" @change="handleInputFile">
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="submit" id="tombol-unggah" class="btn btn-primary">Simpan</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import WebSocketService from './WebSocketService';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
export default {
  name: 'HariLibur',
  data() {
    return {
      insertOrDeleteListener: (d) => {
        switch (d) {
          case 'kalender':
            this.getKalenderAkademik();
            break;
          case 'hariLibur':
            this.getHariLibur();
            break;
          case 'hari':
            this.getListHari();
            break;
          case 'libur':
            this.getListLibur();
            break;
          default:
            break;
        }
      },
      updateListener: (d) => {
        switch (d) {
          case 'kalender':
            this.getKalenderAkademik();
            break;
          case 'hariLibur':
            this.getHariLibur();
            break;
          case 'hari':
            this.getListHari();
            break;
          case 'statusHari':
            this.getListHari();
            break;
          case 'libur':
            this.getListLibur();
            break;
          case 'statusLibur':
            this.getListLibur();
            break;
          default:
            break;
        }
      },
      arrayBulan: [
        {
          bulan: 7,
          nama: "Juli"
        },
        {
          bulan: 8,
          nama: "Agustus"
        },
        {
          bulan: 9,
          nama: "September"
        },
        {
          bulan: 10,
          nama: "Oktober"
        },
        {
          bulan: 11,
          nama: "November"
        },
        {
          bulan: 12,
          nama: "Desember"
        },
        {
          bulan: 1,
          nama: "Januari"
        },
        {
          bulan: 2,
          nama: "Februari"
        },
        {
          bulan: 3,
          nama: "Maret"
        },
        {
          bulan: 4,
          nama: "April"
        },
        {
          bulan: 5,
          nama: "Mei"
        },
        {
          bulan: 6,
          nama: "Juni"
        },
      ],
      kalenderAkademik: [],
      templateKalender: [{
        bulan: '',
        tanggal: '',
        hari_id: '',
        libur_id: ''
      }],
      hariLibur: [],
      tahunAjaran: [],
      listHari: [],
      listLibur: [],
      formCreate: {
        bulan: '',
        tanggal: null,
        hari: '',
        libur: ''
      },
      selectedData: {},
      formEdit: {
        bulan: '',
        tanggal: null,
        hari: '',
        libur: ''
      },
      tahunAjaranTerpilih: "",
      selectedFile: null,
    }
  },
  mounted() {
    WebSocketService.on("inserted-or-deleted-data", this.insertOrDeleteListener);
    WebSocketService.on("update-data", this.updateListener);
    this.tahunAjaranTerpilih = this.tpAktif;
    this.getKalenderAkademik();
    this.getHariLibur();
    this.getListTahunAjaran();
    this.getListHari();
    this.getListLibur();
  },
  beforeUnmount() {
    WebSocketService.off('inserted-or-deleted-data', this.insertOrDeleteListener);
    WebSocketService.off("update-data", this.updateListener);
  },
  computed: {
    tpAktif() {
      return this.$store.getters.getTp
    },
  },
  watch: {
    tpAktif() {
      this.tahunAjaranTerpilih = this.tpAktif;
    },
    tahunAjaranTerpilih() {
      this.getKalenderAkademik();
      this.getHariLibur();
    },
  },
  methods: {
    async getKalenderAkademik() {
      let memuat = this.$loading.show({
        container: this.$refs.table,
        loader: 'dots'
      });
      const headers = {
        Authorization: localStorage.getItem('token')
      };
      try {
        const response = await this.axios.post('/kalender', {
          tahun_ajaran_id: this.tahunAjaranTerpilih.tahun_ajaran_id,
        }, {
          headers: headers
        });
        if (response.data) {
          this.kalenderAkademik = [];
          const kal = response.data.kalender;
          for (const b of this.arrayBulan) {
            let kalender = new Array()
            for (let t = 1; t <= 31; t++) {
              kalender.push(Object(kal.filter((k) => k.bulan == b.bulan && k.tanggal == t)[0]))
            }
            this.kalenderAkademik.push({
              bulan: b.bulan,
              nama: b.nama,
              kalender: kalender
            })
          }
        }
      }
      catch (err) {
        if (err.response) {
          if (err.response.status == 401) {
            this.$swal({
              title: 'Gagal',
              text: 'Sesi berakhir. Silahkan login ulang.',
              icon: 'error',
              confirmButtonText: 'Baik'
            }).then(() => {
              this.$router.go();
            });
          } else {
            console.log(err);
          }
        } else {
          console.log(err)
        }
      }
      setTimeout(() => {
        memuat.hide();
      }, 250);
    },
    async getHariLibur() {
      const headers = {
        Authorization: localStorage.getItem('token')
      };
      try {
        const response = await this.axios.post('/hari-libur/list', {
          tahun_ajaran_id: this.tahunAjaranTerpilih.tahun_ajaran_id,
          limit: 365,
          offset: 0
        }, {
          headers: headers
        });
        if (response.data) {
          this.hariLibur = response.data.hariLibur;
          for (const h of this.hariLibur) {
            h.tanggal = new Date(h.tanggal);
          }
        }
      }
      catch (err) {
        if (err.response) {
          if (err.response.status == 401) {
            this.$swal({
              title: 'Gagal',
              text: 'Sesi berakhir. Silahkan login ulang.',
              icon: 'error',
              confirmButtonText: 'Baik'
            }).then(() => {
              this.$router.go();
            });
          } else {
            console.log(err);
          }
        } else {
          console.log(err)
        }
      }
    },
    async getListTahunAjaran() {
      const headers = {
        Authorization: localStorage.getItem('token')
      };
      try {
        const response = await this.axios.post('/penjadwalan/tahun-ajaran/list', {}, {
          headers: headers
        });
        if (response.data) {
          this.tahunAjaran = response.data.tahun_ajaran;
        }
      }
      catch (err) {
        if (err.response) {
          if (err.response.status == 401) {
            this.$swal({
              title: 'Gagal',
              text: 'Sesi berakhir. Silahkan login ulang.',
              icon: 'error',
              confirmButtonText: 'Baik'
            }).then(() => {
              this.$router.go();
            });
          } else {
            console.log(err);
          }
        } else {
          console.log(err)
        }
      }
    },
    async getListHari() {
      const headers = {
        Authorization: localStorage.getItem('token')
      };
      try {
        const response = await this.axios.post('/hari/aktif', {}, {
          headers: headers
        });
        if (response.data) {
          this.listHari = response.data.hari;
        }
      }
      catch (err) {
        if (err.response) {
          if (err.response.status == 401) {
            this.$swal({
              title: 'Gagal',
              text: 'Sesi berakhir. Silahkan login ulang.',
              icon: 'error',
              confirmButtonText: 'Baik'
            }).then(() => {
              this.$router.go();
            });
          } else {
            console.log(err);
          }
        } else {
          console.log(err)
        }
      }
    },
    async getListLibur() {
      const headers = {
        Authorization: localStorage.getItem('token')
      };
      try {
        const response = await this.axios.post('/libur/list', {
          aktif: 1
        }, {
          headers: headers
        });
        if (response.data) {
          this.listLibur = response.data.libur;
        }
      }
      catch (err) {
        if (err.response) {
          if (err.response.status == 401) {
            this.$swal({
              title: 'Gagal',
              text: 'Sesi berakhir. Silahkan login ulang.',
              icon: 'error',
              confirmButtonText: 'Baik'
            }).then(() => {
              this.$router.go();
            });
          } else {
            console.log(err);
          }
        } else {
          console.log(err)
        }
      }
    },
    tambahData(b, t) {
      this.formCreate.bulan = this.arrayBulan.filter((bln) => bln.bulan == b)[0];
      this.formCreate.tanggal = t;
    },
    async simpanData() {
      let memuat = this.$loading.show({
        container: this.$refs.formCreate,
        loader: 'spinner'
      });
      const headers = {
        Authorization: localStorage.getItem('token')
      };
      const newData = {
        tahun_ajaran_id: this.tahunAjaranTerpilih.tahun_ajaran_id,
        bulan: this.formCreate.bulan.bulan,
        tanggal: this.formCreate.tanggal,
        hari_id: this.formCreate.hari.hari_id || null,
        libur_id: this.formCreate.libur.libur_id
      }
      try {
        const response = await this.axios.post('/kalender/tambah', newData, {
          headers: headers
        });
        if (response.data) {
          document.getElementById('tutupFormCreate').click();
          this.$swal({
            title: 'Sukses',
            text: response.data.message,
            icon: 'success',
            showConfirmButton: false,
            timer: 1500
          }).then(() => {
            this.formCreate = {
              bulan: '',
              tanggal: null,
              hari: '',
              libur: ''
            }
          });
        }
      }
      catch (err) {
        if (err.response) {
          if (err.response.status == 401) {
            this.$swal({
              title: 'Gagal',
              text: 'Sesi berakhir. Silahkan login ulang.',
              icon: 'error',
              confirmButtonText: 'Baik'
            }).then(() => {
              this.$router.go();
            });
          } else if (err.response.status == 403) {
            this.$swal({
              title: 'Gagal',
              text: 'Anda tidak memiliki akses untuk menambahkan data.',
              icon: 'error',
              confirmButtonText: 'Baik'
            });
          } else {
            this.$swal({
              title: 'Gagal',
              html: `<p class="text-danger">${err.response.data.error}</p>
                <code class="text-secondary text-bg-secondary bg-opacity-10">code: ${err.response.data.code}<br/>
                  message: ${err.response.data.message}</code>`,
              icon: 'error',
              confirmButtonText: 'Baik'
            });
          }
        } else {
          console.log(err)
        }
      }
      setTimeout(() => {
        memuat.hide();
      }, 250);
    },
    handleInputFile(event) {
      const file = event.target.files[0];
      if (!file || file.type !== 'text/csv') {
        this.$swal({
          title: 'Peringatan',
          text: 'File yang Anda pilih bukan file CSV. Silahkan pilih file dengan ekstensi .csv sesuai dengan contoh file!',
          icon: 'warning',
          confirmButtonText: 'Mengerti',
        }).then(() => {
          this.$refs.fileInput.value = null;
          document.getElementById('tombol-unggah').removeAttribute('data-bs-dismiss');
        })
      } else {
        this.selectedFile = file;
        document.getElementById('tombol-unggah').setAttribute('data-bs-dismiss', 'modal');
        return;
      }
    },
    async unggahFile() {
      if (this.selectedFile.type !== 'text/csv') {
        this.$swal({
          title: 'Peringatan',
          text: 'File yang Anda pilih bukan file CSV. Silahkan pilih file dengan ekstensi .csv sesuai dengan contoh file!',
          icon: 'warning',
          confirmButtonText: 'Mengerti',
        }).then(() => {
          document.getElementById('tombol-unggah').removeAttribute('data-bs-dismiss');
        })
      } else {
        this.$refs.fileInput.value = null;
        document.getElementById('tombol-unggah').removeAttribute('data-bs-dismiss');
        let memuat = this.$loading.show({
          loader: 'spinner'
        });
        const headers = {
          Authorization: localStorage.getItem('token')
        };
        const formData = new FormData();
        formData.append('tahun_ajaran_id', this.tahunAjaranTerpilih.tahun_ajaran_id)
        formData.append('csvFile', this.selectedFile);
        try {
          const response = await this.axios.post('/kalender/tambah/csv', formData, {
            headers: headers
          });
          if (response.data) {
            this.$swal({
              title: 'Sukses',
              text: response.data.message,
              icon: 'success',
              showConfirmButton: false,
              timer: 1500
            }).then(() => {
              this.$refs.fileInput.value = null;
            });
          } else if (response.status == 204) {
            this.$swal({
              title: 'Informasi',
              text: 'Tidak ada data yang ditambahkan.',
              icon: 'info',
              confirmButtonText: 'Baik',
            }).then(() => {
              this.$refs.fileInput.value = null;
            });
          } else {
            this.$swal({
              title: 'Gagal',
              text: 'Internal Server Error',
              icon: 'error',
              confirmButtonText: 'Baik',
            }).then(() => {
              this.$refs.fileInput.value = null;
            });
          }
        } catch (err) {
          if (err.response) {
            if (err.response.status == 401) {
              this.$swal({
                title: 'Gagal',
                text: 'Sesi berakhir. Silahkan login ulang.',
                icon: 'error',
                confirmButtonText: 'Baik'
              }).then(() => {
                this.$router.go();
              });
            } else if (err.response.status == 403) {
              this.$swal({
                title: 'Gagal',
                text: 'Anda tidak memiliki akses untuk menambahkan data.',
                icon: 'error',
                confirmButtonText: 'Baik'
              });
            } else {
              this.$swal({
                title: 'Gagal',
                html: `<p class="text-danger">${err.response.data.error}</p>
                <code class="text-secondary text-bg-secondary bg-opacity-10">code: ${err.response.data.code}<br/>
                  message: ${err.response.data.message}</code>`,
                icon: 'error',
                confirmButtonText: 'Baik'
              });
            }
          } else {
            console.log(err)
          }
        }
        setTimeout(() => {
          memuat.hide();
        }, 250);
      }
    },
    editData(d) {
      this.selectedData = { ...d };
      this.selectedData.bulan = this.arrayBulan.filter((bln) => bln.bulan == d.bulan)[0];
      this.selectedData.hari = this.listHari.filter((h) => h.hari_id == d.hari_id)[0] || '';
      this.selectedData.libur = this.listLibur.filter((l) => l.libur_id == d.libur_id)[0] || '';
      this.formEdit = { ...this.selectedData };
    },
    async updateData() {
      let memuat = this.$loading.show({
        container: this.$refs.formEdit,
        loader: 'spinner'
      });
      if (JSON.stringify(this.formEdit) == JSON.stringify(this.selectedData)) {
        this.$swal({
          title: 'Perhatian',
          text: 'Anda belum melakukan perubahan',
          icon: 'warning',
          confirmButtonText: 'Baik'
        });
      } else {
        const headers = {
          Authorization: localStorage.getItem('token')
        };
        const newData = {
          kalender_id: this.formEdit.kalender_id,
          hari_id: this.formEdit.hari.hari_id,
          libur_id: this.formEdit.libur.libur_id
        }
        try {
          const response = await this.axios.post('/kalender/perbarui', newData, { headers: headers });
          if (response.data) {
            document.getElementById('tutupFormEdit').click();
            this.$swal({
              title: 'Sukses',
              text: 'Berhasil memperbarui data Kalender',
              icon: 'success',
              showConfirmButton: false,
              timer: 1500
            })
          } else {
            this.$swal({
              title: 'Gagal',
              text: 'Data tidak ditemukan',
              icon: 'error',
              confirmButtonText: 'Baik'
            });
          }
        }
        catch (err) {
          if (err.response) {
            if (err.response.status == 401) {
              this.$swal({
                title: 'Gagal',
                text: 'Sesi berakhir. Silahkan login ulang.',
                icon: 'error',
                confirmButtonText: 'Baik'
              }).then(() => {
                this.$router.go();
              });
            } else if (err.response.status == 403) {
              this.$swal({
                title: 'Gagal',
                text: 'Anda tidak memiliki akses untuk memperbarui data.',
                icon: 'error',
                confirmButtonText: 'Baik'
              });
            } else {
              this.$swal({
                title: 'Gagal',
                html: `<p class="text-danger">${err.response.data.error}</p>
                <code class="text-secondary text-bg-secondary bg-opacity-10">code: ${err.response.data.code}<br/>
                  message: ${err.response.data.message}</code>`,
                icon: 'error',
                confirmButtonText: 'Baik'
              });
            }
          } else {
            console.log(err)
          }
        }
      }
      setTimeout(() => {
        memuat.hide();
      }, 250);
    },
    confirmDelete() {
      this.$swal({
        title: 'Konfirmasi',
        html: `<span class="h5">Anda yakin ingin menghapus data Kalender tanggal <code class="text-black text-bg-secondary bg-opacity-50">${this.selectedData.tanggal}</code>
          bulan <code class="text-black text-bg-secondary bg-opacity-50">${this.selectedData.bulan.nama}</code>?</span><br/>
          <small class="fst-italic text-muted">Tindakan ini tidak dapat diurungkan</small>`,
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: 'Ya, Hapus',
        cancelButtonText: 'Batal',
        reverseButtons: true,
      }).then((result) => {
        document.getElementById('tutupFormEdit').click();
        if (result.isConfirmed) {
          this.hapusData();
        }
      });
    },
    async hapusData() {
      let memuat = this.$loading.show({
        opacity: 0,
        loader: 'spinner'
      });
      const headers = {
        Authorization: localStorage.getItem('token')
      };
      try {
        const response = await this.axios.post('/kalender/hapus', {
          kalender_id: this.selectedData.kalender_id
        }, {
          headers: headers
        });
        if (response.data) {
          this.$swal({
            title: 'Sukses',
            text: response.data.message,
            icon: 'success',
            showConfirmButton: false,
            timer: 1500
          })
        } else {
          this.$swal({
            title: 'Gagal',
            text: 'Data tidak ditemukan.',
            icon: 'error',
            confirmButtonText: 'Baik'
          });
        }
      }
      catch (err) {
        if (err.response) {
          if (err.response.status == 401) {
            this.$swal({
              title: 'Gagal',
              text: 'Sesi berakhir. Silahkan login ulang.',
              icon: 'error',
              confirmButtonText: 'Baik'
            }).then(() => {
              this.$router.go();
            });
          } else if (err.response.status == 403) {
            this.$swal({
              title: 'Gagal',
              text: 'Anda tidak memiliki akses untuk menghapus data.',
              icon: 'error',
              confirmButtonText: 'Baik'
            });
          } else {
            this.$swal({
              title: 'Gagal',
              html: `<p class="text-danger">${err.response.data.error}</p>
                <code class="text-secondary text-bg-secondary bg-opacity-10">code: ${err.response.data.code}<br/>
                  message: ${err.response.data.message}</code>`,
              icon: 'error',
              confirmButtonText: 'Baik'
            });
          }
        } else {
          console.log(err)
        }
      }
      setTimeout(() => {
        memuat.hide();
      }, 250);
    },
    confirmDeleteKalender() {
      this.$swal({
        title: 'Konfirmasi',
        html: `<span class="h5">Anda yakin ingin menghapus data Kalender Tahun Ajaran <code class="text-black text-bg-secondary bg-opacity-50">${this.tahunAjaranTerpilih.nama}</code>?</span><br/>
          <small class="fst-italic text-muted">Tindakan ini tidak dapat diurungkan</small>`,
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: 'Ya, Hapus',
        cancelButtonText: 'Batal',
        reverseButtons: true,
      }).then((result) => {
        document.getElementById('tutupFormEdit').click();
        if (result.isConfirmed) {
          this.hapusDataKalender();
        }
      });
    },
    async hapusDataKalender() {
      let memuat = this.$loading.show({
        opacity: 0,
        loader: 'spinner'
      });
      const headers = {
        Authorization: localStorage.getItem('token')
      };
      try {
        const response = await this.axios.post('/kalender/hapus', {
          tahun_ajaran_id: this.tahunAjaranTerpilih.tahun_ajaran_id
        }, {
          headers: headers
        });
        if (response.data) {
          this.$swal({
            title: 'Sukses',
            text: response.data.message,
            icon: 'success',
            showConfirmButton: false,
            timer: 1500
          })
        } else {
          this.$swal({
            title: 'Gagal',
            text: 'Data tidak ditemukan.',
            icon: 'error',
            confirmButtonText: 'Baik'
          });
        }
      }
      catch (err) {
        if (err.response) {
          if (err.response.status == 401) {
            this.$swal({
              title: 'Gagal',
              text: 'Sesi berakhir. Silahkan login ulang.',
              icon: 'error',
              confirmButtonText: 'Baik'
            }).then(() => {
              this.$router.go();
            });
          } else if (err.response.status == 403) {
            this.$swal({
              title: 'Gagal',
              text: 'Anda tidak memiliki akses untuk menghapus data.',
              icon: 'error',
              confirmButtonText: 'Baik'
            });
          } else {
            this.$swal({
              title: 'Gagal',
              html: `<p class="text-danger">${err.response.data.error}</p>
                <code class="text-secondary text-bg-secondary bg-opacity-10">code: ${err.response.data.code}<br/>
                  message: ${err.response.data.message}</code>`,
              icon: 'error',
              confirmButtonText: 'Baik'
            });
          }
        } else {
          console.log(err)
        }
      }
      setTimeout(() => {
        memuat.hide();
      }, 250);
    },
    unduhPDF() {
      const doc = new jsPDF({
        orientation: 'l',
        unit: 'mm',
        format: 'a4',
      });

      const judul = `Kalender Akademik ${this.tahunAjaranTerpilih.nama}`;

      doc.setFontSize(14);
      doc.text(judul, 10, 15);

      let columnStyles = {
        0: {
          cellWidth: 'wrap', fontSize: 10, cellPadding: {
            top: 1,
            right: 2,
            bottom: 1,
            left: 2
          }
        },
        1: {
          halign: 'left', cellWidth: 'wrap', fontSize: 10, cellPadding: {
            top: 1,
            right: 2,
            bottom: 1,
            left: 2
          }
        },
      }

      doc.autoTable({
        html: '#tabelKalender',
        margin: {
          top: 20,
          right: 10,
          bottom: 15,
          left: 10
        },
        headStyles: {
          halign: 'center',
          cellPadding: 1,
          fillColor: '#184b89',
          fontSize: 10,
        },
        styles: {
          cellPadding: 1,
          fontSize: 8,
          minCellWidth: 8,
          valign: 'middle',
          halign: 'center',
          lineWidth: 0.2,
          lineColor: '#666666',
          overflow: 'hidden'
        },
        columnStyles: columnStyles,
        didParseCell: (d) => {
          if (d.cell.text == '+') {
            d.cell.styles.fillColor = '#171717';
            d.cell.text = '';
          }
          if (d.cell.text == 'LU') {
            d.cell.styles.fillColor = '#b30202';
            d.cell.styles.textColor = '#fce8e8';
          }
        }
      });
      const pageNumber = doc.internal.getNumberOfPages();
      doc.autoTable({
        html: '#tabelKeterangan',
        startY: 110,
        margin: {
          top: 20,
          right: 152,
          bottom: 15,
          left: 10
        },
        headStyles: {
          cellPadding: 2,
          fillColor: '#184b89',
        },
        styles: {
          cellPadding: {
            top: 1,
            right: 2,
            bottom: 1,
            left: 2
          },
          valign: 'middle',
          lineWidth: 0.2,
          lineColor: '#666666',
        },
        columnStyles: {
          0: { halign: 'center' }
        }
      });
      doc.setPage(pageNumber);
      doc.autoTable({
        html: '#tabelHariLibur',
        startY: 110,
        margin: {
          top: 20,
          right: 10,
          bottom: 15,
          left: 152
        },
        headStyles: {
          cellPadding: 2,
          fillColor: '#184b89',
        },
        styles: {
          cellPadding: {
            top: 1,
            right: 2,
            bottom: 1,
            left: 2
          },
          valign: 'middle',
          lineWidth: 0.2,
          lineColor: '#666666',
        },
        columnStyles: {
          0: { halign: 'center' }
        }
      });

      const namaFile = `Kalender Akademik ${this.tahunAjaranTerpilih.nama}`;

      doc.save(namaFile);
    }
  }
}
</script>

<style scoped>
button.hari {
  white-space: nowrap;
  overflow: hidden;
  max-width: 25px;
  text-overflow: clip;
  font-size: 12px;
}

button.hari:hover {
  white-space: wrap;
  max-width: 45px;
}
</style>