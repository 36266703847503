<template>
  <h4>Kontrol Penjadwalan</h4>
  <div class="card shadow">
    <div class="card-header">
      <div class="row row-cols-auto mx-auto">
        <ul class="nav nav-pills card-header-pills">
          <li class="nav-item">
            <router-link to="/kontrol/rombel" class="nav-link" active-class="active">Rombel</router-link>
          </li>
          <li class="nav-item">
            <router-link to="/kontrol/guru" class="nav-link" active-class="active">Guru</router-link>
          </li>
          <li class="nav-item">
            <router-link to="/kontrol/guru-kosong" class="nav-link" active-class="active">Guru Kosong</router-link>
          </li>
          <li class="nav-item">
            <router-link to="/kontrol/ruang" class="nav-link" active-class="active">Ruang</router-link>
          </li>
          <li class="nav-item">
            <router-link to="/kontrol/ruang-kosong" class="nav-link" active-class="active">Ruang Kosong</router-link>
          </li>
        </ul>
        <div v-if="!$route.params.data" class="col-auto ms-auto my-auto pe-0">
          <select class="form-select form-select-sm shadow-sm" v-model="semester">
            <option disabled value="">-- Pilih Semester --</option>
            <option v-for="s in listSemester" :key="s" :value="s">{{ s.nama }}</option>
          </select>
        </div>
      </div>
    </div>
    <div class="card-body">
      <div v-if="!$route.params.data" class="card-text">
        <div class="row row-cols-2 row-cols-sm-3 row-cols-lg-4 g-3">
          <div class="col">
            <div class="card shadow h-100">
              <div class="card-header d-flex">
                <span class="h6 m-0">Rombel</span>
                <router-link to="/kontrol/rombel" class="ms-auto d-inline-flex align-items-center">
                  <svg xmlns="http://www.w3.org/2000/svg" height="18" fill="currentColor"
                    class="bi bi-box-arrow-in-up-right" viewBox="0 0 16 16">
                    <path fill-rule="evenodd"
                      d="M6.364 13.5a.5.5 0 0 0 .5.5H13.5a1.5 1.5 0 0 0 1.5-1.5v-10A1.5 1.5 0 0 0 13.5 1h-10A1.5 1.5 0 0 0 2 2.5v6.636a.5.5 0 1 0 1 0V2.5a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 .5.5v10a.5.5 0 0 1-.5.5H6.864a.5.5 0 0 0-.5.5z" />
                    <path fill-rule="evenodd"
                      d="M11 5.5a.5.5 0 0 0-.5-.5h-5a.5.5 0 0 0 0 1h3.793l-8.147 8.146a.5.5 0 0 0 .708.708L10 6.707V10.5a.5.5 0 0 0 1 0v-5z" />
                  </svg>
                </router-link>
              </div>
              <div class="card-body">
                <span class="h1">{{ kontrolJadwal.rombel }}</span><span class="ms-2 text-body-secondary  fs-6">data
                  aktif</span>
              </div>
            </div>
          </div>
          <div class="col">
            <div class="card shadow h-100">
              <div class="card-header d-flex">
                <span class="h6 m-0">Guru</span>
                <router-link to="/kontrol/guru" class="ms-auto d-inline-flex align-items-center">
                  <svg xmlns="http://www.w3.org/2000/svg" height="18" fill="currentColor"
                    class="bi bi-box-arrow-in-up-right" viewBox="0 0 16 16">
                    <path fill-rule="evenodd"
                      d="M6.364 13.5a.5.5 0 0 0 .5.5H13.5a1.5 1.5 0 0 0 1.5-1.5v-10A1.5 1.5 0 0 0 13.5 1h-10A1.5 1.5 0 0 0 2 2.5v6.636a.5.5 0 1 0 1 0V2.5a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 .5.5v10a.5.5 0 0 1-.5.5H6.864a.5.5 0 0 0-.5.5z" />
                    <path fill-rule="evenodd"
                      d="M11 5.5a.5.5 0 0 0-.5-.5h-5a.5.5 0 0 0 0 1h3.793l-8.147 8.146a.5.5 0 0 0 .708.708L10 6.707V10.5a.5.5 0 0 0 1 0v-5z" />
                  </svg>
                </router-link>
              </div>
              <div class="card-body">
                <span class="h1">{{ kontrolJadwal.guru }}</span><span class="ms-2 text-body-secondary  fs-6">data
                  aktif</span>
              </div>
            </div>
          </div>
          <div class="col">
            <div class="card shadow h-100">
              <div class="card-header d-flex">
                <span class="h6 m-0">Ruang</span>
                <router-link to="/kontrol/ruang" class="ms-auto d-inline-flex align-items-center">
                  <svg xmlns="http://www.w3.org/2000/svg" height="18" fill="currentColor"
                    class="bi bi-box-arrow-in-up-right" viewBox="0 0 16 16">
                    <path fill-rule="evenodd"
                      d="M6.364 13.5a.5.5 0 0 0 .5.5H13.5a1.5 1.5 0 0 0 1.5-1.5v-10A1.5 1.5 0 0 0 13.5 1h-10A1.5 1.5 0 0 0 2 2.5v6.636a.5.5 0 1 0 1 0V2.5a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 .5.5v10a.5.5 0 0 1-.5.5H6.864a.5.5 0 0 0-.5.5z" />
                    <path fill-rule="evenodd"
                      d="M11 5.5a.5.5 0 0 0-.5-.5h-5a.5.5 0 0 0 0 1h3.793l-8.147 8.146a.5.5 0 0 0 .708.708L10 6.707V10.5a.5.5 0 0 0 1 0v-5z" />
                  </svg>
                </router-link>
              </div>
              <div class="card-body">
                <span class="h1">{{ kontrolJadwal.ruang }}</span><span class="ms-2 text-body-secondary  fs-6">data
                  aktif</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <kontrol-rombel v-else-if="$route.params.data === 'rombel'" />
      <kontrol-guru v-else-if="$route.params.data === 'guru'" />
      <guru-kosong v-else-if="$route.params.data === 'guru-kosong'" />
      <kontrol-ruang v-else-if="$route.params.data === 'ruang'" />
      <ruang-kosong v-else-if="$route.params.data === 'ruang-kosong'" />
      <not-found v-else />
    </div>
  </div>
</template>

<script>
import NotFound from '@/components/NotFound.vue';
import KontrolRombel from '@/components/KontrolRombel.vue';
import KontrolGuru from '@/components/KontrolGuru.vue';
import GuruKosong from '@/components/GuruKosong.vue';
import KontrolRuang from '@/components/KontrolRuang.vue';
import RuangKosong from '@/components/RuangKosong.vue';
import WebSocketService from '@/components/WebSocketService';
export default {
  components: { NotFound, KontrolRombel, KontrolGuru, GuruKosong, KontrolRuang, RuangKosong },
  name: 'KontrolView',
  data() {
    return {
      dataListener: (d) => {
        if (d == 'ruang' || d == 'statusRuang') {
          this.getKontrolJadwal();
        }
      },
      listSemester: [],
      semester: "",
      kontrolJadwal: {
        rombel: 0,
        guru: 0,
        ruang: 0,
      },
    }
  },
  mounted() {
    WebSocketService.on("inserted-or-deleted-data", this.dataListener);
    WebSocketService.on("update-data", this.dataListener);
    let memuat = this.$loading.show({
      loader: 'dots'
    });
    this.semester = {
      tahun_ajaran_id: this.tpAktif.tahun_ajaran_id,
      ...this.semesterAktif
    };
    this.getListSemester();
    setTimeout(() => {
      memuat.hide();
    }, 250);
  },
  beforeUnmount() {
    WebSocketService.off("inserted-or-deleted-data", this.dataListener);
    WebSocketService.off("update-data", this.dataListener);
  },
  computed: {
    tpAktif() {
      return this.$store.getters.getTp
    },
    semesterAktif() {
      return this.$store.getters.getSemester
    }
  },
  watch: {
    semesterAktif() {
      this.semester = {
        tahun_ajaran_id: this.tpAktif.tahun_ajaran_id,
        ...this.semesterAktif
      };
    },
    semester() {
      this.getKontrolJadwal();
    }
  },
  methods: {
    async getListSemester() {
      const headers = {
        Authorization: localStorage.getItem('token')
      };
      try {
        const response = await this.axios.post('/penjadwalan/semester/list', {}, {
          headers: headers
        });
        if (response.data) {
          this.listSemester = response.data.semester;
        }
      }
      catch (err) {
        if (err.response) {
          if (err.response.status == 401) {
            this.$swal({
              title: 'Gagal',
              text: 'Sesi berakhir. Silahkan login ulang.',
              icon: 'error',
              confirmButtonText: 'Baik'
            }).then(() => {
              this.$router.go();
            });
          } else {
            console.log(err);
          }
        } else {
          console.log(err)
        }
      }
    },
    async getKontrolJadwal() {
      const headers = {
        Authorization: localStorage.getItem('token')
      }
      try {
        const response = await this.axios.post('/penjadwalan/jumlah-data', {
          semester_id: this.semester.semester_id
        }, {
          headers: headers
        });
        if (response.data) {
          this.kontrolJadwal = response.data;
        }
      } catch (err) {
        if (err.response) {
          if (err.response.status == 401) {
            this.$swal({
              title: 'Gagal',
              text: 'Sesi berakhir. Silahkan login ulang.',
              icon: 'error',
              confirmButtonText: 'Baik'
            }).then(() => {
              this.$router.go();
            });
          } else {
            console.log(err);
          }
        } else {
          console.log(err);
        }
      }
    }
  }
}
</script>

<style></style>