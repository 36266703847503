<template>
  <h4>Riwayat Penjadwalan</h4>
  <div class="card shadow">
    <div class="card-header">
      <div class="row row-cols-auto mx-auto">
        <ul class="nav nav-pills card-header-pills">
          <li class="nav-item">
            <router-link to="/riwayat/jadwal-pelajaran" class="nav-link" active-class="active">Jadwal
              Pelajaran</router-link>
          </li>
          <li class="nav-item">
            <router-link to="/riwayat/jadwal-piket" class="nav-link" active-class="active">Jadwal Piket</router-link>
          </li>
        </ul>
        <div v-if="!$route.params.data" class="col-auto ms-auto my-auto pe-0">
          <select class="form-select form-select-sm shadow-sm" v-model="semester">
            <option disabled value="">-- Pilih Semester --</option>
            <option v-for="s in listSemester" :key="s" :value="s">{{ s.nama }}</option>
          </select>
        </div>
      </div>
    </div>
    <div class="card-body">
      <div v-if="!$route.params.data" class="card-text">
        <div class="row row-cols-1 row-cols-sm-2 row-cols-lg-3 g-3">
          <div class="col">
            <div class="card shadow h-100">
              <div class="card-header d-flex">
                <span class="h6 m-0">Jadwal Pelajaran</span>
                <router-link to="/riwayat/jadwal-pelajaran" class="ms-auto d-inline-flex align-items-center">
                  <svg xmlns="http://www.w3.org/2000/svg" height="18" fill="currentColor"
                    class="bi bi-box-arrow-in-up-right" viewBox="0 0 16 16">
                    <path fill-rule="evenodd"
                      d="M6.364 13.5a.5.5 0 0 0 .5.5H13.5a1.5 1.5 0 0 0 1.5-1.5v-10A1.5 1.5 0 0 0 13.5 1h-10A1.5 1.5 0 0 0 2 2.5v6.636a.5.5 0 1 0 1 0V2.5a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 .5.5v10a.5.5 0 0 1-.5.5H6.864a.5.5 0 0 0-.5.5z" />
                    <path fill-rule="evenodd"
                      d="M11 5.5a.5.5 0 0 0-.5-.5h-5a.5.5 0 0 0 0 1h3.793l-8.147 8.146a.5.5 0 0 0 .708.708L10 6.707V10.5a.5.5 0 0 0 1 0v-5z" />
                  </svg>
                </router-link>
              </div>
              <div class="card-body">
                <span class="h1">{{ dataRiwayat.pelajaran }}</span><span
                  class="ms-2 text-body-secondary  fs-6">riwayat</span>
              </div>
            </div>
          </div>
          <div class="col">
            <div class="card shadow h-100">
              <div class="card-header d-flex">
                <span class="h6 m-0">Jadwal Piket</span>
                <router-link to="/riwayat/jadwal-piket" class="ms-auto d-inline-flex align-items-center">
                  <svg xmlns="http://www.w3.org/2000/svg" height="18" fill="currentColor"
                    class="bi bi-box-arrow-in-up-right" viewBox="0 0 16 16">
                    <path fill-rule="evenodd"
                      d="M6.364 13.5a.5.5 0 0 0 .5.5H13.5a1.5 1.5 0 0 0 1.5-1.5v-10A1.5 1.5 0 0 0 13.5 1h-10A1.5 1.5 0 0 0 2 2.5v6.636a.5.5 0 1 0 1 0V2.5a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 .5.5v10a.5.5 0 0 1-.5.5H6.864a.5.5 0 0 0-.5.5z" />
                    <path fill-rule="evenodd"
                      d="M11 5.5a.5.5 0 0 0-.5-.5h-5a.5.5 0 0 0 0 1h3.793l-8.147 8.146a.5.5 0 0 0 .708.708L10 6.707V10.5a.5.5 0 0 0 1 0v-5z" />
                  </svg>
                </router-link>
              </div>
              <div class="card-body">
                <span class="h1">{{ dataRiwayat.piket }}</span><span class="ms-2 text-body-secondary  fs-6">riwayat</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <riwayat-jadwal-pelajaran v-else-if="$route.params.data === 'jadwal-pelajaran'" />
      <riwayat-jadwal-piket v-else-if="$route.params.data === 'jadwal-piket'" />
      <not-found v-else />
    </div>
  </div>
</template>

<script>
import NotFound from '@/components/NotFound.vue';
import RiwayatJadwalPelajaran from '@/components/RiwayatJadwalPelajaran.vue';
import RiwayatJadwalPiket from '@/components/RiwayatJadwalPiket.vue';
import WebSocketService from '@/components/WebSocketService';
export default {
  components: { NotFound, RiwayatJadwalPelajaran, RiwayatJadwalPiket },
  name: 'RiwayatView',
  data() {
    return {
      penjadwalanListener: (d) => {
        const data = JSON.parse(d);
        if (data.semester_id == this.semester.semester_id) {
          this.getDataRiwayat();
        }
      },
      listSemester: [],
      semester: "",
      dataRiwayat: {
        pelajaran: 0,
        piket: 0,
      }
    }
  },
  mounted() {
    WebSocketService.on("inserted-or-deleted-jadwal", this.penjadwalanListener);
    WebSocketService.on("update-jadwal", this.penjadwalanListener);
    let memuat = this.$loading.show({
      loader: 'dots'
    });
    this.semester = {
      tahun_ajaran_id: this.tpAktif.tahun_ajaran_id,
      ...this.semesterAktif
    };
    this.getListSemester();
    setTimeout(() => {
      memuat.hide();
    }, 250);
  },
  beforeUnmount() {
    WebSocketService.off("inserted-or-deleted-jadwal", this.penjadwalanListener);
    WebSocketService.off("update-jadwal", this.penjadwalanListener);
  },
  computed: {
    tpAktif() {
      return this.$store.getters.getTp
    },
    semesterAktif() {
      return this.$store.getters.getSemester
    }
  },
  watch: {
    semesterAktif() {
      this.semester = {
        tahun_ajaran_id: this.tpAktif.tahun_ajaran_id,
        ...this.semesterAktif
      };
    },
    semester() {
      this.getDataRiwayat();
    }
  },
  methods: {
    async getListSemester() {
      const headers = {
        Authorization: localStorage.getItem('token')
      };
      try {
        const response = await this.axios.post('/penjadwalan/semester/list', {}, {
          headers: headers
        });
        if (response.data) {
          this.listSemester = response.data.semester;
        }
      }
      catch (err) {
        if (err.response) {
          if (err.response.status == 401) {
            this.$swal({
              title: 'Gagal',
              text: 'Sesi berakhir. Silahkan login ulang.',
              icon: 'error',
              confirmButtonText: 'Baik'
            }).then(() => {
              this.$router.go();
            });
          } else {
            console.log(err);
          }
        } else {
          console.log(err)
        }
      }
    },
    async getDataRiwayat() {
      const headers = {
        Authorization: localStorage.getItem('token')
      }
      try {
        const response = await this.axios.post('/penjadwalan/jumlah-data', {
          semester_id: this.semester.semester_id,
          riwayat: 1
        }, {
          headers: headers
        });
        if (response.data) {
          this.dataRiwayat = response.data;
        }
      } catch (err) {
        if (err.response) {
          if (err.response.status == 401) {
            this.$swal({
              title: 'Gagal',
              text: 'Sesi berakhir. Silahkan login ulang.',
              icon: 'error',
              confirmButtonText: 'Baik'
            }).then(() => {
              this.$router.go();
            });
          } else {
            console.log(err);
          }
        } else {
          console.log(err);
        }
      }
    }
  }
}
</script>

<style></style>