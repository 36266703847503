<template>
  <penjadwalan-rombel v-if="$route.params.data === 'rombel'" />
  <penjadwalan-guru v-else-if="$route.params.data === 'guru'" />
  <penjadwalan-piket v-else-if="$route.params.data === 'piket'" />
  <not-found v-else />
</template>

<script>
import NotFound from '@/components/NotFound.vue';
import PenjadwalanRombel from '@/components/PenjadwalanRombel.vue';
import PenjadwalanGuru from '@/components/PenjadwalanGuru.vue';
import PenjadwalanPiket from '@/components/PenjadwalanPiket.vue';
export default {
  components: { NotFound, PenjadwalanRombel, PenjadwalanGuru, PenjadwalanPiket },
  name: 'PenjadwalanView',
  data() {
    return {
    }
  },
  created() {
  },
  computed: {
    tpAktif() {
      return this.$store.getters.getTp
    },
    semesterAktif() {
      return this.$store.getters.getSemester
    }
  },
  methods: {
  }
}
</script>

<style></style>