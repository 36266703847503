<template>
  <div class="row row-cols-auto justify-content-between mb-2 mx-auto gap-2">
    <div class="col-auto p-0 d-inline-flex">
      <h5 class="my-auto">Guru</h5>
    </div>
    <div class="col-auto p-0 ms-auto">
      <select class="form-select form-select-sm shadow-sm" v-model="semester">
        <option disabled value="">-- Pilih Semester --</option>
        <option v-for="s in listSemester" :key="s" :value="s">{{ s.nama }}</option>
      </select>
    </div>
  </div>
  <div v-if="guruKontrol.length" class="accordion shadow rounded-bottom">
    <div class="accordion-item" v-for="d, index in guruKontrol" :key="d">
      <h6 class="accordion-header">
        <button class="accordion-button collapsed py-2" type="button" data-bs-toggle="collapse"
          :data-bs-target="'#g' + d.guru_id" aria-expanded="false" :aria-controls="d.guru_id">
          <div class="row justify-content-between w-100 gap-3">
            <div class="col-auto">{{ d.nama }}</div>
            <div class="col">
              <div class="progress h-100 text-bg-secondary" role="progressbar" :aria-valuenow="d.jumlah_jam"
                aria-valuemin="0" :aria-valuemax="d.total_jam">
                <div class="progress-bar progress-bar-striped progress-bar-animated overflow-visible px-1" :class="[d.jumlah_jam / d.total_jam > 0.89 ? 'text-bg-primary' :
                                  d.jumlah_jam / d.total_jam > (40 / 52) ? 'text-bg-warning' :
                                    d.jumlah_jam / d.total_jam > (24 / 52) ? 'text-bg-success' :
                                      d.jumlah_jam / d.total_jam > 0.25 ? 'text-bg-info' :
                                        d.jumlah_jam / d.total_jam > 0 ? 'text-bg-danger' : 'text-bg-secondary'
                                ]" :style="{ width: memuat ? '0%' : d.jumlah_jam / d.total_jam * 100 + '%' }">
                  {{ d.jumlah_jam || 0 }}/{{ d.total_jam }}
                </div>
              </div>
            </div>
          </div>
        </button>
      </h6>
      <div :id="'g' + d.guru_id" class="accordion-collapse collapse">
        <div class="accordion-body bg-light x-5" :class="{ 'rounded-bottom': index == guruKontrol.length - 1 }">
          <div class="accordion shadow-sm">
            <div class="accordion-item">
              <h6 class="accordion-header">
                <button class="accordion-button collapsed py-1" type="button" data-bs-toggle="collapse"
                  :data-bs-target="'#g' + d.guru_id + '-hari'" aria-expanded="false" :aria-controls="d.guru_id + '-hari'"
                  @click="d.dataHari ? '' : getHariKontrol(d)">
                  Hari
                </button>
              </h6>
              <div :id="'g' + d.guru_id + '-hari'" class="accordion-collapse collapse">
                <div class="accordion-body">
                  <div v-if="!d.dataHari" class="spinner-border text-info text-center" role="status">
                    <span class="visually-hidden">Loading...</span>
                  </div>
                  <table v-else class="table table-sm table-striped table-hover shadow mb-0">
                    <thead class="table-secondary align-middle">
                      <tr>
                        <th>Hari</th>
                        <th>Jumlah Jam Terjadwal/Jumlah Jam per Hari</th>
                      </tr>
                    </thead>
                    <tbody class="small">
                      <tr v-for="h in d.dataHari" :key="h">
                        <td>{{ h.nama }}</td>
                        <td>{{ h.jumlah_jadwal || '-' }}/{{ h.jumlah_jam }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h6 class="accordion-header">
                <button class="accordion-button collapsed py-1" type="button" data-bs-toggle="collapse"
                  :data-bs-target="'#g' + d.guru_id + '-mapel'" aria-expanded="false"
                  :aria-controls="d.guru_id + '-mapel'" @click="d.dataMapel ? '' : getMapelKontrol(d)">
                  Mata Pelajaran/Piket
                </button>
              </h6>
              <div :id="'g' + d.guru_id + '-mapel'" class="accordion-collapse collapse">
                <div class="accordion-body">
                  <div v-if="!d.dataMapel" class="spinner-border text-info text-center" role="status">
                    <span class="visually-hidden">Loading...</span>
                  </div>
                  <table v-else class="table table-sm table-striped table-hover shadow mb-0">
                    <thead class="table-secondary align-middle">
                      <tr>
                        <th>Mata Pelajaran/Piket</th>
                        <th>Jumlah Jam Terjadwal</th>
                      </tr>
                    </thead>
                    <tbody class="small">
                      <tr v-for="m in d.dataMapel" :key="m">
                        <td>{{ m.tingkat ? m.tingkat + ' - ' + m.nama : m.nama }}</td>
                        <td>{{ m.jumlah_jam_terjadwal || '-' }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h6 class="accordion-header">
                <button class="accordion-button collapsed py-1" type="button" data-bs-toggle="collapse"
                  :data-bs-target="'#g' + d.guru_id + '-guru'" aria-expanded="false" :aria-controls="d.guru_id + '-guru'"
                  @click="d.rombelDiampu ? '' : getRombelDiampu(d)">
                  Rombel Diampu
                </button>
              </h6>
              <div :id="'g' + d.guru_id + '-guru'" class="accordion-collapse collapse">
                <div class="accordion-body">
                  <div v-if="!d.rombelDiampu" class="spinner-border text-info text-center" role="status">
                    <span class="visually-hidden">Loading...</span>
                  </div>
                  <table v-else class="table table-sm table-striped table-hover shadow mb-0">
                    <thead class="table-secondary align-middle">
                      <tr>
                        <th>Rombongan Belajar</th>
                        <th>Mata Pelajaran</th>
                        <th>Jumlah Jam</th>
                      </tr>
                    </thead>
                    <tbody class="small">
                      <tr v-for="r in d.rombelDiampu" :key="r">
                        <td>{{ r.nama }}</td>
                        <td>{{ r.nama_mapel }}</td>
                        <td>{{ r.jumlah }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-else class="bg-light py-5">
    <h4 class="text-muted m-0 text-center">Data tidak ditemukan.</h4>
  </div>
  <span v-if="guruKontrol.length" class="small text-muted fst-italic">
    Menampilkan {{ pageOffset + 1 }}-{{ pageOffset + guruKontrol.length }} dari total {{ count }} data
  </span>
  <div v-if="guruKontrol.length" class="row row-cols-auto justify-content-sm-between justify-content-center mt-2">
    <div class="row row-cols-auto">
      <label class="col-auto col-form-label-sm pe-1" for="numRows">Tampilkan:</label>
      <div class="col-auto ps-1">
        <select class="form-select form-select-sm shadow" v-model="pageLimit" id="numRows">
          <option v-for="l in arrayLimit" :key="l" :value="l">
            {{ l }} baris
          </option>
        </select>
      </div>
    </div>
    <div class="col-auto">
      <ul class="pagination pagination-sm shadow mb-0">
        <li class="page-item">
          <button class="page-link" :class="{ disabled: pageCurrent == 1 }"
            @click="pageCurrent > 1 ? pageCurrent-- : ''">&laquo;</button>
        </li>
        <li class="page-item" v-for="p in  pageCount " :key="p">
          <button v-if="p == pageCurrent" class="page-link active fw-bold">{{ p }}</button>
          <button v-else-if="p == 1 || p == pageCount || Math.abs(p - pageCurrent) == 1" class="page-link"
            @click="pageCurrent = p">{{ p }}</button>
          <button v-else-if="(Math.abs(pageCurrent - 1) < 4 && Math.abs(p - 1) < 5) ||
                      (Math.abs(pageCurrent - pageCount) < 4 && Math.abs(p - pageCount) < 5)" class="page-link"
            @click="pageCurrent = p">{{ p }}</button>
          <button
            v-else-if="(Math.abs(p - 1) == 5 || Math.abs(p - pageCount) == 5) && (Math.abs(p - pageCount) == 1 || Math.abs(p - 1) == 1)"
            class="page-link" @click="pageCurrent = p">{{ p }}</button>
          <button v-else-if="Math.abs(p - pageCurrent) == 2 ||
                      (Math.abs(pageCurrent - 1) < 4 && Math.abs(p - 1) == 5) ||
                      (Math.abs(pageCurrent - pageCount) < 4 && Math.abs(p - pageCount) == 5)" class="page-link px-1"
            @click="pageCurrent = p">...</button>
        </li>
        <li class="page-item">
          <button class="page-link" :class="{ disabled: pageCurrent == pageCount }"
            @click="pageCurrent < pageCount ? pageCurrent++ : ''">&raquo;</button>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import WebSocketService from './WebSocketService';
export default {
  name: 'KontrolGuru',
  data() {
    return {
      dataListener: (d) => {
        if (d == 'hari' || d == 'statusHari') {
          this.getGuruKontrolUpdate();
          for (const g of this.guruKontrol) {
            if (g.dataHari) {
              this.getHariKontrol(g);
            }
          }
        }
      },
      penjadwalanListener: (d) => {
        const data = JSON.parse(d);
        if (data.semester_id == this.semester.semester_id) {
          const guru_id = data.guru_id || data.new_guru_id || data.old_guru_id;
          const i = this.guruKontrol.findIndex((r) => r.guru_id == guru_id);
          if (i >= 0) {
            this.getGuruKontrolUpdate();
            if (this.guruKontrol[i].dataHari)
              this.getHariKontrol(this.guruKontrol[i]);
            if (this.guruKontrol[i].dataMapel)
              this.getMapelKontrol(this.guruKontrol[i]);
            if (this.guruKontrol[i].rombelDiampu)
              this.getRombelDiampu(this.guruKontrol[i]);
          }
        }
      },
      guruKontrol: [],
      listSemester: [],
      semester: "",
      arrayLimit: [10, 25, 50, 100, 250],
      count: 0,
      pageCurrent: 1,
      pageOffset: 0,
      pageLimit: 10,
      pageCount: 0,
      memuat: true,
    }
  },
  mounted() {
    WebSocketService.on("inserted-or-deleted-data", this.dataListener);
    WebSocketService.on("update-data", this.dataListener);
    WebSocketService.on("inserted-or-deleted-jadwal", this.penjadwalanListener);
    WebSocketService.on("update-jadwal", this.penjadwalanListener);
    this.pageLimit = this.batasBaris;
    this.semester = {
      tahun_ajaran_id: this.tpAktif.tahun_ajaran_id,
      ...this.semesterAktif
    };
    this.getListSemester();
  },
  beforeUnmount() {
    WebSocketService.off("inserted-or-deleted-data", this.dataListener);
    WebSocketService.off("update-data", this.dataListener);
    WebSocketService.off("inserted-or-deleted-jadwal", this.penjadwalanListener);
    WebSocketService.off("update-jadwal", this.penjadwalanListener);
  },
  computed: {
    tpAktif() {
      return this.$store.getters.getTp
    },
    semesterAktif() {
      return this.$store.getters.getSemester
    },
    batasBaris() {
      return this.$store.getters.getDefaultLimit
    },
  },
  watch: {
    semesterAktif() {
      this.semester = {
        tahun_ajaran_id: this.tpAktif.tahun_ajaran_id,
        ...this.semesterAktif
      };
    },
    semester() {
      if (this.pageCurrent == 1) {
        this.memuat = true;
        this.getGuruKontrol();
      } else {
        this.pageCurrent = 1;
      }
    },
    pageCurrent() {
      this.pageOffset = (this.pageCurrent - 1) * this.pageLimit;
      this.memuat = true;
      this.getGuruKontrol();
    },
    pageLimit() {
      if (this.pageCurrent == 1) {
        this.memuat = true;
        this.getGuruKontrol();
      } else {
        this.pageCurrent = 1;
      }
    },
  },
  methods: {
    async getListSemester() {
      const headers = {
        Authorization: localStorage.getItem('token')
      };
      try {
        const response = await this.axios.post('/penjadwalan/semester/list', {}, {
          headers: headers
        });
        if (response.data) {
          this.listSemester = response.data.semester;
        }
      }
      catch (err) {
        if (err.response) {
          if (err.response.status == 401) {
            this.$swal({
              title: 'Gagal',
              text: 'Sesi berakhir. Silahkan login ulang.',
              icon: 'error',
              confirmButtonText: 'Baik'
            }).then(() => {
              this.$router.go();
            });
          } else {
            console.log(err);
          }
        } else {
          console.log(err)
        }
      }
    },
    async getGuruKontrol() {
      const headers = {
        Authorization: localStorage.getItem('token')
      };
      try {
        const response = await this.axios.post('/guru/kontrol', {
          semester_id: this.semester.semester_id,
          limit: this.pageLimit,
          offset: this.pageOffset,
        }, {
          headers: headers
        });
        if (response.data) {
          this.guruKontrol = response.data.guru;
          this.count = response.data.count;
          this.pageCount = Math.ceil(this.count / this.pageLimit);
        }
      }
      catch (err) {
        if (err.response) {
          if (err.response.status == 401) {
            this.$swal({
              title: 'Gagal',
              text: 'Sesi berakhir. Silahkan login ulang.',
              icon: 'error',
              confirmButtonText: 'Baik'
            }).then(() => {
              this.$router.go();
            });
          } else {
            console.log(err);
          }
        } else {
          console.log(err)
        }
      }
      setTimeout(() => {
        this.memuat = false;
      }, 250)
    },
    async getGuruKontrolUpdate() {
      const headers = {
        Authorization: localStorage.getItem('token')
      };
      try {
        const response = await this.axios.post('/guru/kontrol', {
          semester_id: this.semester.semester_id,
          limit: this.pageLimit,
          offset: this.pageOffset,
        }, {
          headers: headers
        });
        if (response.data) {
          const dataGuruKontrol = response.data.guru;
          for (let i in dataGuruKontrol) {
            this.guruKontrol[i].jumlah_jam = dataGuruKontrol[i].jumlah_jam;
            this.guruKontrol[i].total_jam = dataGuruKontrol[i].total_jam;
          }
        }
      }
      catch (err) {
        if (err.response) {
          if (err.response.status == 401) {
            this.$swal({
              title: 'Gagal',
              text: 'Sesi berakhir. Silahkan login ulang.',
              icon: 'error',
              confirmButtonText: 'Baik'
            }).then(() => {
              this.$router.go();
            });
          } else {
            console.log(err);
          }
        } else {
          console.log(err)
        }
      }
    },
    async getHariKontrol(d) {
      const headers = {
        Authorization: localStorage.getItem('token')
      };
      try {
        const response = await this.axios.post('/hari/kontrol', {
          semester_id: this.semester.semester_id,
          guru_id: d.guru_id
        }, {
          headers: headers
        });
        if (response.data) {
          setTimeout(() => {
            d.dataHari = response.data.hari;
          }, 250)
        }
      }
      catch (err) {
        if (err.response) {
          if (err.response.status == 401) {
            this.$swal({
              title: 'Gagal',
              text: 'Sesi berakhir. Silahkan login ulang.',
              icon: 'error',
              confirmButtonText: 'Baik'
            }).then(() => {
              this.$router.go();
            });
          } else {
            console.log(err);
          }
        } else {
          console.log(err)
        }
      }
    },
    async getMapelKontrol(d) {
      const headers = {
        Authorization: localStorage.getItem('token')
      };
      try {
        const response = await this.axios.post('/mapel/kontrol', {
          tahun_ajaran_id: this.semester.tahun_ajaran_id,
          semester_id: this.semester.semester_id,
          guru_id: d.guru_id
        }, {
          headers: headers
        });
        if (response.data) {
          setTimeout(() => {
            d.dataMapel = response.data.mapel;
          }, 250)
        }
      }
      catch (err) {
        if (err.response) {
          if (err.response.status == 401) {
            this.$swal({
              title: 'Gagal',
              text: 'Sesi berakhir. Silahkan login ulang.',
              icon: 'error',
              confirmButtonText: 'Baik'
            }).then(() => {
              this.$router.go();
            });
          } else {
            console.log(err);
          }
        } else {
          console.log(err)
        }
      }
    },
    async getRombelDiampu(d) {
      const headers = {
        Authorization: localStorage.getItem('token')
      };
      try {
        const response = await this.axios.post('/guru/rombel-diampu', {
          semester_id: this.semester.semester_id,
          guru_id: d.guru_id
        }, {
          headers: headers
        });
        if (response.data) {
          setTimeout(() => {
            d.rombelDiampu = response.data.rombel;
          }, 250)
        }
      }
      catch (err) {
        if (err.response) {
          if (err.response.status == 401) {
            this.$swal({
              title: 'Gagal',
              text: 'Sesi berakhir. Silahkan login ulang.',
              icon: 'error',
              confirmButtonText: 'Baik'
            }).then(() => {
              this.$router.go();
            });
          } else {
            console.log(err);
          }
        } else {
          console.log(err)
        }
      }
    },
  },
}
</script>

<style></style>