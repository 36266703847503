<template>
  <jadwal-rombel v-if="$route.params.data === 'rombel'" />
  <jadwal-guru v-else-if="$route.params.data === 'guru'" />
  <jadwal-mapel v-else-if="$route.params.data === 'mapel'" />
  <jadwal-ruang v-else-if="$route.params.data === 'ruang'" />
  <jadwal-piket v-else-if="$route.params.data === 'piket'" />
  <not-found v-else />
</template>

<script>
import NotFound from '@/components/NotFound.vue';
import JadwalRombel from '@/components/JadwalRombel.vue';
import JadwalGuru from '@/components/JadwalGuru.vue';
import JadwalMapel from '@/components/JadwalMapel.vue';
import JadwalRuang from '@/components/JadwalRuang.vue';
import JadwalPiket from '@/components/JadwalPiket.vue';
export default {
  components: { NotFound, JadwalRombel, JadwalGuru, JadwalMapel, JadwalRuang, JadwalPiket },
  name: 'JadwalView',
  data() {
    return {
    }
  },
  created() {
  },
  computed: {
    tpAktif() {
      return this.$store.getters.getTp
    },
    semesterAktif() {
      return this.$store.getters.getSemester
    }
  },
  methods: {
  }
}
</script>

<style></style>